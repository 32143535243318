export interface TaskResponse {
  task_id: string;
}

export enum TaskState {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
}

export type DeleteComplete = '';

export interface TaskStatus {
  task_status: TaskState;
}
