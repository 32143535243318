// Autogenerated by util/sync-labels/sync-labels.ts
// Do not edit this file directly.
// To regenerate this file, start ithriv_landing_service locally on port 8000 and
// run the following command in the root directory of the ithriv_web repository:
// > npm run sync_labels

export enum DatasetRole {
  ADMIN = 'admin',
  COLLABORATOR = 'collaborator',
  CUSTOMER = 'customer',
}

export const DatasetRoleLabels = {
  admin: 'Admin',
  collaborator: 'Collaborator',
  customer: 'Customer',
} as const;

export type DatasetRoleId = keyof typeof DatasetRoleLabels;
export type DatasetRoleLabel = (typeof DatasetRoleLabels)[DatasetRoleId];
