import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AddCategoryButtonComponent} from '@buttons/add-category-button/add-category-button.component';
import {AddResourceButtonComponent} from '@buttons/add-resource-button/add-resource-button.component';
import {ApprovedBadgeComponent} from '@buttons/approved-badge/approved-badge.component';
import {CommaDelimitedListItemComponent} from '@buttons/comma-delimited-list-item/comma-delimited-list-item.component';
import {CsvExportButtonComponent} from '@buttons/csv-export-button/csv-export-button.component';
import {EditCategoryButtonComponent} from '@buttons/edit-category-button/edit-category-button.component';
import {EditResourceButtonComponent} from '@buttons/edit-resource-button/edit-resource-button.component';
import {FavoriteResourceButtonComponent} from '@buttons/favorite-resource-button/favorite-resource-button.component';
import {LoadingComponent} from '@buttons/loading/loading.component';
import {LockedIconComponent} from '@buttons/locked-icon/locked-icon.component';
import {LogoComponent} from '@buttons/logo/logo.component';
import {MoveCategoryButtonComponent} from '@buttons/move-category-button/move-category-button.component';
import {FormControlsModule} from '@form-controls/form-controls.module';
import {MaterialModule} from '@material/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';

@NgModule({
  declarations: [
    AddCategoryButtonComponent,
    AddResourceButtonComponent,
    ApprovedBadgeComponent,
    CommaDelimitedListItemComponent,
    CsvExportButtonComponent,
    EditCategoryButtonComponent,
    EditResourceButtonComponent,
    FavoriteResourceButtonComponent,
    LockedIconComponent,
    LoadingComponent,
    LogoComponent,
    MoveCategoryButtonComponent,
  ],
  imports: [CommonModule, FormControlsModule, MaterialModule, PipesModule],
  exports: [
    AddCategoryButtonComponent,
    AddResourceButtonComponent,
    ApprovedBadgeComponent,
    CommaDelimitedListItemComponent,
    CsvExportButtonComponent,
    EditCategoryButtonComponent,
    EditResourceButtonComponent,
    FavoriteResourceButtonComponent,
    LoadingComponent,
    LockedIconComponent,
    LogoComponent,
    MoveCategoryButtonComponent,
  ],
  providers: [],
})
export class ButtonsModule {}
