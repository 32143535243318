import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {isHSD} from '@utilities/commons-dataset-util';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {CommonsSearchDataset} from '@shared/types/commons-types';
import {User} from '@shared/types/user';
import {lastValueFrom} from 'rxjs';

export interface HsdDownloadDialogData {
  confirm: boolean;
  dataset: CommonsSearchDataset;
  user: User;
}

@Component({
  selector: 'app-hsd-download-dialog',
  templateUrl: './hsd-download-dialog.component.html',
  styleUrls: ['./hsd-download-dialog.component.scss'],
})
export class HsdDownloadDialogComponent {
  isHSD = isHSD;

  constructor(
    public cas: CommonsApiService,
    @Inject(MAT_DIALOG_DATA) public data: HsdDownloadDialogData,
    public dialogRef: MatDialogRef<HsdDownloadDialogComponent>,
  ) {}

  onNoClick() {
    this.data.confirm = false;
    this.dialogRef.close(this.data);
  }

  onSubmit() {
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }

  async downloadDSP() {
    const associatedProject = this.data.dataset.associated_projects.find(ap => !!ap.data_security_plan);

    if (!associatedProject) {
      return;
    }

    const project = await lastValueFrom(
      this.cas.getProject(associatedProject.project.id, this.data.user, this.data.dataset.publisher.name),
    );
    const fileUrl = this.cas.getProjectFileUrl(this.data.user, project, associatedProject.data_security_plan.id);

    await this.cas.downloadFile(
      fileUrl,
      associatedProject.data_security_plan.file_name,
      this.data.user,
      this.data.dataset,
    );
  }
}
