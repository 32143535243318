<div *ngIf="dataLoaded" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
  <h2>Select your institution</h2>
  <div class="login-service" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="center center">
    <button
      *ngFor="let s of loginServices"
      mat-stroked-button
      (click)="goLogin(s)"
      [ngStyle]="{
        'border-color': s.color
      }"
      class="service-logo"
      [matTooltip]="getTooltipText(s.name)"
      id="btn-service-{{ s.id }}"
    >
      <img *ngIf="s.image" [alt]="s.name" [src]="s.image" />
      <span *ngIf="!s.image">{{ s.name }}</span>
    </button>
  </div>
  <h5>
    The iTHRIV Research Concierge Portal is supported in part by the National Center For Advancing Translational
    Sciences of the National Institutes of Health under Award Numbers UL1TR003015 and KL2TR003016.
  </h5>
</div>
