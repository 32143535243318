/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DatasetType {
    DICOM = 'DICOM',
    GENERIC = 'Generic',
    REDCAP = 'REDCap',
}
