import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {
  HtmlEditorService,
  ImageService,
  ImageSettingsModel,
  LinkService,
  ResizeService,
  TableService,
  ToolbarService,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {FormField} from '@shared/types/form-field';

@Component({
  selector: 'app-rich-text-field',
  templateUrl: './rich-text-field.component.html',
  styleUrls: ['./rich-text-field.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, ResizeService, TableService, HtmlEditorService],
})
export class RichTextFieldComponent implements OnInit, AfterViewInit {
  @Input() field: FormField;
  @Input() errors: ValidationErrors;
  @Input() errorMatcher: ErrorStateMatcher;
  @Input() formGroup: UntypedFormGroup;

  public tools: object = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'Undo',
      'Redo',
      '|',
      'Alignments',
      '|',
      'OrderedList',
      'UnorderedList',
      '|',
      'Indent',
      'Outdent',
      '|',
      'CreateTable',
      '|',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      '|',
      'FullScreen',
    ],
  };

  public insertImageSettings: ImageSettingsModel = {
    allowedTypes: ['.jpeg', '.jpg', '.png'],
    display: 'inline',
    width: 'auto',
    height: 'auto',
    saveFormat: 'Base64',
    saveUrl: null,
    path: null,
  };

  options = [];

  public min = new Date();

  constructor(
    private api: ResourceApiService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }
  ngOnInit() {
    this.loadOptions();

    this.formGroup.valueChanges.subscribe(_ => {
      if (this.field && this.field.formControl) {
        this.field.formControl.updateValueAndValidity({onlySelf: true});
      }
    });

    if (this.field.defaultValue && !this.field.formControl.value) {
      this.field.formControl.setValue(this.field.defaultValue);
    }
  }

  loadOptions() {
    this.changeDetectorRef.detectChanges();
  }

  currentLength() {
    return (
      (this.field && this.field.formControl && this.field.formControl.value && this.field.formControl.value.length) || 0
    );
  }
}
