<div
  class="container mat-typography"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutGap="10px"
  fxLayoutAlign="center center"
>
  <div fxFlex="50%">
    <app-logo></app-logo>
    <app-login-services></app-login-services>
  </div>
</div>
