<div
  *ngIf="field.type === 'richtexteditor'"
  [attr.app-rich-text-field-name]="field.name"
  class="mat-typography"
  [matTooltip]="field.tooltip"
>
  <app-form-field-label [errors]="errors" [field]="field"></app-form-field-label>
  <div *ngIf="field.markdownAbove" class="markdown-above">
    <markdown>{{ field.markdownAbove }}</markdown>
  </div>
  <mat-error *ngIf="field.formControl.errors && (field.formControl.dirty || field.formControl.touched)">
    <span *ngIf="field.required && field.formControl.hasError('required')">
      {{ field.placeholder }} is
      <strong>required</strong>
    </span>
    <span *ngIf="field.minLength && field.formControl.hasError('minlength')">
      Must be at least
      <strong>{{ field.minLength }}</strong> characters long. You only entered
      <strong>{{ currentLength() }}</strong> characters.
    </span>
    <span *ngIf="field.maxLength && field.formControl.hasError('maxlength')">
      Maximum length is
      <strong>"{{ field.maxLength }}"</strong> characters. You entered
      <strong>{{ currentLength() }}</strong> characters.
    </span>
  </mat-error>
  <ejs-richtexteditor
    ngDefaultControl
    *ngIf="field.formControl"
    [enableResize]="true"
    [enableRtl]="false"
    [formControl]="field.formControl"
    [insertImageSettings]="insertImageSettings"
    [toolbarSettings]="tools"
    [value]="field.formControl.value"
    id="defaultRTE"
  >
  </ejs-richtexteditor>
</div>
