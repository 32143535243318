<div
  class="container mat-typography"
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutGap="10px"
  fxLayoutAlign="center center"
>
  <div fxFlex="50%" class="message">
    <app-logo (click)="goHome()"></app-logo>
    <h1>Your iTHRIV session has timed out.</h1>
    <p>You will need to log in again to access your iTHRIV profile.</p>
    <div class="login">
      <app-login-services [prevUrl]="prevUrl"></app-login-services>
    </div>
  </div>
</div>
