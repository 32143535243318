import {Component, Input} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {FormField} from '@shared/types/form-field';
import {NgClass} from '@angular/common';
import {MaterialModule} from '@material/material.module';

@Component({
  selector: 'app-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.scss'],
  standalone: true,
  imports: [NgClass, MaterialModule],
})
export class FormFieldLabelComponent {
  @Input() field: FormField;
  @Input() errors: ValidationErrors;

  constructor() {}

  hasErrors() {
    return this.field.formControl.touched && (this.field.formControl.invalid || this.errors !== null);
  }
}
