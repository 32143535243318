import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {ButtonToggleOptions} from '@shared/types/form-field';

@Component({
  selector: 'app-public-private-toggle',
  templateUrl: './public-private-toggle.component.html',
  styleUrls: ['./public-private-toggle.component.scss'],
})
export class PublicPrivateToggleComponent {
  @Input() disabled = false;
  @Input() selected_option: string;
  @Input() labelPrivate = 'PRIVATE';
  @Input() labelPublic = 'PUBLIC';
  @Input() tooltipPrivate: string = '';
  @Input() tooltipPublic: string = '';
  @Output() intercept = new EventEmitter<MatButtonToggleChange>();
  @Output() toggle = new EventEmitter<string>();
  @Input() options?: ButtonToggleOptions[];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.options = [
      {
        label: this.labelPrivate,
        tooltip: this.tooltipPrivate,
        icon: 'visibility_off',
        value: 'PRIVATE',
      },
      {
        label: this.labelPublic,
        tooltip: this.tooltipPublic,
        icon: 'visibility',
        value: 'PUBLIC',
      },
    ];
  }

  async handleClick($event: Event, value: string) {
    if (!this.disabled) {
      const selectedOption = this.options.find(o => o.value === value);
      const result = selectedOption.onClick ? await selectedOption.onClick() : true;

      if (result) {
        this.selected_option = value;
        this.toggle.emit(value);
        this.changeDetectorRef.detectChanges();
      }
    }
  }
}
