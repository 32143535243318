import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {FileAttachment} from './file-attachment';
import {FormSelectOption} from './form-select-option';

export type FormFieldType =
  | 'boolean'
  | 'multicheckbox'
  | 'list'
  | 'color'
  | 'email'
  | 'files'
  | 'daterange'
  | 'password'
  | 'richtexteditor'
  | 'radio'
  | 'select'
  | 'image_select'
  | 'text'
  | 'textarea'
  | 'toggle'
  | 'tree'
  | 'url'
  | 'number';

export type BooleanFieldMode = 'checkbox' | 'slideToggle' | 'buttonToggle';

export interface ButtonToggleOptions {
  label: string;
  tooltip: string;
  icon: string;
  value: string;
  onClick?: () => Promise<boolean>;
}

export interface FormFieldProps {
  name?: string;
  fieldsetId?: string;
  fieldsetLabel?: string;
  formControl: UntypedFormControl | UntypedFormGroup;
  formGroup: UntypedFormGroup;
  label?: string;
  helpText?: string;
  hideNoneOption?: boolean;
  maxLength?: number;
  minLength?: number;
  multiSelect?: boolean;
  options?: object;
  placeholder?: string;
  required?: boolean;
  hidden?: boolean;
  showIcons?: boolean;
  type?: FormFieldType;
  showTime?: boolean;
  defaultValue?: any;
  markdownAbove?: string;
  markdownBelow?: string;
  tooltip?: string;
  booleanMode?: BooleanFieldMode;
  booleanOptions?: ButtonToggleOptions[];
  attachments?: Map<number | string, FileAttachment>;
  selectOptions?: string[] | FormSelectOption[];
  apiSource?: string;
  passwordsMatch?: boolean;
  onChange?: (event: any) => void;
}

export class FormField {
  name?: string;
  fieldsetId?: string;
  fieldsetLabel?: string;
  formControl: UntypedFormControl | UntypedFormGroup;
  formGroup: UntypedFormGroup;
  label?: string;
  helpText?: string;
  maxLength?: number;
  minLength?: number;
  multiSelect = false;
  options?: object;
  placeholder: string;
  required?: boolean;
  hidden?: boolean;
  showIcons = false;
  type: FormFieldType;
  showTime?: boolean;
  defaultValue?: any;
  markdownAbove?: string;
  markdownBelow?: string;
  tooltip?: string;
  booleanMode?: BooleanFieldMode;
  booleanOptions?: ButtonToggleOptions[];
  onChange?: (event: any) => void;
  hideNoneOption?: boolean;

  // 'files' type
  attachments = new Map<number | string, FileAttachment>();

  // 'select' type can pull from a hard-coded list
  // or from API. If selectOptions is not provided,
  // apiSource will be used.
  selectOptions?: string[] | FormSelectOption[];
  apiSource?: string; // Should be the name of the API function to call
  passwordsMatch?: boolean;

  constructor(private _props: FormFieldProps) {
    for (const propName in this._props) {
      if (Object.hasOwn(this._props, propName) && ![null, undefined].includes(this._props[propName])) {
        this[propName] = this._props[propName];
      }
    }
  }
}
