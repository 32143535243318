<app-commons-menu [navItems]="navItems" [title]="project?.name || 'Loading...'">
  <ng-container
    *ngTemplateOutlet="isContentLoadedAndOnVPN() ? pageContent : !isLoaded() ? loadingMessage : noVPNMessage"
  ></ng-container>
</app-commons-menu>

<ng-template #pageContent>
  <div class="project">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="1em">
      <div
        fxFlex.lg="calc(75% - 1em)"
        fxFlex.md="calc(75% - 1em)"
        fxFlex.sm="calc(50% - 1em)"
        fxFlex.xl="calc(75% - 1em)"
        fxFlex.xs="calc(100% - 1em)"
      >
        <app-buttons-card [project]="project" [projectWithPermissions]="projectWithPermissions"></app-buttons-card>
        <mat-divider [inset]="true"></mat-divider>
        <app-documents-card
          *ngIf="showProjectDocuments()"
          (reloadEmit)="reloadProject()"
          [project]="project"
          [projectWithPermissions]="projectWithPermissions"
        ></app-documents-card>
        <mat-divider [inset]="true"></mat-divider>
        <app-datasets-card
          [displayedColumns]="displayedColumns"
          [datasetsDataSource]="datasetsDataSource"
          [datasets]="datasets"
          [project]="project"
          [projectWithPermissions]="projectWithPermissions"
          [publicCommons]="false"
          (reloadEmit)="reloadProject()"
        ></app-datasets-card>
        <mat-divider [inset]="true"></mat-divider>
        <div>
          <app-resources-card
            (reloadEmit)="reloadProject()"
            [projectResources]="projectResources"
            [projectWithPermissions]="projectWithPermissions"
            [project]="project"
            [publicCommons]="false"
          ></app-resources-card>
        </div>
      </div>
      <div>
        <app-details-card
          (reloadEmit)="reloadProject()"
          [project]="project"
          [publicCommons]="false"
          [projectWithPermissions]="projectWithPermissions"
        ></app-details-card>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingMessage>
  <app-loading size="sm" message="Loading {{ contentBeingLoaded || 'Project' }}..."></app-loading>
</ng-template>

<ng-template #noVPNMessage>
  <app-vpn-warning></app-vpn-warning>
</ng-template>
