import {Component, Inject, Input, OnInit} from '@angular/core';
import {User} from '@shared/types/user';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '@services/user-service/user.service';
import {ALLOWED_MIME_TYPES} from '@shared/constants/constants';
import {lastValueFrom} from 'rxjs';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {formatSize} from '@utilities/format-size';

export interface UploadDialogData {
  complete: boolean;
  user: User;
  datasetFileUrl: string;
}

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent implements OnInit {
  @Input() accept = ALLOWED_MIME_TYPES;
  fileSizeLimit: number = 0;
  formatSize = formatSize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    public userService: UserService,
    public cas: CommonsApiService,
  ) {}

  ngOnInit() {
    this.getFileSizeLimit();
  }

  async getFileSizeLimit() {
    this.fileSizeLimit = await lastValueFrom(this.cas.checkFileSizeLimit(this.user));
  }

  get user(): User {
    return this.userService.getUser();
  }

  onFileComplete(event) {
    this.data.complete = true;
    this.dialogRef.close(this.data);
  }

  close() {
    this.data.complete = false;
    this.dialogRef.close(this.data);
  }
}
