<div *ngIf="fieldHasValue(field)">
  <mat-list-item *ngIf="field && displayType !== 'link' && displayType !== 'chip'">
    <h4 mat-line>{{ pluralize(title) }}:</h4>
    <p *ngFor="let item of fieldList" mat-line>{{ item | truncateWords }}</p>
  </mat-list-item>
  <mat-list-item *ngIf="field && displayType === 'link'">
    <h4 mat-line>{{ pluralize(title) }}:</h4>
    <a *ngFor="let item of fieldList" [href]="item" mat-line target="_blank">{{ item | shortenWord }}</a>
  </mat-list-item>
  <mat-list-item *ngIf="field && displayType === 'chip'">
    <h4 mat-line>{{ pluralize(title) }}:</h4>
    <mat-chip-list mat-line>
      <mat-chip *ngFor="let item of fieldList">{{ item | shortenWord }}</mat-chip>
    </mat-chip-list>
  </mat-list-item>
</div>
