import TurndownService from 'turndown';

export const cleanUpHtml = (val: string) => {
  if (typeof val !== 'string') {
    return '';
  }

  const cleanVal = val
    // Remove base64-encoded images.
    .replace(/<img[^>]+data:[^>]+base64[^>]+>/gi, '')

    // Replace problematic characters.
    .replace(/[\u200B-\u200D\uFEFF]/g, '') // Zero-width spaces
    .replace(/&nbsp;/g, ' ') // Non-breaking spaces
    .replace(/&quot;|&rsquo;|&lsquo;|[\u2018\u2019]/g, `'`) // "Smart" single quotes
    .replace(/&rdquo;|&ldquo;|[\u201C\u201D]/g, `"`); // "Smart" double quotes

  // Convert HTML to Markdown.
  const turndownService = new TurndownService();
  return turndownService.turndown(cleanVal || '');
};
