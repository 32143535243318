import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ALL_LOGIN_SERVICES} from '@authentication/login-service';
import {Institution} from '@shared/types/institution';
import {UserService} from '@services/user-service/user.service';
import {Resource} from '@shared/types/resource';
import {ResourceCategory} from '@shared/types/resource-category';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-resource-tile',
  templateUrl: './resource-tile.component.html',
  styleUrls: ['./resource-tile.component.scss'],
})
export class ResourceTileComponent {
  @Input() resource: Resource;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  get user(): User {
    return this.userService.getUser();
  }

  handleClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  resourceRoute(resource: Resource) {
    return ['/resource', resource.id];
  }

  getResourceOrEventURL(resource: Resource) {
    return [window.location.origin, '#', 'resource', resource.id].join('/');
  }

  typeIconId(resource: Resource) {
    return resource && resource.type && resource.type.icon && resource.type.icon.id;
  }

  getInstitutionLogoUrl(institution: Institution) {
    return ALL_LOGIN_SERVICES[institution.name].image;
  }

  getCategoryBreadcrumbs(cat) {
    let returnStr = '';

    if (cat.category.parent) {
      if (cat.category.parent.parent) {
        returnStr = returnStr + cat.category.parent.parent.name + ' > ';
      }

      returnStr = returnStr + cat.category.parent.name + ' > ' + cat.category.name;
    } else {
      returnStr = cat.category.name;
    }

    return returnStr;
  }

  getRootCategoryName(cat: ResourceCategory): string {
    let currentCat = cat.category;

    while (currentCat.parent) {
      currentCat = currentCat.parent;
    }

    return currentCat.name;
  }
}
