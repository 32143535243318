import {SelectionModel} from '@angular/cdk/collections';
import {NestedTreeControl} from '@angular/cdk/tree';
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {Category} from '@shared/types/category';
import {FormField} from '@shared/types/form-field';
import {lastValueFrom, of as observableOf} from 'rxjs';

@Component({
  selector: 'app-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent implements OnInit {
  @Input() field: FormField;
  formGroup: UntypedFormGroup;
  categories: Category[] = [];
  treeControl: NestedTreeControl<Category>;
  dataSource: MatTreeNestedDataSource<Category>;
  dataLoaded = false;
  nodes = {};
  changeDetectorRef: ChangeDetectorRef;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<Category>(true /* multiple */);

  constructor(private api: ResourceApiService) {}

  ngOnInit() {
    this.formGroup = this.field?.formControl as UntypedFormGroup;
    this.treeControl = new NestedTreeControl<Category>(node => observableOf(node.children));
    this.dataSource = new MatTreeNestedDataSource();
    this.loadData();
  }

  async loadData() {
    this.categories = await lastValueFrom(this.api.getCategories());
    this.dataSource.data = this.categories;
    this.updateSelection();
  }

  updateSelection() {
    const controls = this.formGroup?.controls;
    if (controls) {
      for (const key in this.nodes) {
        if (this.nodes.hasOwnProperty(key) && controls.hasOwnProperty(key)) {
          if (controls[key].value) {
            this.checklistSelection.select(this.nodes[key]);
          } else {
            this.checklistSelection.deselect(this.nodes[key]);
          }
        }
      }
      this.dataLoaded = true;
    }
  }

  getFormControl(node: Category) {
    const key = node.id.toString();
    this.nodes[key] = node;
    this.updateSelection();

    if (this.formGroup?.controls?.hasOwnProperty(key)) {
      return this.formGroup?.controls[key] as UntypedFormControl;
    } else {
      console.error('Could not find control with key:', key);
    }
  }

  hasNestedChild(_: number, node: Category) {
    return node.children && node.children.length > 0;
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: Category): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: Category): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  numSelectedDescendants(node: Category): number {
    const descendants: Category[] = this.treeControl.getDescendants(node);
    const selectedDescendants = descendants.filter(d => this.checklistSelection.isSelected(d));
    return selectedDescendants.length;
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  toggleNode(node: Category): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  toggleExpand($event, node: Category): void {
    $event.preventDefault();
  }
}
