import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DatasetCreate, DatasetPut, ProjectCreate, ProjectPut} from '@services/landing-service';

export interface SensitiveDialogData {
  dataset?: DatasetCreate | DatasetPut;
  project?: ProjectCreate | ProjectPut;
  hsd?: boolean;
  confirm: boolean;
}

@Component({
  selector: 'app-hipaa-dialog',
  templateUrl: './sensitive-data-dialog.html',
  styleUrls: ['./sensitive-data-dialog.scss'],
})
export class SensitiveDataDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SensitiveDialogData,
    public dialogRef: MatDialogRef<SensitiveDataDialogComponent>,
  ) {}

  onNoClick() {
    this.data.confirm = false;
    this.dialogRef.close(this.data);
  }

  onSubmit() {
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }
}
