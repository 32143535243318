import {Component, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorSnackbarComponent} from '@form-controls/error-snackbar/error-snackbar.component';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {UserService} from '@services/user-service/user.service';
import {User} from '@shared/types/user';
import {ProjectResponse} from '@services/landing-service';
import {CommonsSearchProject} from '@shared/types/commons-types';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {ResourceApiService} from '@services/resource-api/resource-api.service';

@Component({
  selector: 'app-buttons-card',
  templateUrl: './buttons-card.component.html',
  styleUrls: ['./buttons-card.component.scss'],
})
export class ButtonsCardComponent {
  @Input() project: ProjectResponse;
  @Input() projectWithPermissions: CommonsSearchProject;
  showConfirmDelete = false;
  error: String;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private cas: CommonsApiService,
    public api: ResourceApiService,
    public snackBar: MatSnackBar,
  ) {}

  get user(): User {
    return this.userService.user;
  }

  async deleteProject() {
    try {
      await lastValueFrom(this.cas.deleteProject(this.projectWithPermissions));
      await firstValueFrom(this.api.syncOnProjectDelete(this.project.id, this.user, this.project.publisher.name));
      await this.router.navigate(['/home'], {queryParams: {tabIndex: 'private_commons'}});
    } catch (error) {
      this.displayError(error);
      this.error = error;
    }
  }

  private displayError(errorString?: string) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: {errorString, action: 'Ok'},
      duration: 5000,
      panelClass: 'snackbar-warning',
    });
  }

  canEditProject() {
    return this.projectWithPermissions?._can_update_meta === true;
  }

  canDeleteProject() {
    return this.projectWithPermissions?._can_delete_meta && this.projectWithPermissions?._can_delete_data;
  }
}
