import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import {FormField} from '@shared/types/form-field';
import {cleanUpCommaDelimitedList} from '@shared/validators/delimited_list.validator';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent implements OnInit {
  @Input() field: FormField;
  @ViewChild('chipInput', {static: true}) chipInput: ElementRef;
  visible = true;
  selectable = false;
  removable = true;
  addOnBlur = true;
  values: string[] = [];
  invalidIndices: number[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() {
    this.loadValues();
  }

  ngOnInit() {
    this.loadValues();
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value || '';

    // Remove duplicate values
    this.values = this.addAndDedupeValues(this.splitText(value));

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.updateFormControl();
  }

  remove(value: string): void {
    const index = this.values.indexOf(value);

    if (index >= 0) {
      this.values.splice(index, 1);
    }

    this.updateFormControl();
  }

  updateFormControl() {
    this.field.formControl.setValue(this.values);
    this.validate();
  }

  loadValues() {
    if (this.field && this.field.formControl) {
      const value = this.field.formControl.value || [];

      // Convert stored value to array
      this.values = Array.isArray(value) ? value : cleanUpCommaDelimitedList(value).split(',');
    }
  }

  handleClick() {
    this.chipInput.nativeElement.focus();
  }

  validate() {
    // Clear out the list of invalid indices.
    this.invalidIndices = [];

    this.field.formControl.updateValueAndValidity();
    if (this.field.formControl.invalid && this.field.formControl.errors) {
      if (this.field.formControl.errors.email || this.field.formControl.errors.url) {
        Object.keys(this.field.formControl.errors).forEach(err => {
          const error = this.field.formControl.errors[err];
          if (error.indices) {
            // Mark which items are invalid.
            this.invalidIndices = this.invalidIndices.concat(error.indices);
          }
        });
      }
    }
  }

  isInvalid(i: number) {
    return this.invalidIndices.includes(i);
  }

  handlePaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    event.preventDefault();
    this.values = this.addAndDedupeValues(this.splitText(pastedText));
  }

  splitText(value: string): string[] {
    const splitPattern = /[^\w\-_.@\/\\: #?=]/gim;
    const newValues = value
      .split(splitPattern)
      .map(v => v.trim())
      .filter(v => v.length > 0);
    this.values = this.values.concat(newValues);

    // Remove duplicate values
    return Array.from(new Set(this.values));
  }

  addAndDedupeValues(arr: string[]) {
    return Array.from(new Set(this.values.concat(arr)));
  }

  handleBlur() {
    this.field.formControl.markAllAsTouched();
    this.field.formControl.markAsDirty();
  }
}
