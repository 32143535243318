import {Component} from '@angular/core';

@Component({
  selector: 'app-vpndisconnect-dialog',
  templateUrl: './vpndisconnect-dialog.component.html',
  styleUrls: ['./vpndisconnect-dialog.component.scss'],
})
export class VPNDisconnectDialogComponent {
  constructor() {}
}
