import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {Category} from '@shared/types/category';
import {getCategoryTooltipText} from '@utilities/category-util';
import {hexColorToRGBA} from '@utilities/color';

@Component({
  selector: 'app-category-tile',
  templateUrl: './category-tile.component.html',
  styleUrls: ['./category-tile.component.scss'],
})
export class CategoryTileComponent {
  @Input()
  category: Category;
  @Input() fromCategory: Category;
  getTooltipText = getCategoryTooltipText;
  @Input() childCategory: Category;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ResourceApiService,
  ) {}

  get shouldHighlight(): boolean {
    return this.category.hover || this.isSelected;
  }

  get isSelected(): boolean {
    if (this.category && this.childCategory) {
      let rootParent: Category;
      let parent = this.childCategory;

      while (!rootParent) {
        if (parent.parent) {
          parent = parent.parent;
        } else {
          rootParent = parent;
        }
      }

      return this.category.id === rootParent.id;
    } else {
      return false;
    }
  }

  categoryRoute(category: Category) {
    const viewPrefs = this.api.getViewPreferences();
    const isNetworkView = viewPrefs && viewPrefs.hasOwnProperty('isNetworkView') ? viewPrefs.isNetworkView : true;
    const catId = category.id.toString();

    if (isNetworkView) {
      return ['/network', catId];
    } else {
      return ['/browse', catId];
    }
  }

  categoryImageURL() {
    const rootCat = this.category && this.category.level === 0;
    if (rootCat) {
      return `url('/assets/browse/${this.category.image.replace('.png', '')}-tile.png')`;
    }
  }

  categoryColorLight() {
    return hexColorToRGBA(this.category.color, 0.1);
  }
}
