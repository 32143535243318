import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LoginServicesComponent} from '@authentication/login-services/login-services.component';
import {LoginComponent} from '@authentication/login/login.component';
import {LogoutComponent} from '@authentication/logout/logout.component';
import {SessionRedirectComponent} from '@authentication/session-redirect/session-redirect.component';
import {TimedoutComponent} from '@authentication/timedout/timedout.component';
import {ButtonsModule} from '@buttons/buttons.module';
import {FormControlsModule} from '@form-controls/form-controls.module';
import {MaterialModule} from '@material/material.module';

@NgModule({
  declarations: [LoginComponent, LoginServicesComponent, LogoutComponent, SessionRedirectComponent, TimedoutComponent],
  imports: [ButtonsModule, CommonModule, FormControlsModule, MaterialModule],
  exports: [LoginComponent, LoginServicesComponent, LogoutComponent, SessionRedirectComponent, TimedoutComponent],
  providers: [],
})
export class AuthenticationModule {}
