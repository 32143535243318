<mat-card [ngClass]="'view'">
  <mat-card-header fxLayout="row">
    <mat-card-title> Datasets</mat-card-title>
    <span fxFlex></span>
    <mat-button-toggle-group [(ngModel)]="datasetListMode">
      <mat-button-toggle value="tiles">
        <mat-icon>grid_view</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="table">
        <mat-icon>table_chart</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </mat-card-header>
  <mat-card-content>
    <app-loading *ngIf="!datasets" size="sm" message="Loading Datasets..."></app-loading>
    <ng-container *ngIf="datasets">
      <ng-container *ngIf="datasets.length === 0">No files uploaded.</ng-container>
      <ng-container *ngIf="datasets.length > 0">
        <ng-container>
          <app-commons-dataset-list
            *ngIf="datasetListMode === 'tiles'"
            [datasets]="datasets"
            [publicCommons]="this.publicCommons"
            [showCsvDownload]="false"
          ></app-commons-dataset-list>
          <table
            *ngIf="datasetListMode === 'table'"
            [dataSource]="datasetsDataSource"
            class="datasets"
            aria-describedby="Datasets"
            mat-table
          >
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell>NAME</th>
              <td *matCellDef="let pd" [ngClass]="'dataset-label'" mat-cell>
                <button
                  (click)="goDataset(toTypedDs(pd).id)"
                  [ngClass]="'dataset-unlocked'"
                  mat-button
                  matTooltip="Click here to view dataset details"
                >
                  {{ toTypedDs(pd).name }}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="hsd">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let pd" mat-cell>
                <mat-icon
                  *ngIf="toTypedDs(pd).sensitivity_level.id === SensitivityLevel.HSD"
                  matTooltip="Sensitive Data"
                  >gpp_maybe
                </mat-icon>
                <app-commons-hipaa-warning
                  [warnings]="displayWarnings(pd)"
                  [dataset]="toTypedDs(pd)"
                  [isScanComplete]="true"
                ></app-commons-hipaa-warning>
              </td>
            </ng-container>
            <ng-container matColumnDef="isMetadataPublic">
              <th *matHeaderCellDef mat-header-cell>DETAILS VISIBILITY</th>
              <td *matCellDef="let pd" mat-cell>
                <app-public-private-toggle
                  [selected_option]="toTypedDs(pd).is_metadata_public ? 'PUBLIC' : 'PRIVATE'"
                  [disabled]="true"
                  tooltipPrivate="Only dataset admins and collaborators can view the dataset details."
                  tooltipPublic="Anyone can view dataset details."
                ></app-public-private-toggle>
              </td>
            </ng-container>
            <ng-container matColumnDef="isDataPublic">
              <th *matHeaderCellDef mat-header-cell>DATA FILE VISIBILITY</th>
              <td *matCellDef="let pd" mat-cell>
                <app-public-private-toggle
                  [selected_option]="toTypedDs(pd).is_data_public ? 'PUBLIC' : 'PRIVATE'"
                  [disabled]="true"
                  tooltipPrivate="Only dataset admins and collaborators can download the dataset data file."
                  tooltipPublic="Anyone can download the dataset data file."
                ></app-public-private-toggle>
              </td>
            </ng-container>
            <ng-container matColumnDef="editMetadata">
              <th *matHeaderCellDef mat-header-cell>EDIT DETAILS</th>
              <td *matCellDef="let pd" mat-cell>
                <button
                  (click)="showDataset(toTypedDs(pd))"
                  *ngIf="!isLocked(toTypedDs(pd)) && toTypedDs(pd)._can_update_meta === true"
                  color="accent"
                  mat-mini-fab
                  type="button"
                >
                  <mat-icon matTooltip="Click here to edit dataset details/permissions">edit</mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="editDataFile">
              <th mat-header-cell *matHeaderCellDef [hidden]="true">EDIT DATA</th>
              <td mat-cell *matCellDef="let pd" [hidden]="true">
                <button
                  type="button"
                  (click)="!isLocked(toTypedDs(pd)) && editDatasetData(toTypedDs(pd))"
                  *ngIf="toTypedDs(pd)._can_upload_data === true && checkForFile(pd)"
                  [disabled]="isLocked(toTypedDs(pd))"
                  color="accent"
                  mat-mini-fab
                >
                  <mat-icon
                    [matTooltip]="
                      isLocked(toTypedDs(pd))
                        ? 'You do not have IRB approval to edit this data.'
                        : 'Click here to edit dataset data'
                    "
                  >
                    {{ !isLocked(toTypedDs(pd)) ? 'grid_on' : 'lock' }}
                  </mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="upload">
              <th *matHeaderCellDef mat-header-cell [hidden]="true">UPLOAD</th>
              <td *matCellDef="let pd" mat-cell [hidden]="true">
                <app-locked-icon
                  *ngIf="isLocked(toTypedDs(pd))"
                  tooltip="You do not have IRB approval to upload this dataset."
                ></app-locked-icon>
                <app-commons-file-upload
                  (uploadComplete)="onFileComplete()"
                  *ngIf="
                    user &&
                    toTypedDs(pd).id !== '' &&
                    toTypedDs(pd)._can_upload_data === true &&
                    !isLocked(toTypedDs(pd))
                  "
                  [target]="uploadUrlDataset(toTypedDs(pd))"
                  [user]="user"
                  color="primary"
                ></app-commons-file-upload>
              </td>
            </ng-container>
            <ng-container matColumnDef="download">
              <th *matHeaderCellDef mat-header-cell>DOWNLOAD</th>
              <td *matCellDef="let pd" mat-cell>
                <app-locked-icon
                  *ngIf="isLocked(toTypedDs(pd))"
                  tooltip="You do not have IRB approval to download this dataset."
                ></app-locked-icon>
                <button
                  (click)="downloadFile(toTypedDs(pd))"
                  *ngIf="checkForFile(pd)"
                  color="primary"
                  mat-mini-fab
                  type="button"
                >
                  <mat-icon
                    matTooltip="Click here to download dataset file ({{
                      toTypedDs(pd).dataset_file_versions[0].file_version.file_name
                    }})"
                  >
                    file_download
                  </mat-icon>
                </button>
              </td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; let odd = odd; columns: displayedColumns" [ngClass]="{gray: odd}" mat-row></tr>
          </table>
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-card-content>
  <mat-card-actions>
    <button (click)="showDataset()" *ngIf="showAddButton()" color="primary" mat-raised-button>
      <mat-icon>post_add</mat-icon>
      ADD DATASET
    </button>
  </mat-card-actions>
</mat-card>
