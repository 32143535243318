import {AbstractControl, ValidationErrors} from '@angular/forms';

/**
 * Returns a validation error if no leaves in a tree select have been chosen (if all keys are falsy)
 * @param control
 */
export const ValidateRequiredTreeSelect = (control: AbstractControl): null | ValidationErrors => {
  const treeValues = control.value;
  let valid = false;
  Object.keys(treeValues).forEach(key => {
    if (treeValues[key]) {
      valid = true;
    }
  });
  return valid ? null : {required: true};
};
