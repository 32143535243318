import {Component, Input} from '@angular/core';
import {Institution} from '@shared/types/institution';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {Resource} from '@shared/types/resource';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-user-resource-list',
  templateUrl: './user-resource-list.component.html',
  styleUrls: ['./user-resource-list.component.scss'],
})
export class UserResourceListComponent {
  resources: Resource[];
  @Input() institution: Institution;

  constructor(
    private api: ResourceApiService,
    private userService: UserService,
  ) {
    this.getUserResources();
  }

  get user(): User {
    return this.userService.getUser();
  }

  getUserResources() {
    this.api.getUserResources().subscribe(resources => {
      this.resources = resources;
    });
  }
}
