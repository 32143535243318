import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {Category} from '@shared/types/category';
import {User} from '@shared/types/user';
import {getcategoryRoute} from '@utilities/category-util';
import {scrollToSelector} from '@utilities/scroll-to-selector';
import {AppBaseComponent} from '@shared/components/app-base/app-base.component';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss'],
})
export class BrowseComponent extends AppBaseComponent implements OnInit {
  category: Category;
  allCategories: Category[];
  categoryId = 1;
  isDataLoaded = false;
  scrolling = false;
  dummyText = {
    category: `
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
      irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur.
    `,
    c: `
      This should be a brief statement about the nature of the section and the resources
      users can find within it. It should briefly describe the contents of this section
      and give an overview of the resources that will be within it.
    `,
    sc: `
      The dummy text is a page description on the next page that establishes the content
      of the definitions and glossary category.
    `,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ResourceApiService,
    private titleService: Title,
    public breakpointObserver: BreakpointObserver,
    private userService: UserService,
  ) {
    super(breakpointObserver);
    this.route.params.subscribe(params => {
      if (params && params.hasOwnProperty('category')) {
        this.categoryId = params['category'];
        this.loadCategory(this.categoryId);
      }
      this.loadAllCategories();
    });
  }

  get user(): User {
    return this.userService.getUser();
  }

  scrollToRouteCategory() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams && queryParams.hasOwnProperty('scrollTo')) {
        const selector = `#category_${queryParams['scrollTo']}`;
        scrollToSelector(selector);
      }
    });
  }

  loadCategory(categoryId: Number) {
    this.api.getCategory(categoryId).subscribe(category => {
      this.category = category;
      this.isDataLoaded = true;

      // Set page title
      const currentTitle = this.titleService.getTitle();
      this.titleService.setTitle(`${currentTitle} - ${this.category.name}`);
    });
  }

  loadAllCategories() {
    this.api.getCategories().subscribe(cats => {
      this.allCategories = cats;
      this.isDataLoaded = true;
      this.scrollToRouteCategory();
    });
  }

  categoryRoute(category: Category) {
    return getcategoryRoute(category, this.api);
  }

  updateCategory(category: Category) {
    if (category.level === 0) {
      this.loadAllCategories();
    } else {
      this.loadCategory(this.categoryId);
    }

    const selector = `category_${category.id}`;

    // Only one scrolling action at a time
    if (!this.scrolling) {
      this.scrolling = true;
      scrollToSelector(selector).then(_ => (this.scrolling = false));
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  headerImage(category) {
    return `url('assets/browse/${category.image}')`;
  }

  categoryIcon(c: Category) {
    if (c.icon) {
      return `ithriv_${c.icon_id}`;
    }
  }

  gerunding(word: string) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase().replace(/e$/, '') + 'ing';
  }

  hasCategories(): boolean {
    return this.category && this.allCategories && this.allCategories.length > 0;
  }

  // Returns all Level 0 categories before the selected category
  prevCategories() {
    const cats: Category[] = [];

    if (this.hasCategories()) {
      for (const c of this.allCategories) {
        if (c.id === this.category.id) {
          return cats;
        } else {
          cats.push(c);
        }
      }
    } else {
      return cats;
    }
  }

  // Returns all Level 0 categories after the selected category
  nextCategories() {
    const cats: Category[] = [];

    if (this.hasCategories()) {
      const allCatsReversed = this.allCategories.slice().reverse();

      for (const c of allCatsReversed) {
        if (c.id === this.category.id) {
          return cats;
        } else {
          cats.unshift(c);
        }
      }
    }
  }

  getSiblings(cat: Category): Category[] {
    if (cat.level === 0) {
      return this.allCategories;
    } else if (cat.level === 1) {
      for (const parent of this.allCategories) {
        if (parent.id === cat.parent_id) {
          return [parent];
        }
      }
    }
  }

  showMoveButtons() {
    return this.xl || this.lg || this.md;
  }

  getCategoryIconCSSClasses(c: Category, rootCat: Category) {
    const cssClasses = {
      'cat-icon-2': true,
      'category-bg': c.hover,
      'category-border': c.hover,
      'category-color': !c.hover,
    };

    cssClasses[rootCat.name] = true;

    return cssClasses;
  }
}
