import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Category} from '@shared/types/category';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-add-resource-button',
  templateUrl: './add-resource-button.component.html',
  styleUrls: ['./add-resource-button.component.scss'],
})
export class AddResourceButtonComponent {
  @Input() category: Category;
  @Input() user: User;
  @Input() eventButton?: boolean;
  @Input() resourceButton?: boolean;

  constructor(private router: Router) {
    this.eventButton = this.eventButton ? this.eventButton : true;
    this.resourceButton = this.resourceButton ? this.resourceButton : true;
  }

  async addResource(type: string) {
    let url = this.category ? `${type}/add/${this.category.id}` : `${type}/add/new`;
    await this.router.navigateByUrl(url);
  }
}
