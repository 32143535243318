<h2 mat-dialog-title>
  USER
  <span fxFlex></span>
  <button mat-dialog-close="" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content *ngIf="isLoaded" fxLayout="column">
  <mat-form-field *ngIf="!isDataset">
    <mat-label>Investigator Email</mat-label>
    <input [formControl]="emailControl" matInput required />
    <mat-error *ngIf="emailControl.hasError('required')">User Email is empty</mat-error>
    <mat-error *ngIf="emailControl.hasError('email')"
      ><markdown>{{ emailFormatsText }}</markdown></mat-error
    >
    <mat-hint [id]="''"
      >Enter {{ isDataset ? 'dataset' : 'project' }} user login email.
      <markdown>{{ emailFormatsText }}</markdown></mat-hint
    >
  </mat-form-field>

  <mat-form-field *ngIf="isDataset">
    <mat-label>{{ hasIrbNumber ? 'IRB ' : '' }}Investigator Email</mat-label>
    <ng-container *ngIf="hasIrbNumber">
      <mat-select [formControl]="emailControl" required>
        <mat-option *ngFor="let investigator of irbInvestigators" [value]="investigator.email">
          {{ investigator.email }}
        </mat-option>
      </mat-select>
    </ng-container>
    <ng-container *ngIf="!hasIrbNumber">
      <mat-select [formControl]="emailControl" required>
        <mat-option *ngFor="let teamMember of projectTeamMembers" [value]="teamMember.email">
          {{ teamMember.email }}
        </mat-option>
      </mat-select>
    </ng-container>
    <mat-error *ngIf="emailControl.hasError('required')">User Email is empty</mat-error>
    <mat-error *ngIf="emailControl.hasError('email')">User Email is invalid</mat-error>
    <mat-hint [id]="''">Select {{ isDataset ? 'dataset' : 'project' }} user login email</mat-hint>
  </mat-form-field>

  <mat-form-field>
    <mat-select [formControl]="permissionControl" placeholder="Select Role" required>
      <mat-option *ngFor="let datasetRole of permissions" [matTooltip]="datasetRole.tooltip" [value]="datasetRole">{{
        datasetRole.value
      }}</mat-option>
    </mat-select>
    <mat-error *ngIf="permissionControl.hasError('required')">User role not selected</mat-error>
    <mat-hint [id]="''">{{ isDataset ? 'Dataset' : 'Project' }} user permission</mat-hint>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="submit()" color="primary" mat-button mat-raised-button>
    <mat-icon>person</mat-icon>
    Save
  </button>
  <button (click)="onNoClick()" mat-button>
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</mat-dialog-actions>
