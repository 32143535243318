<div *ngIf="files.length > 0" class="popup">
  <h3>File Upload</h3>
  <h5>Upload in progress. Do not close this browser tab until the upload is complete.</h5>
  <mat-progress-bar *ngIf="fileZipping" mode="buffer"></mat-progress-bar>
</div>

<ul>
  <li *ngFor="let file of files" [@fadeInOut]="file.state">
    <mat-progress-bar *ngIf="!fileZipping" [value]="file.progress"></mat-progress-bar>
    <span id="file-label">
      {{ file.data.name }}
      <a title="Retry" (click)="retryFile(file)" *ngIf="file.canRetry"> <mat-icon>refresh</mat-icon></a>
      <a title="Cancel" (click)="cancelFile(file)" *ngIf="file.canCancel"> <mat-icon>cancel</mat-icon></a>
    </span>
  </li>
</ul>

<div *ngIf="files.length === 0">
  <ng-container *ngIf="!text">
    <button mat-button [color]="color" (click)="openFileFinder()" [disabled]="disableButton" mat-mini-fab>
      <mat-icon>file_upload</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="text">
    <button mat-raised-button [color]="color" (click)="openFileFinder()" [disabled]="disableButton">
      <mat-icon>file_upload</mat-icon>
      {{ text }}
    </button>
  </ng-container>
</div>

<br />
<input type="file" id="fileUpload" name="fileUpload" accept="{{ accept }}" style="display: none" />
