<div
  *ngFor="let c of prevCategories()"
  [routerLink]="categoryRoute(c)"
  class="mat-typography prev-category category-bg {{ c.name }}"
>
  <h1>{{ c.name }}</h1>
</div>

<div *ngIf="isDataLoaded && !category" class="mat-typography" id="browse-top-level">
  <div *ngFor="let rootCategory of allCategories" [routerLink]="categoryRoute(rootCategory)" class="header">
    <h1 class="mat-display-1">
      <mat-icon *ngIf="rootCategory.icon" svgIcon="{{ categoryIcon(rootCategory) }}"></mat-icon>
      {{ rootCategory.name }}
      <app-move-category-button
        (updatedCategory)="updateCategory(rootCategory)"
        *ngIf="showMoveButtons()"
        [category]="rootCategory"
        [direction]="'up'"
        [siblings]="allCategories"
        [user]="user"
        class="up"
      ></app-move-category-button>
      <app-move-category-button
        (updatedCategory)="updateCategory(rootCategory)"
        *ngIf="showMoveButtons()"
        [category]="rootCategory"
        [direction]="'down'"
        [siblings]="allCategories"
        [user]="user"
        class="down"
      ></app-move-category-button>
      <app-edit-category-button
        (updatedCategory)="updateCategory($event)"
        [category]="rootCategory"
        [user]="user"
      ></app-edit-category-button>
      <app-add-category-button [category]="rootCategory" [user]="user"></app-add-category-button>
    </h1>
    <markdown class="root-category-description">{{ rootCategory.brief_description || dummyText.category }}</markdown>
    <button mat-raised-button>Start {{ gerunding(rootCategory.name) }}</button>
    <div [ngStyle]="{'background-image': headerImage(rootCategory)}" class="bg-img"></div>
    <div [appGradientBackground]="rootCategory.color" class="gradient-overlay"></div>
  </div>
</div>
<div *ngIf="isDataLoaded && category" class="mat-typography" id="browse">
  <div class="header">
    <h1 class="mat-display-1" id="category_{{ category.id }}">
      <mat-icon *ngIf="category.icon" svgIcon="{{ categoryIcon(category) }}"></mat-icon>
      {{ category.name }}
      <app-edit-category-button
        (updatedCategory)="updateCategory($event)"
        [category]="category"
        [user]="user"
      ></app-edit-category-button>
      <app-add-category-button [category]="category" [user]="user"></app-add-category-button>
      <app-move-category-button
        (updatedCategory)="updateCategory(category)"
        *ngIf="showMoveButtons()"
        [category]="category"
        [direction]="'up'"
        [siblings]="getSiblings(category)"
        [user]="user"
        class="up"
      ></app-move-category-button>
      <app-move-category-button
        (updatedCategory)="updateCategory(category)"
        *ngIf="showMoveButtons()"
        [category]="category"
        [direction]="'down'"
        [siblings]="getSiblings(category)"
        [user]="user"
        class="down"
      ></app-move-category-button>
    </h1>
    <markdown class="category-description">{{ category.description || dummyText.category }}</markdown>
    <div [ngStyle]="{'background-image': headerImage(category)}" class="bg-img"></div>
    <div [appGradientBackground]="category.color" class="gradient-overlay"></div>
  </div>

  <div>
    <section
      *ngFor="let c of category.children"
      [id]="'category_' + c.id"
      class="sub-category category-border {{ category.name }}"
    >
      <div>
        <mat-icon
          (mouseleave)="c.hover = false"
          (mouseover)="c.hover = true"
          *ngIf="c.icon"
          [routerLink]="categoryRoute(c)"
          [ngClass]="getCategoryIconCSSClasses(c, category)"
          svgIcon="{{ categoryIcon(c) }}"
        ></mat-icon>
        <h2 id="category_{{ c.id }}">{{ c.name }}</h2>
      </div>
      <app-edit-category-button
        (updatedCategory)="updateCategory($event)"
        [category]="c"
        [parent]="category"
        [user]="user"
      ></app-edit-category-button>
      <app-add-category-button [category]="c" [user]="user"></app-add-category-button>
      <app-move-category-button
        (updatedCategory)="updateCategory(c)"
        *ngIf="showMoveButtons()"
        [category]="c"
        [direction]="'up'"
        [siblings]="category.children"
        [user]="user"
        class="up"
      ></app-move-category-button>
      <app-move-category-button
        (updatedCategory)="updateCategory(c)"
        *ngIf="showMoveButtons()"
        [category]="c"
        [direction]="'down'"
        [siblings]="category.children"
        [user]="user"
        class="down"
      ></app-move-category-button>
      <markdown class="sub-category-description">{{ c.description || dummyText.c }}</markdown>

      <div
        class="sub-categories container"
        fxLayout="row wrap"
        fxLayout.xs="column"
        fxLayoutAlign="center"
        fxLayoutGap="0px grid"
      >
        <mat-card
          *ngFor="let sc of c.children"
          [routerLink]="categoryRoute(sc)"
          class="sub-sub-category"
          fxFlex="30%"
          id="category_{{ sc.id }}"
        >
          <mat-card-header>
            <div *ngIf="sc.icon" mat-card-avatar>
              <mat-icon class="cat-icon-3 category-bg {{ c.name }}" svgIcon="{{ categoryIcon(sc) }}"></mat-icon>
            </div>
            <mat-card-title>{{ sc.name }}</mat-card-title>
            <mat-card-subtitle>
              <markdown class="sub-sub-category-description">{{ sc.brief_description || dummyText.sc }}</markdown>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content></mat-card-content>
          <mat-card-footer>
            <p [ngStyle]="{color: sc.color}">{{ sc.resource_count || 0 }} resources/events</p>
          </mat-card-footer>
          <app-edit-category-button
            (updatedCategory)="updateCategory($event)"
            [category]="sc"
            [parent]="c"
            [user]="user"
          ></app-edit-category-button>
          <app-move-category-button
            (updatedCategory)="updateCategory(sc)"
            *ngIf="showMoveButtons()"
            [category]="sc"
            [direction]="'up'"
            [siblings]="c.children"
            [user]="user"
            class="up"
          ></app-move-category-button>
          <app-move-category-button
            (updatedCategory)="updateCategory(sc)"
            *ngIf="showMoveButtons()"
            [category]="sc"
            [direction]="'down'"
            [siblings]="c.children"
            [user]="user"
            class="down"
          ></app-move-category-button>
        </mat-card>
      </div>
    </section>
  </div>
</div>

<div
  *ngFor="let c of nextCategories()"
  [routerLink]="categoryRoute(c)"
  class="mat-typography next-category category-bg {{ c.name }}"
>
  <h1>{{ c.name }}</h1>
</div>
