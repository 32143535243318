import {Component} from '@angular/core';

@Component({
  selector: 'app-vpn-warning',
  templateUrl: './vpn-warning.component.html',
  styleUrls: ['./vpn-warning.component.scss'],
})
export class VpnWarningComponent {
  constructor() {}
}
