<div *ngIf="user">
  <div
    class="container"
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="left"
    fxLayoutGap="10px"
    fxLayoutGap.xs="0"
  >
    <button mat-mini-fab (click)="handleClick($event)" matTooltip="{{ instructions() }}">
      <mat-icon [ngClass]="{active: isUserFavorite()}">grade</mat-icon>
    </button>
  </div>
</div>
