export type NodeOptionsRelationship = 'root' | 'child' | 'self';

export interface NodeOptionsParams {
  relationship?: NodeOptionsRelationship;
  x: number;
  y: number;
  radius?: number;
  angle?: number;
  titleHeight?: number;
}

export class NodeOptions implements NodeOptionsParams {
  relationship: NodeOptionsRelationship;
  x: number;
  y: number;
  radius: number;
  angle: number;
  titleHeight: number;

  constructor(private _props: NodeOptionsParams) {
    for (const propName in this._props) {
      if (this._props.hasOwnProperty(propName)) {
        this[propName] = this._props[propName];
      }
    }
  }
}
