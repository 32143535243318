<app-commons-menu [navItems]="navItems" [title]="datasetTitle">
  <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</app-commons-menu>

<ng-template #pageContent>
  <div id="error_message_delete" class="display mat-error" *ngIf="error">
    <app-error-message [errorString]="error"></app-error-message>
  </div>
  <ng-container *ngIf="isLoaded; else loadingMessage">
    <mat-card *ngIf="userService.user" [ngClass]="{'create-edit': true, 'form-bg': true}">
      <mat-card-header>
        <mat-card-title> Dataset Details </mat-card-title>
        <span fxFlex></span>
      </mat-card-header>
      <mat-card-content *ngIf="userService.user" class="form-bg">
        <form *ngIf="formStatus === 'form' && formContainer && fg" [formGroup]="fg" (ngSubmit)="submitDataset($event)">
          <mat-card
            *ngFor="let fieldset of fieldsets"
            [fxHide]="fieldset.hidden"
            fxLayout="column"
            fxLayoutGap="40px"
            ngClass="fieldset"
          >
            <mat-card-title *ngIf="!fieldset.hidden && fieldset.label">{{ fieldset.label }}</mat-card-title>
            <mat-card-content *ngFor="let field of fieldset.fields">
              <app-form-field
                [errors]="field.formControl ? field.formControl.errors : field.formGroup.errors"
                [field]="field"
                [formGroup]="fg"
                [errorMatcher]="errorMatcher"
                [hidden]="field.hidden"
              ></app-form-field>
            </mat-card-content>
          </mat-card>

          <footer *ngIf="formStatus === 'form'">
            <div id="error_message" class="display mat-error" *ngIf="errorMessage">
              <app-error-message [errorString]="errorMessage"></app-error-message>
            </div>
            <button type="button" color="primary" mat-raised-button (click)="submitDataset($event)">
              <mat-icon>save_alt</mat-icon>
              Save
            </button>
            <button type="button" (click)="cancelDataset()" mat-button>
              <mat-icon>cancel</mat-icon>
              Cancel
            </button>
          </footer>
        </form>
        <ng-container *ngIf="formStatus !== 'form'">
          <div *ngIf="formStatus === 'submitting'" fxLayout="column" fxLayoutAlign="space-around center">
            <mat-spinner [diameter]="48"></mat-spinner>
            <button type="button" (click)="onCancel()" mat-button>Cancel</button>
          </div>
          <div *ngIf="formStatus === 'complete'">
            <p>
              <b>Commons dataset {{ dataset.name }} successfully created. </b>
            </p>
            <button type="button" color="primary" mat-raised-button (click)="showForm()">
              <mat-icon>help</mat-icon>
              Create another dataset
            </button>
            <button type="button" color="primary" mat-button (click)="cancelDataset()">Continue</button>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
    <mat-divider [inset]="true"></mat-divider>
    <mat-card
      *ngIf="
        userService.user && formStatus === 'form' && isEdit && datasetWithPermissions?._can_manage_permission === true
      "
      [ngClass]="'create-edit'"
    >
      <mat-card-header>
        <mat-card-title id="datasetPermissionsLabel">Dataset Permissions</mat-card-title>
      </mat-card-header>
      <mat-card-subtitle>
        <markdown>{{ emailFormatsText }}</markdown>
      </mat-card-subtitle>
      <mat-card-content *ngIf="userService.user">
        <mat-list dense>
          <table
            mat-table
            [dataSource]="userPermissions?.team"
            class="datasetperms"
            aria-describedby="datasetPermissionsLabel"
          >
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>EMAIL</th>
              <td mat-cell *matCellDef="let userPermission">
                <mat-label>
                  {{ toTypedUP(userPermission).user.email }}
                </mat-label>
              </td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef>ROLE</th>
              <td mat-cell *matCellDef="let userPermission">
                {{ toTypedUP(lookupRole(userPermission.dataset_role_id)) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef>EDIT</th>
              <td mat-cell *matCellDef="let userPermission">
                <button
                  type="button"
                  (click)="editPermission(userPermission)"
                  color="accent"
                  mat-mini-fab
                  *ngIf="userPermission.user.email !== userService.user.email"
                >
                  <mat-icon matTooltip="Click here to edit dataset user permission"> edit </mat-icon>
                </button>
                <button
                  type="button"
                  (click)="displaySelfError('edit')"
                  color="disabled"
                  mat-mini-fab
                  *ngIf="userPermission.user.email === userService.user.email"
                >
                  <mat-icon matTooltip="Can't edit your own permission"> edit </mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef>DELETE</th>
              <td mat-cell *matCellDef="let userPermission">
                <button
                  type="button"
                  (click)="deletePermission(userPermission)"
                  color="warn"
                  mat-mini-fab
                  *ngIf="userPermission.user.email !== userService.user.email"
                >
                  <mat-icon matTooltip="Click here to delete user's dataset permission"> delete </mat-icon>
                </button>
                <button
                  type="button"
                  (click)="displaySelfError('delete')"
                  color="disabled"
                  mat-mini-fab
                  *ngIf="userPermission.user.email === userService.user.email"
                >
                  <mat-icon matTooltip="Can't disavow your own permission"> delete </mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedUserpermColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; let odd = odd; columns: displayedUserpermColumns"
              [ngClass]="{gray: odd}"
            ></tr>
          </table>
        </mat-list>
        <div *ngIf="isHSD(dataset) === true">
          <mat-card-subtitle>
            You can only give permissions to team members listed on your IRB approval who are EMPLOYED BY (or official
            VOLUNTEER under) a HIPAA-COVERED ENTITY. Refer to your approved personnel IRB documentation.
          </mat-card-subtitle>
        </div>
        <div id="error_message_permission" class="display mat-error" *ngIf="errorMessagePerm">
          <app-error-message [errorString]="errorMessagePerm"></app-error-message>
        </div>
      </mat-card-content>
      <mat-card-actions id="dataset_permissions">
        <button type="button" color="primary" mat-raised-button (click)="addPermission()">
          <mat-icon>security</mat-icon>
          ADD PERMISSION
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</ng-template>

<ng-template #loadingMessage>
  <app-loading message="Loading dataset..." size="sm"></app-loading>
</ng-template>
