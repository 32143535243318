<div class="mat-typography">
  <div mat-dialog-content>
    <p>This will permanently delete the dataset file "{{ data.document_name }}".</p>
    <p>By clicking CONFIRM DELETE, you agree that this deletion aligns with local records management policies.</p>
  </div>
  <div mat-dialog-actions>
    <div class="select-mode" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <button (click)="onSubmit()" color="primary" mat-flat-button>
        <mat-icon>check</mat-icon>
        Confirm Delete
      </button>
      <button (click)="onNoClick()" color="warn" mat-flat-button>
        <mat-icon>close</mat-icon>
        Cancel
      </button>
    </div>
  </div>
</div>
