import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Advanced formatting needed for timezone format. advancedFormat requires utc and timezone plugins.
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
export default dayjs;
