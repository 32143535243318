<ng-container *ngIf="resource && resource.id">
  <ng-container *ngIf="resource.segment.name === 'Event'">
    <app-event-tile [event]="resource"></app-event-tile>
  </ng-container>
  <ng-container *ngIf="resource.segment.name === 'Resource'">
    <mat-card
      *ngIf="resource && resource.id"
      [routerLink]="resourceRoute(resource)"
      [ngClass]="{resource: true, private: resource.private}"
      [hidden]="!resource.user_may_view"
    >
      <div class="category-color" fxLayout="row">
        <div
          class="category-color-item {{ getRootCategoryName(cat) }}"
          fxFlex
          *ngFor="let cat of resource.resource_categories"
          [matTooltip]="getCategoryBreadcrumbs(cat)"
        ></div>
      </div>

      <div class="icon-with-content" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
        <div fxFlex="70px">
          <div class="type-icon" *ngIf="typeIconId(resource)">
            <mat-icon svgIcon="ithriv_{{ typeIconId(resource) }}" matTooltip="{{ resource.type.name }}"></mat-icon>
          </div>
          <div *ngIf="resource.institution.name !== 'Public'">
            <div class="institution" matTooltip="{{ resource.institution.name }}">
              <img [alt]="resource.institution.name" [src]="getInstitutionLogoUrl(resource.institution)" />
            </div>
          </div>
        </div>

        <div fxFlex="calc(100% - 90px)">
          <mat-card-header [routerLink]="resourceRoute(resource)">
            <mat-card-title>{{ resource.name }}</mat-card-title>
            <app-approved-badge [resource]="resource" [user]="user"></app-approved-badge>
            <app-favorite-resource-button [resource]="resource" [user]="user"></app-favorite-resource-button>
          </mat-card-header>
          <mat-card-content [routerLink]="resourceRoute(resource)">
            <markdown [data]="resource.description | truncateWords"></markdown>
          </mat-card-content>
          <mat-card-footer> </mat-card-footer>
        </div>
      </div>
    </mat-card>
  </ng-container>
</ng-container>
