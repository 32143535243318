<div class="mat-typography">
  <h2 mat-dialog-title>
    VPN Disconnected
    <span fxFlex></span>
    <button mat-dialog-close="" mat-icon-button id="dismissVPNWarning">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content>
    <p>
      You need to stay connected on your <strong>high security institutional VPN</strong> to access Private Data Commons
      projects.
    </p>
    <p>
      Loading your project list may take several seconds, or you may need to reload the page. Please contact
      <a href="mailto:iTHRIVAdmin@uvahealth.org">iTHRIVAdmin&#64;uvahealth.org</a> if the issue persists.
    </p>
  </mat-dialog-content>
</div>
