<div class="main">
  <mat-sidenav-container>
    <mat-sidenav
      #sidenav
      [mode]="mobileQuery?.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery?.matches"
      fixedTopGap="56"
    >
      <mat-nav-list>
        <mat-list-item>
          <button mat-button (click)="closeSidenav(); goHome($event)">
            <mat-icon>home</mat-icon>
            Home
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="user && user.role === 'Admin'">
          <button mat-button (click)="closeSidenav(); goUserAdmin($event)" id="search-admin-button-mobile">
            <mat-icon>sync</mat-icon>
            Search Index Administration
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="user && user.role === 'Admin'">
          <button mat-button (click)="closeSidenav(); goUserAdmin($event)" id="user-admin-button-mobile">
            <mat-icon>person_add</mat-icon>
            User Administration
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="!user">
          <button mat-button (click)="closeSidenav(); goLogin($event)" id="login-button-mobile">
            <mat-icon>account_circle</mat-icon>
            Log in
          </button>
        </mat-list-item>
        <ng-container *ngIf="user">
          <mat-list-item>
            <button mat-button (click)="closeSidenav(); goHome($event)">
              <mat-icon>account_circle</mat-icon>
              {{ user.display_name }} ({{ timeLeftInSession | timeLeft }})
            </button>
          </mat-list-item>
          <mat-list-item>
            <button mat-button (click)="closeSidenav(); goLogout($event)" id="logout-button-mobile">
              <mat-icon>exit_to_app</mat-icon>
              Log Out
            </button>
          </mat-list-item>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar *ngIf="!hideHeader" class="mat-toolbar-tools">
        <mat-toolbar-row class="menubar menubar-xs" *ngIf="mobileQuery?.matches">
          <button mat-icon-button (click)="toggleSidenav()">
            <mat-icon>menu</mat-icon>
          </button>
          <a mat-button [routerLink]="['/home']" [queryParams]="{}" aria-label="Go Home">
            <img src="/assets/logo/ithriv-logomark.png" alt="iTHRIV" class="logomark" />
            iTHRIV Research Concierge Portal
          </a>
        </mat-toolbar-row>
        <mat-toolbar-row class="menubar" [ngClass]="toolBarWarningClass()" *ngIf="!mobileQuery?.matches">
          <div class="view">
            <div *ngIf="!user" [matTooltip]="'Resources are curated for public partners.'">PUBLIC VIEW</div>
            <div *ngIf="user">INSTITUTIONAL VIEW</div>
          </div>
          <span class="fill-remaining-space"></span>

          <ng-container *ngIf="user">
            <a mat-button *ngIf="user.role === 'Admin' && isDev" id="dev-timeout-button" (click)="setTimeout(10)">
              <mat-icon>time</mat-icon>
              Timeout in 10 seconds
            </a>

            <a mat-button *ngIf="showTimeoutWarning" id="extend-session-button" (click)="setTimeout(2 * 60 * 60)">
              <mat-icon>time</mat-icon>
              Extend session 2 hours
            </a>

            <a mat-button *ngIf="user.role === 'Admin'" id="search-admin-button" [routerLink]="['/admin']">
              <mat-icon>sync</mat-icon>
              Search Index Administration
            </a>

            <a mat-button *ngIf="user.role === 'Admin'" id="user-admin-button" [routerLink]="['/admin/users']">
              <mat-icon>person_add</mat-icon>
              User Administration
            </a>
            <a
              mat-button
              *ngIf="user"
              [routerLink]="['/profile']"
              id="user_{{ user.id }}"
              [ngClass]="{'user-name': true}"
            >
              <mat-icon>account_circle</mat-icon>
              {{ user.display_name }}
              <ng-container *ngIf="showTimeoutWarning">&nbsp; ({{ timeLeftInSession | timeLeft }})</ng-container>
            </a>
            <button mat-button *ngIf="user" (click)="goLogout($event)" id="logout-button">
              <mat-icon>exit_to_app</mat-icon>
              Log Out
            </button>
          </ng-container>
          <ng-container *ngIf="!user">
            <button mat-button *ngIf="!user && institution" (click)="goLogin($event)">
              Go To {{ institution.name }} View
            </button>
            <button
              mat-button
              *ngIf="!user"
              (click)="goLogin($event)"
              id="login-button"
              matTooltip="Resources are curated for your institution. Additional features are available such as adding and managing content. Private institutional content is visible."
            >
              <mat-icon>account_circle</mat-icon>
              Log in
            </button>
          </ng-container>
        </mat-toolbar-row>
        <mat-toolbar-row *ngIf="!user" class="public" (click)="goLogin()">
          <a (click)="goLogin()">LOG IN to access an Institutional View.</a>
        </mat-toolbar-row>
        <mat-toolbar-row
          fxLayout="row"
          fxLayoutAlign.gt-xs="space-around center"
          fxLayoutAlign.xs="space-around center"
          fxLayoutGap.gt-xs="20px"
          fxLayoutGap.xs="10px"
          class="logo"
          [style.marginTop.px]="mobileQuery?.matches ? 56 : 0"
        >
          <h1 *ngIf="!mobileQuery?.matches">
            <a href="#" [routerLink]="['/home']" [queryParams]="{}" aria-label="Go Home" id="logomark-link">
              <img src="/assets/logo/ithriv-logomark.png" alt="iTHRIV" class="logomark" />
              Research Concierge Portal
            </a>
          </h1>
          <span *ngIf="!mobileQuery?.matches" class="fill-remaining-space"></span>
          <button mat-stroked-button [routerLink]="['/help']" fxFlex.xs="calc(50% - 20px)">
            <mat-icon>help</mat-icon>
            Help
          </button>
          <button *ngIf="user && showConsultRequest()" mat-stroked-button (click)="goConsult($event)" color="primary">
            <mat-icon>help</mat-icon>
            Request a consult
          </button>
          <app-logo
            *ngIf="!mobileQuery?.matches"
            [user]="this.user"
            [showPartner]="false"
            id="logo-ithriv-link"
          ></app-logo>
          <app-logo
            *ngIf="!mobileQuery?.matches && this.user"
            [user]="this.user"
            [showPartner]="!!this.user"
            id="logo-horizontal-link"
          ></app-logo>
        </mat-toolbar-row>

        <mat-toolbar-row
          fxLayout="row wrap"
          fxLayoutAlign="space-around center"
          fxLayoutGap="20px"
          class="secondary-nav"
        >
          <span *ngIf="!mobileQuery?.matches" class="fill-remaining-space"></span>
          <app-add-resource-button [category]="" [user]="user"></app-add-resource-button>
          <button
            [routerLink]="['/search']"
            fxFlex.xs="calc(50% - 20px)"
            id="search-button"
            mat-stroked-button
            [disabled]="isSearch"
          >
            <mat-icon>search</mat-icon>
            Search Resources
          </button>
          <button
            (click)="isExpanded = !isExpanded"
            fxFlex.xs="calc(50% - 20px)"
            id="expand-categories"
            mat-stroked-button
            *ngIf="shouldDisplaySearchBar"
          >
            Browse Resources
            <mat-icon>{{ isExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>

          <ng-container *ngIf="!shouldDisplaySearchBar">
            <div>Change View:</div>
            <mat-button-toggle-group #group="matButtonToggleGroup">
              <mat-button-toggle
                (click)="viewMode(group.value)"
                [value]="true"
                matTooltip="View network graph of category relationships"
                color="primary"
                [checked]="isNetworkView"
              >
                <mat-icon>device_hub</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle
                (click)="viewMode(group.value)"
                [value]="false"
                matTooltip="View category/resource listing"
                color="primary"
                [checked]="!isNetworkView"
              >
                <mat-icon>view_list</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </ng-container>
        </mat-toolbar-row>
      </mat-toolbar>
      <app-search-bar *ngIf="!hideHeader && shouldDisplaySearchBar" [isExpanded]="isExpanded"></app-search-bar>

      <div class="content" fxFlexFill>
        <h1>{{ pageTitle }}</h1>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
