import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {FormContainer} from '@form-controls/form-container';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {FormField} from '@shared/types/form-field';
import {User} from '@shared/types/user';
import {ErrorMatcher} from '@shared/validators/error-matcher';

@Component({
  selector: 'app-consult-request-form',
  templateUrl: './consult-request-form.component.html',
  styleUrls: ['./consult-request-form.component.scss'],
})
export class ConsultRequestFormComponent implements OnInit {
  errorMessage: string;
  errorMatcher = new ErrorMatcher();
  requestConsultForm: UntypedFormGroup = new UntypedFormGroup({});
  formStatus = 'form';

  fields = {
    request_category: new FormField({
      formGroup: this.requestConsultForm,
      formControl: new UntypedFormControl(),
      required: true,
      placeholder: 'Pick the category that best represents your request:',
      type: 'select',
      selectOptions: [],
    }),
    request_title: new FormField({
      formGroup: this.requestConsultForm,
      formControl: new UntypedFormControl(),
      required: true,
      placeholder: 'Please provide an issue summary for which you are requesting a consult:',
      type: 'textarea',
      maxLength: 100,
      options: {
        status: ['words'],
      },
    }),
    request_text: new FormField({
      formGroup: this.requestConsultForm,
      formControl: new UntypedFormControl(),
      required: true,
      placeholder: 'Please describe the issue for which you are requesting a consult:',
      type: 'textarea',
      options: {
        status: ['words'],
      },
    }),
  };
  formContainer = new FormContainer(this.fields, this.requestConsultForm);
  displayedColumns = ['reference_id', 'request_type', 'summary', 'status', 'create_date'];
  @ViewChild('input') input: ElementRef;

  constructor(
    private router: Router,
    private api: ResourceApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    public snackBar: MatSnackBar,
  ) {
    this.api.getConsultCategoryList().subscribe(categoryList => {
      this.fields['request_category'] = new FormField({
        formGroup: this.requestConsultForm,
        formControl: new UntypedFormControl(),
        required: true,
        placeholder: 'Pick the category that best represents your request:',
        type: 'select',
        selectOptions: categoryList,
      });
    });
  }

  ngOnInit() {
    this.formContainer.loadForm();
  }

  get user(): User {
    return this.userService.getUser();
  }

  getFields() {
    return this.formContainer.getFields();
  }

  onSubmit() {
    this.formContainer.validate();
    if (!this.requestConsultForm.valid) {
      return;
    }
    this.formStatus = 'submitting';

    this.api
      .sendConsultRequest(
        this.user,
        this.fields.request_category.formGroup.value.request_category,
        'Inquiry',
        this.fields.request_title.formControl.value,
        this.fields.request_text.formControl.value,
      )
      .subscribe(
        e => {
          this.errorMessage = '';
          this.formStatus = 'complete';
          this.snackBar.open(
            'Thank you for submitting your request to iTHRIV. You can expect a response via email within two business days.',
            'Ok',
            {duration: 3000},
          );
        },
        error1 => {
          if (error1) {
            this.errorMessage = error1;
          }
          this.formStatus = 'form';
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  onCancel() {
    this.router.navigate(['']);
  }

  showConsultRequestForm() {
    this.formContainer.reset();
    this.formStatus = 'form';
  }
}
