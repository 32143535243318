import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {ActivatedRoute} from '@angular/router';
import {ACCEPTABLE_COMMONS_EMAIL_FORMATS, ACCEPTABLE_PORTAL_EMAIL_FORMATS} from '@authentication/login-service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {FormField} from '@shared/types/form-field';
import {FormSelectOption, FormSelectOptionProps} from '@shared/types/form-select-option';
import dayjs from 'dayjs';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';
import {lastValueFrom} from 'rxjs';

interface DateRanges {
  [index: string]: [dayjs.Dayjs, dayjs.Dayjs];
}

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit {
  @Input() field: FormField;
  @Input() errors: ValidationErrors;
  @Input() errorMatcher: ErrorStateMatcher;
  @Input() formGroup: UntypedFormGroup;
  @ViewChild(DaterangepickerDirective, {static: false}) dateTimePicker: DaterangepickerDirective;
  public tools: object = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'Undo',
      'Redo',
      '|',
      'Alignments',
      '|',
      'OrderedList',
      'UnorderedList',
      '|',
      'Indent',
      'Outdent',
      '|',
      'CreateTable',
      '|',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      '|',
      'FullScreen',
    ],
  };
  options: FormSelectOption[] = [];
  public min = new Date();
  ranges: DateRanges = {
    Today: [dayjs(), dayjs()],
    Tomorrow: [dayjs().add(1, 'days'), dayjs().add(1, 'days')],
    'Next 7 Days': [dayjs(), dayjs().add(6, 'days')],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Next Month': [dayjs().add(1, 'month').startOf('month'), dayjs().add(1, 'month').endOf('month')],
  };

  constructor(
    private route: ActivatedRoute,
    private api: ResourceApiService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  get emailFormatsText() {
    const isCommons = this.route.pathFromRoot.toString().includes('commons');
    return isCommons ? ACCEPTABLE_COMMONS_EMAIL_FORMATS : ACCEPTABLE_PORTAL_EMAIL_FORMATS;
  }

  ngOnInit() {
    this.loadOptions();

    this.formGroup.valueChanges.subscribe(_ => {
      if (this.field && this.field.formControl) {
        this.field.formControl.updateValueAndValidity({onlySelf: true});
      }
    });

    if (
      ![null, undefined].includes(this.field.defaultValue) &&
      [null, undefined].includes(this.field.formControl.value)
    ) {
      this.field.formControl.setValue(this.field.defaultValue);
    }
  }

  /** Convert an array of strings or FormSelectOption instances to an array of FormSelectOption instances. */
  toFormSelectOptions(selectOptions: string[] | FormSelectOptionProps[] | FormSelectOption[]): FormSelectOption[] {
    // selectOptions could be an array of strings, a JSON object, or an array of FormSelectOption instances.
    return selectOptions.map((v: string | FormSelectOptionProps | FormSelectOption) => {
      // Convert string options to FormSelectOption instances.
      if (v instanceof FormSelectOption) {
        return v;
      }
      if (typeof v === 'string') {
        return new FormSelectOption({id: v, name: v});
      }
      if (typeof v === 'object') {
        return new FormSelectOption(v);
      }
    });
  }

  async loadOptions() {
    if (['select', 'image_select', 'multicheckbox', 'radio'].includes(this.field.type)) {
      if (
        this.field.hasOwnProperty('selectOptions') &&
        this.field.selectOptions &&
        Array.isArray(this.field.selectOptions)
      ) {
        // selectOptions could be an array of strings or an array of FormSelectOption instances.
        this.options = this.toFormSelectOptions(this.field.selectOptions);
      } else if (this.field.hasOwnProperty('apiSource')) {
        const source = this.field.apiSource;

        if (this.api[source] && typeof this.api[source] === 'function') {
          const results: string[] | FormSelectOptionProps[] = await lastValueFrom(this.api[source]());
          this.options = this.toFormSelectOptions(results);
          this.field.formControl.updateValueAndValidity();
        }
      }
    } else if (['boolean'].includes(this.field.type) && this.field.booleanMode === 'buttonToggle') {
      this.field.formControl.setValue(this.field.formControl.value === 'PRIVATE' ? 'PRIVATE' : 'NOT PRIVATE');
    }
    //
    // this.changeDetectorRef.detectChanges();
  }

  clearValue(fc) {
    fc.setValue('');
  }

  currentLength() {
    return (
      (this.field && this.field.formControl && this.field.formControl.value && this.field.formControl.value.length) || 0
    );
  }

  isTextField(field: FormField) {
    return ['text', 'url', 'email', 'password', 'number'].indexOf(field.type) > -1;
  }

  isNormalField(field: FormField) {
    return !(
      ['boolean', 'multicheckbox', 'radio', 'files', 'image_select', 'list', 'daterange', 'tree'].indexOf(field.type) >
      -1
    );
  }

  toggleNoneOption($event: MouseEvent, option: FormSelectOption, isMultiSelect: boolean) {
    const label = option.name;
    const nonePattern = /^NONE\b/i;
    const naPattern = /^N\/A\b/i;
    const noneNaPattern = /^NONE\b|^N\/A\b/i;
    const blankPattern = /--/i;

    // Remove the None selected option from single-select fields:
    if (!isMultiSelect && this._matches(label, blankPattern)) {
      this.field.formControl.setValue(undefined);
      return;
    }

    // If user selects NONE, deselect all other options.
    if (this._matches(label, nonePattern)) {
      this.deselectAllExceptMatches(nonePattern);
    }

    // If they select N/A, deselect all other options.
    else if (this._matches(label, naPattern)) {
      this.deselectAllExceptMatches(naPattern);
    }

    // If they select something else, deselect NONE and N/A.
    else {
      this.deselectMatches(noneNaPattern);
    }

    this.handleSelectionListChange();
  }

  /** Returns true if given value matches the given Regular Expression pattern */
  _matches(value: string | number, pattern: RegExp): boolean {
    // Reset the RegExp object
    pattern.lastIndex = 0;

    return pattern.test(value.toString());
  }

  deselectAllExceptMatches(pattern: RegExp) {
    const newOptions = this.options.filter(option => this._matches(option.name, pattern)).map(o => o.id);
    this.field.formControl.setValue(newOptions);
  }

  deselectMatches(pattern: RegExp) {
    const selectedIds = this.field.formControl.value;
    if (selectedIds && Array.isArray(selectedIds)) {
      // Deselect any currently-selected options that match the given pattern.
      const nonMatchingOptions = this.options
        .filter(o => {
          return (
            selectedIds.includes(o.id) && // Is in set of selected options
            !this._matches(o.name, pattern) // Does not match the given pattern
          );
        })
        .map(o => o.id);

      // Set the form control value to the set of options that were not deselected.
      this.field.formControl.setValue(nonMatchingOptions);
    }
  }

  handleSelectionListChange() {
    if (this.field && this.field.formControl && this.field.formControl.parent) {
      this.field.formControl.parent.updateValueAndValidity();
    }
    this.changeDetectorRef.detectChanges();
  }

  setValue($event) {
    this.field.formControl.setValue($event.value);
  }

  openDateTimePicker($event: MouseEvent | KeyboardEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dateTimePicker.open($event);
  }

  getFieldOptions() {
    return this.field.selectOptions || this.field.selectOptions || this.options;
  }
}
