export interface FormSelectOptionProps {
  id: string | number;
  name: string;
  color?: string;
  image?: string;
  readonly?: boolean;
  tooltip?: string;
}

export class FormSelectOption implements FormSelectOptionProps {
  id: string | number;
  name: string;
  color?: string;
  image?: string;
  readonly?: boolean;
  tooltip?: string;

  constructor(private _props: FormSelectOptionProps) {
    for (const propName in this._props) {
      if (this._props.hasOwnProperty(propName)) {
        this[propName] = this._props[propName];
      }
    }
  }
}
