import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ShortenWordPipe} from '@shared/pipes/shorten-word/shorten-word.pipe';
import {TimeLeftPipe} from '@shared/pipes/time-left/time-left.pipe';
import {TruncateWordsPipe} from '@shared/pipes/truncate-words/truncate-words.pipe';
import {SanitizePipe} from '@shared/pipes/sanitize/sanitize.pipe';

@NgModule({
  declarations: [TimeLeftPipe, TruncateWordsPipe, ShortenWordPipe, SanitizePipe],
  imports: [CommonModule],
  providers: [SanitizePipe],
  exports: [TimeLeftPipe, TruncateWordsPipe, ShortenWordPipe, SanitizePipe],
})
export class PipesModule {}
