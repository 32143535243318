import {AbstractControl, ValidationErrors} from '@angular/forms';

/**
 * Returns a validation error if the users selects extract redcap report without entering a redcap ID.
 * @param control
 */
export const ValidateRequiredIfREDCapExtractData = (control: AbstractControl): null | ValidationErrors => {
  const formGroup = control.parent;
  const redcapExtractData = formGroup?.get('extract_options');
  const isRequired = redcapExtractData?.value === 'redcap_report';

  if (control && isRequired) {
    return !control.value ? {required: true} : null;
  }

  return null;
};
