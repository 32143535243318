/** showProgress
 * Return distinct message for sent, upload progress, & response events */
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {EventEmitter} from '@angular/core';

export const showProgress = (event: HttpEvent<any>, progressUpdated: EventEmitter<number>, result?: any): any => {
  let value = 0;

  switch (event.type) {
    case HttpEventType.Sent:
      value = 0;
      break;
    case HttpEventType.UploadProgress:
      value = Math.round((100 * event.loaded) / event.total);
      break;
    case HttpEventType.DownloadProgress:
      value = Math.round((100 * event.loaded) / event.total);
      break;
    case HttpEventType.Response:
      value = 100;
      return result || event.body;
    default:
      break;
  }

  progressUpdated.emit(value);
};
