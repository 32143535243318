<div class="mat-typography">
  <h2 mat-dialog-title>
    Related Resources
    <span fxFlex></span>
    <button mat-dialog-close="" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <mat-dialog-content style="margin-top: 4em; margin-bottom: 2em">
    <p>
      List URLs here for iTHRIV Research Concierge Portal resources and events that Research Data Commons project owners
      and project collaborators choose to associate with their project. These will typically represent resources the
      project team plans to use and indexing them in the project will help planning.
    </p>

    <form [formGroup]="formGroup">
      <app-form-field
        *ngFor="let field of formContainer.getFields()"
        [errorMatcher]="errorMatcher"
        [errors]="field.formControl ? field.formControl.errors : field.formGroup.errors"
        [field]="field"
        [formGroup]="formGroup"
      ></app-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
    <button
      (click)="onSubmit(true)"
      [disabled]="!checkValidity()"
      color="primary"
      mat-flat-button
      fxFlex="calc(50% - 10px)"
    >
      <mat-icon>save</mat-icon>
      Submit
    </button>
    <button (click)="onSubmit(false)" color="warn" mat-flat-button fxFlex="calc(50% - 10px)">
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
  </mat-dialog-actions>
</div>
