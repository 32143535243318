import {AbstractControl, ValidationErrors} from '@angular/forms';

export const ValidateDelimitedList = (
  control: AbstractControl,
  splitChars: string,
  disallowedChars?: string,
): ValidationErrors => {
  // Split the control value string using the given split character(s).
  const value: string | string[] = control.value === null ? '' : control.value;

  const items = Array.isArray(value)
    ? value
    : value
        .split(splitChars)
        .map(e => e.trim())
        .filter(v => !!v);

  // Make sure the items in the list don't contain the disallowed character(s).
  if (disallowedChars) {
    const escapedDisallowedChars = disallowedChars.replace(/[-[\]{}()*+?.,\\^$|#\s\n\t]/g, '\\$&');
    const pattern = new RegExp(`^([^${escapedDisallowedChars}]+)$`, 'i');
    const forbidden = items.some((keyword: string) => !pattern.test(keyword));

    return forbidden ? {delimitedList: {value: control.value}} : null;
  } else {
    return null;
  }
};

export const ValidateCommaDelimitedList = (control: AbstractControl) => {
  return ValidateDelimitedList(control, ',') ? {commaDelimitedList: {value: control.value}} : null;
};

export const ValidateSemicolonDelimitedList = (control: AbstractControl) => {
  return ValidateDelimitedList(control, ';') ? {semicolonDelimitedList: {value: control.value}} : null;
};

export const cleanUpDelimitedList = (value: string | string[], splitChar: string): string => {
  if (Array.isArray(value)) {
    return value.join(splitChar);
  }

  if ([null, undefined, ''].includes(value)) {
    return '';
  }

  return value
    .split(splitChar)
    .map(e => e.trim())
    .filter(v => !!v)
    .join(splitChar);
};

export const cleanUpCommaDelimitedList = (value: string): string => cleanUpDelimitedList(value, ',');
export const cleanUpSemicolonDelimitedList = (value: string): string => cleanUpDelimitedList(value, ';');
