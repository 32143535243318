import {UntypedFormGroup, Validators} from '@angular/forms';
import {Fieldset} from '@shared/types/fieldset';
import {FormField} from '@shared/types/form-field';
import {ValidatePasswordsMatch} from '@shared/validators/password_match.validator';

export class FormContainer {
  fields: {[key: string]: FormField};
  form: UntypedFormGroup;

  constructor(_fields, _form: UntypedFormGroup) {
    this.fields = _fields;
    this.form = _form;
  }

  getFields() {
    return Object.entries(this.fields).map(([fieldName, field]) => {
      field.name = fieldName;
      return field;
    });
  }

  loadForm() {
    this.getFields();
    Object.entries(this.fields).forEach(([fieldName, field]) => {
      const validators = [
        {name: 'required', validator: Validators.required},
        {name: 'minLength', validator: Validators.minLength(field.minLength)},
        {name: 'maxLength', validator: Validators.maxLength(field.maxLength)},
        {name: 'passwordsMatch', validator: ValidatePasswordsMatch},
        {name: 'type', validator: field.type === 'email' ? Validators.email : undefined},
      ];

      const filteredValidators = validators
        .filter(v => Object.hasOwn(field, v.name) && v.validator && ![null, undefined].includes(field[v.name]))
        .map(v => v.validator);

      field.formControl.setValidators(filteredValidators);
      this.form.addControl(fieldName, field.formControl);
    });
  }

  setObjectToEdit(object: any) {
    Object.entries(this.fields).forEach(([fieldName, field]) => {
      if (object.hasOwnProperty(fieldName)) {
        field.formControl.patchValue(object[fieldName]);
      }
    });
  }

  updateObject(object: any) {
    Object.entries(this.fields).forEach(([fieldName, field]) => {
      if (object.hasOwnProperty(fieldName)) {
        object[fieldName] = field.formControl.value;
      }
    });
  }

  validate() {
    Object.values(this.form.controls).forEach(formControl => {
      formControl.markAsTouched();
      formControl.updateValueAndValidity();
    });

    return this.form.valid;
  }

  reset() {
    for (const fieldName in this.fields) {
      if (fieldName != null) {
        const field = this.fields[fieldName];
        field.formControl.reset();
        field.formControl.markAsUntouched();
      }
    }
  }
}

export const loadFieldsets = (fields: {[key: string]: FormField}): Fieldset[] => {
  const fieldsets: Fieldset[] = [];

  for (const fieldName in fields) {
    if (fields.hasOwnProperty(fieldName)) {
      const field = fields[fieldName];

      // If fieldset id is different from current, create new fieldset
      if (fieldsets.length === 0 || fieldsets[fieldsets.length - 1].id !== field.fieldsetId) {
        fieldsets.push(
          new Fieldset({
            id: field.fieldsetId || Math.random().toString(),
            label: field.fieldsetLabel || null,
            fields: [],
          }),
        );
      }

      // Add the field to the fieldset
      fieldsets[fieldsets.length - 1].fields.push(field);
    }
  }

  return fieldsets;
};
