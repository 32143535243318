<mat-card
  *ngIf="dataset && dataset.id"
  (click)="goDataset()"
  [hidden]="false"
  [ngClass]="{dataset: true, private: !dataset.is_metadata_public}"
>
  <div class="icon-bg" fxLayout="row" fxLayoutAlign="center start"></div>
  <div class="status-icons" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="8px">
    <mat-icon *ngIf="isHSD(dataset)" matTooltip="Sensitive Data">gpp_maybe</mat-icon>
    <app-commons-hipaa-warning
      *ngIf="!this.publicCommons"
      [warnings]="getWarnings()"
      [dataset]="dataset"
      [isScanComplete]="true"
    ></app-commons-hipaa-warning>
    <mat-icon
      [matTooltip]="
        !dataset.is_metadata_public
          ? 'Only dataset admins and collaborators can view the dataset details.'
          : 'Anyone can view dataset details.'
      "
      >{{ !dataset.is_metadata_public ? 'visibility_off' : 'visibility_on' }}</mat-icon
    >
  </div>
  <mat-card-header fxLayout="column" fxLayoutAlign="center center">
    <h2 mat-card-title>
      <mat-icon matTooltip="Research Commons Dataset">insights</mat-icon><br />
      {{ dataset.name }}
    </h2>
  </mat-card-header>
  <mat-card-content fxLayout="column" fxLayoutAlign="center center"> </mat-card-content>
  <div class="dataset-actions" fxLayout="row" fxLayoutAlign="end start">
    <button
      mat-button
      color="accent"
      [routerLink]="[
        '/private_commons',
        'project',
        dataset.associated_projects[0].project.id,
        'dataset',
        dataset.id,
        'edit'
      ]"
      *ngIf="canUpdateMeta()"
    >
      <mat-icon>edit</mat-icon>
      Edit Details
    </button>
    <button type="button" (click)="editDatasetData()" *ngIf="canEditDatasetData()" color="accent" mat-button>
      Edit Data
    </button>
  </div>
  <app-commons-institution-logo [name]="getInstitutionName()"></app-commons-institution-logo>
</mat-card>
