import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-locked-icon',
  templateUrl: './locked-icon.component.html',
  styleUrls: ['./locked-icon.component.scss'],
})
export class LockedIconComponent {
  @Input() tooltip: string;

  constructor() {}
}
