import {ActivatedRoute} from '@angular/router';

export const scrollToSelector = (selector: string) => {
  return new Promise<boolean>(resolve => {
    if (selector) {
      setTimeout(() => {
        const el: HTMLElement = document.querySelector(selector);
        if (el) {
          const top = el.getBoundingClientRect().top + document.documentElement.scrollTop;
          window.scroll({behavior: 'smooth', top: top});
          resolve(true);
        } else {
          console.error(`Can't find element ${selector} to scroll to.`);
          resolve(false);
        }
      }, 500);
    } else {
      resolve(false);
    }
  });
};

export const scrollToRouteParam = (route: ActivatedRoute) => {
  const selector = route.snapshot.queryParamMap.get('scrollTo');
  if (selector) {
    return scrollToSelector(selector);
  }
};
