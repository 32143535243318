export const ResourceAPIEndpoints = {
  availability: '/api/availability/<id>',
  availabilitylist: '/api/availability',
  categorybyresource: '/api/resource/<resource_id>/category',
  category: '/api/category/<id>',
  categorylist: '/api/category',
  dataset: '/api/landing_service/project/<project_id>/dataset/<dataset_id>',
  datasets: '/api/landing_service/datasets',
  dataverse_email: '/api/dataverse_email',
  favorite: '/api/favorite/<id>',
  favoritelist: '/api/favorite',
  file: '/api/file/<file_type>/<id>',
  filelist: '/api/file',
  icon: '/api/icon/<id>',
  iconlist: '/api/icon',
  institutionavailabilitylist: '/api/institution/availability',
  institution: '/api/institution/<id>',
  institutionlist: '/api/institution',
  project: '/api/landing_service/project/<id>',
  projects: '/api/landing_service/projects',
  rebuild_index: '/api/rebuild_index/<index>',
  resourceavailability: '/api/resource/<resource_id>/availability',
  resourcebycategory: '/api/category/<category_id>/resource',
  resourcecategory: '/api/resource_category/<id>',
  resourcecategorylist: '/api/resource_category',
  resource: '/api/resource/<id>',
  resourcelist: '/api/resource',
  rootcategorylist: '/api/category/root',
  search: '/api/search',
  search_commons: '/api/elasticsearch/global',
  search_datasets: '/api/elasticsearch/datasets',
  search_projects: '/api/elasticsearch/projects',
  search_projects_global: '/api/elasticsearch/projects/global',
  session: '/api/session',
  sessionstatus: '/api/session_status',
  task_status: '/api/status/celery/<task_id>',
  syncproject: '/api/sync_es/project/<project_id>',
  syncdataset: '/api/sync_es/dataset/<dataset_id>',
  type: '/api/type/<id>',
  typelist: '/api/type',
  segmentlist: '/api/segment',
  user: '/api/user/<id>',
  userfavorite: '/api/session/favorite',
  userlist: '/api/user',
  userresource: '/api/session/resource',
  commons_eula: '/api/commons_eula',
  approval_request: '/api/approval_request',
  forgot_password: '/api/forgot_password',
  login_password: '/api/login_password',
  reset_password: '/api/reset_password',
  consult_request: '/api/consult_request',
  consult_category_list: '/api/consult_category_list',
  consult_request_list: '/api/consult_request_list',
  root: '/',
  sso_login: '/api/login',
  static: '/static/<filename>',
  logo: '/api/track/<user_id>/<code>/logo.png',
} as const;

export type ResourceAPIEndpointsKey = keyof typeof ResourceAPIEndpoints;
export type ResourceAPIEndpointsURL = (typeof ResourceAPIEndpoints)[ResourceAPIEndpointsKey];
