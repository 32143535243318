import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({
  template: '',
  styleUrls: ['./app-base.component.scss'],
  standalone: true,
})
export class AppBaseComponent implements OnInit, OnDestroy {
  @HostBinding('class.xl') protected xl: boolean;
  @HostBinding('class.lg') protected lg: boolean;
  @HostBinding('class.md') protected md: boolean;
  @HostBinding('class.sm') protected sm: boolean;
  @HostBinding('class.xs') protected xs: boolean;

  private baseSubscriptions = new Subscription();

  constructor(protected readonly breakpointObserver?: BreakpointObserver) {}

  ngOnInit() {
    this.baseSubscriptions.add(
      this.breakpointObserver
        .observe([Breakpoints.XLarge, Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
        .subscribe((state: BreakpointState) => {
          [this.xl, this.lg, this.md, this.sm, this.xs] = Object.values(state.breakpoints);
        }),
    );
  }

  ngOnDestroy() {
    this.baseSubscriptions.unsubscribe();
    this.baseSubscriptions = undefined;
  }
}
