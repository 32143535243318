<div>
  <button
    *ngFor="let option of options"
    [value]="option.value"
    [disabled]="disabled"
    (click)="handleClick($event, option.value)"
    mat-flat-button
    color="{{ selected_option === option.value ? 'primary' : '' }}"
  >
    <mat-icon style="margin-right: 8px">{{ option.icon }}</mat-icon>
    {{ option.label }}
  </button>
</div>
