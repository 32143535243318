<div fxLayout="row" fxLayoutAlign="end start">
  <button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div fxLayout="column" fxLayoutAlign="space-between center">
  <div>
    <app-commons-file-upload
      (uploadComplete)="onFileComplete($event)"
      (close)="close()"
      [target]="data.datasetFileUrl"
      [user]="data.user"
      [fileSizeLimit]="fileSizeLimit"
      color="primary"
      [disableButton]="false"
      text="Select a new file to upload"
    >
    </app-commons-file-upload>
  </div>
  <div>
    <h5>Max file upload size: {{ formatSize(fileSizeLimit, 2) }}</h5>
  </div>
</div>
