<ng-container>
  <mat-accordion>
    <mat-expansion-panel [expanded]="isExpanded" hideToggle="true">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="45px" id="tiles">
        <app-category-tile
          (click)="goCategory(category)"
          *ngFor="let category of categories"
          [category]="category"
          [childCategory]="childCategory"
        ></app-category-tile>
        <div>
          <h2 class="mat-headline">Find Health Research Resources and Support</h2>
          <p class="mat-h3">
            To browse content, select the circle on the left that best fits your current project goals. You can also
            search content or request a consult by using buttons in upper right.
          </p>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
