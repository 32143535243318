import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {getSessionInstitutionId} from '@authentication/login-service';
import {getPrevUrl} from '@utilities/prev-url';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {Institution} from '@shared/types/institution';

@Component({
  selector: 'app-timedout',
  templateUrl: './timedout.component.html',
  styleUrls: ['./timedout.component.scss'],
})
export class TimedoutComponent {
  title: string;
  institution: Institution;
  prevUrl: string;

  constructor(
    private api: ResourceApiService,
    private router: Router,
  ) {
    const institutionId = getSessionInstitutionId();

    if (institutionId !== undefined) {
      this.api.getInstitution(institutionId).subscribe(institution => (this.institution = institution));
    }

    this.prevUrl = getPrevUrl('/home?tabIndex=events');

    localStorage.clear();
    sessionStorage.clear();
  }

  async goHome() {
    localStorage.clear();
    sessionStorage.clear();
    await this.router.navigateByUrl(this.prevUrl);
  }
}
