import {Component, Input} from '@angular/core';
import {DatasetResponse, FileHipaaWarning} from '@services/landing-service';
import {SensitivityLevel} from '@shared/labels/sensitivity-level';

@Component({
  selector: 'app-commons-hipaa-warning',
  templateUrl: './commons-hipaa-warning.component.html',
  styleUrls: ['./commons-hipaa-warning.component.scss'],
})
export class CommonsHipaaWarningComponent {
  @Input() warnings: FileHipaaWarning[] | string[];
  @Input() dataset: DatasetResponse;
  @Input() isScanComplete: boolean;

  /**
   * Returns true if Dataset:
   * - Is DE-ID and file versions HIPAA warnings list is not empty OR
   * - Is LDS and file versions HIPAA warnings list contains anything BESIDES "PossibleZIPData" or "PossibleDateData"
   *
   * Otherwise, returns false.
   */
  get shouldDisplayWarning(): boolean {
    if (this.dataset.sensitivity_level.id === SensitivityLevel.HSD || !this.warnings || this.warnings.length === 0) {
      return false;
    } else {
      const isDEID = this.dataset.sensitivity_level.id === SensitivityLevel.DE_ID;
      const isLDS = this.dataset.sensitivity_level.id === SensitivityLevel.LDS;

      if (isDEID) {
        return true;
      }

      if (isLDS) {
        return this.warnings
          .map((w: FileHipaaWarning | string) => (typeof w === 'string' ? w : w.hipaa_warning))
          .some(w => !['PossibleZIPData', 'PossibleDateData'].includes(w));
      }
    }

    return false;
  }

  hipaaWarningTooltip(warnings: FileHipaaWarning[] | string[]): string {
    const errorsList = warnings
      .map((w: string | FileHipaaWarning) => {
        const wString = typeof w === 'string' ? w : w.hipaa_warning;
        return `  • ${wString.replace(/([A-Z])/g, ' $1').trim()}`;
      })
      .join('\n');

    return `WARNING:

The system detected file content that appears similar to HIPAA identifiers:

${errorsList}

A system admin has been notified and may audit the uploaded file.`;
  }
}
