import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {UserService} from '@services/user-service/user.service';
import {
  Keyword,
  OrganizationResponse,
  ProjectResponse,
  ProjectUrlResponse,
  ProjectUserResponse,
} from '@services/landing-service';
import {User} from '@shared/types/user';
import {CommonsNamedObject, CommonsPerson, CommonsSearchProject, getRoleTooltip} from '@shared/types/commons-types';
import {delay, firstValueFrom, lastValueFrom} from 'rxjs';
import {ResourceApiService} from '@services/resource-api/resource-api.service';

@Component({
  selector: 'app-details-card',
  templateUrl: './details-card.component.html',
  styleUrls: ['./details-card.component.scss'],
})
export class DetailsCardComponent {
  @Input() project: ProjectResponse;
  @Input() projectWithPermissions?: CommonsSearchProject;
  @Input() publicCommons: boolean = true;
  getRoleTooltip = getRoleTooltip;
  displayedUserpermColumns: string[] = ['email', 'role'];
  @Output() reloadEmit = new EventEmitter<any>();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService,
    public cas: CommonsApiService,
    public api: ResourceApiService,
  ) {}

  get user(): User {
    return this.userService.user;
  }

  async togglePrivate(value: string) {
    await lastValueFrom(
      this.cas.patchProject(this.project, {is_metadata_public: value === 'PUBLIC'}).pipe(delay(1000)),
    );
    await firstValueFrom(this.api.syncProject(this.project.id, this.user, this.user.institution.name));
    this.reloadEmit.emit();
  }

  lookupRole(lookupKey: string) {
    return `PROJECT ${lookupKey.toUpperCase()}`;
  }

  parseObject(object: CommonsNamedObject[]) {
    return object.map(obj => obj.name);
  }

  parsePerson(object: CommonsPerson[]) {
    return object.map(obj => obj.email);
  }

  showProjectPermissions() {
    this.router.navigate(['/private_commons', 'project', this.project.id, 'edit'], {
      queryParams: {scrollTo: '#project_permissions'},
    });
  }

  showProjectTeam(): boolean {
    return !this.publicCommons && this.project.id !== '' && this.projectWithPermissions?._can_upload_data;
  }

  showToggle(): boolean {
    return !this.publicCommons && this.projectWithPermissions?._can_manage_permission;
  }

  toStringArray(
    items: string[] | Keyword[] | ProjectUrlResponse[] | OrganizationResponse[] | ProjectUserResponse[],
  ): string[] {
    return items.map((item: string | Keyword | ProjectUrlResponse | OrganizationResponse | ProjectUserResponse) => {
      if (typeof item === 'string') return item;
      if ('name' in item) return item.name;
      if ('url' in item) return item.url;
      if ('user' in item) return item.user.email;
    });
  }

  getPIs(project_users: ProjectUserResponse[]) {
    return project_users.filter(pu => pu.is_project_pi);
  }

  toTypeUp(up: unknown) {
    return up as ProjectUserResponse;
  }

  showEditPermissions() {
    return !this.publicCommons && this.projectWithPermissions._can_manage_permission;
  }
}
