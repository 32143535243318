import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UnparseConfig} from 'papaparse';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {Category} from '@shared/types/category';
import {NavItem} from '@shared/types/nav-item';
import {Resource} from '@shared/types/resource';
import {ResourceQuery} from '@shared/types/resource-query';
import {User} from '@shared/types/user';
import {convertResourceListToCsvData, getCsvFilename, getCsvOptions} from '@utilities/csv-export';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {AppBaseComponent} from '@shared/components/app-base/app-base.component';
import {CommonsApiService} from '@services/commons-api/commons-api.service';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent extends AppBaseComponent implements OnInit {
  @Input() resources: Resource[];
  @Input() resourceQuery: ResourceQuery;
  @Input() sidenavExpanded: boolean;
  @Input() navItems: NavItem[];
  @Input() currentCategory?: Category;
  @Input() showUserActions?: boolean = true;
  @Input() showPastEvents: boolean = false;
  @Input() hideEventsFilter: boolean = false;
  @Output() toggleShowPastEvents = new EventEmitter<boolean>(false);
  csvDataReady = false;
  preparingCsvData = false;
  csvData = [];
  redactFields = ['_links', 'owner', 'contact_email', 'contact_phone', 'type_id', 'institution_id'];

  constructor(
    private api: ResourceApiService,
    public breakpointObserver: BreakpointObserver,
    private userService: UserService,
  ) {
    super(breakpointObserver);
  }

  get csvFilename(): string {
    return getCsvFilename('resources');
  }

  get csvOptions(): UnparseConfig {
    return getCsvOptions(this.resources, this.redactFields);
  }

  get user(): User {
    return this.userService.getUser();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  includePastEvents(event: MatCheckboxChange): void {
    this.toggleShowPastEvents.emit(event.checked);
  }

  async getCsvData() {
    this.preparingCsvData = true;

    this.csvData = await convertResourceListToCsvData({
      resourceApiService: this.api,
      resourceQuery: this.resourceQuery,
      items: this.resources,
      redactFields: this.redactFields,
    });

    this.csvDataReady = true;
    this.preparingCsvData = false;
  }

  protected readonly event = event;
}
