import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CategoryColorBorderDirective} from '@shared/directives/category-color/category-color-border.directive';
import {GradientBackgroundDirective} from '@shared/directives/gradient-background/gradient-background.directive';
import {GradientBorderDirective} from '@shared/directives/gradient-border/gradient-border.directive';

@NgModule({
  declarations: [CategoryColorBorderDirective, GradientBackgroundDirective, GradientBorderDirective],
  imports: [CommonModule],
  exports: [CategoryColorBorderDirective, GradientBackgroundDirective, GradientBorderDirective],
})
export class DirectivesModule {}
