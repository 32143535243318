import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonsDatasetCreateEditComponent} from '@commons/commons-dataset-create-edit/commons-dataset-create-edit.component';
import {CommonsHomeComponent} from '@commons/commons-home/commons-home.component';
import {CommonsRoutingModule} from '@commons/commons-routing.module';
import {CommonsProjectCreateEditComponent} from '@private-commons/commons-project-create-edit/commons-project-create-edit.component';
import {PrivateDatasetComponent} from '@private-commons/private-dataset/private-dataset.component';
import {PrivateProjectComponent} from '@private-commons/private-project/private-project.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', component: CommonsHomeComponent},
  {
    path: 'project/:project_id',
    pathMatch: 'full',
    component: PrivateProjectComponent,
  },
  {
    path: 'new-project',
    pathMatch: 'full',
    component: CommonsProjectCreateEditComponent,
  },
  {
    path: 'project/:project_id/edit',
    pathMatch: 'full',
    component: CommonsProjectCreateEditComponent,
  },
  {
    path: 'project/:project_id/dataset/:dataset_id',
    pathMatch: 'full',
    component: PrivateDatasetComponent,
  },
  {
    path: 'project/:project_id/new-dataset',
    pathMatch: 'full',
    component: CommonsDatasetCreateEditComponent,
  },
  {
    path: 'project/:project_id/dataset/:dataset_id/edit',
    pathMatch: 'full',
    component: CommonsDatasetCreateEditComponent,
  },
  {
    path: 'project/:project_id/dataset/:dataset_id/spreadsheet',
    pathMatch: 'full',
    loadChildren: () =>
      import('@commons/commons-dataset-spreadsheet/commons-dataset-spreadsheet.module').then(
        m => m.CommonsDatasetSpreadsheetModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonsRoutingModule],
  exports: [RouterModule],
})
export class PrivateCommonsRoutingModule {}
