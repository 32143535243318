import {AbstractControl, ValidationErrors} from '@angular/forms';
import {SERVICE_HOST} from '@src/environments/environment';

const getInvalidIndices = (control: AbstractControl, pattern: RegExp): number[] => {
  const isValidUrl = (url): boolean => !!url && url !== '' && pattern.test(url);

  if (control.value !== null) {
    let values = [];

    // Handle both strings and lists of strings.
    if (Array.isArray(control.value)) {
      values = control.value;
    } else if (typeof control.value === 'string') {
      values = control.value.split(',');
    }

    // Build list of invalid items.
    const invalidIndices = [];
    values.forEach((v, i) => {
      if (!isValidUrl(v.trim())) {
        invalidIndices.push(i);
      }
    });

    return invalidIndices;
  }

  return [];
};

/**
 * Returns null if the give control's value contains a valid URL or a list of valid URLs.
 * Otherwise, returns a list of indices of the invalid URLs.
 * @param control
 * @constructor
 */
export const ValidateUrl = (control: AbstractControl): ValidationErrors => {
  const urlRegEx = new RegExp(
    '^' +
      '(?:(?:https?|ftp)://)' +
      '(?:\\S+(?::\\S*)?@)?' +
      '(?:' +
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
      '|' +
      '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
      '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
      '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
      '\\.?' +
      ')' +
      '(?::\\d{2,5})?' +
      '(?:[/?#]\\S*)?' +
      '$',
    'i',
  );

  const invalidIndices = getInvalidIndices(control, urlRegEx);

  if (invalidIndices.length > 0) {
    return {url: {value: control.value, indices: invalidIndices}};
  }

  return null;
};

export const ValidatePortalResourceUrls = (control: AbstractControl): ValidationErrors => {
  const portalUrlPattern = new RegExp(`^https?:\\/\\/${SERVICE_HOST}:?[0-9]*\\/#\\/resource\\/[0-9]+$`);
  const invalidIndices = getInvalidIndices(control, portalUrlPattern);

  if (invalidIndices.length > 0) {
    return {url: {value: control.value, indices: invalidIndices}};
  }

  return null;
};
