<ng-container *ngIf="projects && projects.length > 0">
  <div
    class="resource-list"
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    fxLayoutAlign.xs="start start"
    fxLayoutGap="20px grid"
  >
    <div *ngIf="user && user.role === 'Admin'" fxFlex="100%" fxFlexFill fxLayout="row" fxLayoutAlign="end end">
      <button mat-raised-button (click)="getSystemUsageReport('projects')">
        <mat-icon>save_alt</mat-icon>
        Download this list as a CSV file
      </button>
    </div>
    <app-commons-project-tile
      *ngFor="let project of projects"
      class="resource-tile-container"
      fxFlex.xl="1 1 20%"
      fxFlex.lg="1 1 25%"
      fxFlex.md="1 1 33%"
      fxFlex.sm="1 1 50%"
      fxFlex.xs="1 1 100%"
      fxFlexFill
      [project]="project"
      [publicCommons]="this.publicCommons"
      id="project-tile-{{ project.id }}"
    ></app-commons-project-tile>
  </div>
</ng-container>
