import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ButtonsModule} from '@buttons/buttons.module';
import {MaterialModule} from '@material/material.module';
import {BreadcrumbsComponent} from '@navigation/breadcrumbs/breadcrumbs.component';
import {BrowseComponent} from '@navigation/browse/browse.component';
import {GraphComponent} from '@navigation/network/graph/graph.component';
import {NodeComponent} from '@navigation/network/node/node.component';
import {NotFoundComponent} from '@navigation/not-found/not-found.component';
import {UpgradeBrowserComponent} from '@navigation/upgrade-browser/upgrade-browser.component';
import {BreadcrumbsHistoryService} from '@services/breadcrumbs-history/breadcrumbs-history.service';
import {DirectivesModule} from '@shared/directives/directives.module';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {MarkdownModule} from 'ngx-markdown';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    BrowseComponent,
    GraphComponent,
    NodeComponent,
    NotFoundComponent,
    UpgradeBrowserComponent,
  ],
  imports: [
    ButtonsModule,
    CommonModule,
    DirectivesModule,
    InlineSVGModule,
    MarkdownModule.forRoot({loader: HttpClient}),
    MaterialModule,
    RouterModule,
  ],
  exports: [
    BreadcrumbsComponent,
    BrowseComponent,
    GraphComponent,
    NodeComponent,
    NotFoundComponent,
    UpgradeBrowserComponent,
  ],
  providers: [BreadcrumbsHistoryService],
})
export class NavigationModule {}
