import {Component, Input} from '@angular/core';
import {Institution} from '@shared/types/institution';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {Resource} from '@shared/types/resource';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-favorite-resource-list',
  templateUrl: './favorite-resource-list.component.html',
  styleUrls: ['./favorite-resource-list.component.scss'],
})
export class FavoriteResourceListComponent {
  resources: Resource[];
  @Input() institution: Institution;

  constructor(
    private api: ResourceApiService,
    private userService: UserService,
  ) {
    this.getFavoriteResources();
  }

  get user(): User {
    return this.userService.getUser();
  }

  getFavoriteResources() {
    this.api.getUserFavorites().subscribe(favs => {
      this.resources = favs.map(f => f.resource);
    });
  }
}
