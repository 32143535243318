import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CommonsSearchProject} from '@shared/types/commons-types';
import {AddRelatedResourceDialogComponent} from '@commons/add-related-resource-dialog/add-related-resource-dialog.component';
import {PatchService} from '@services/patch/patch.service';
import {UserService} from '@services/user-service/user.service';
import {Resource} from '@shared/types/resource';
import {User} from '@shared/types/user';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {ProjectPatch, ProjectResponse} from '@services/landing-service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {CommonsApiService} from '@services/commons-api/commons-api.service';

@Component({
  selector: 'app-resources-card',
  templateUrl: './resources-card.component.html',
  styleUrls: ['./resources-card.component.scss'],
})
export class ResourcesCardComponent implements OnInit {
  @Input() projectResources: Resource[];
  @Input() project: ProjectResponse;
  @Input() projectWithPermissions?: CommonsSearchProject;
  @Input() publicCommons: boolean = true;
  @Output() reloadEmit = new EventEmitter<any>();
  shownResources: Resource[];
  showPastEvents: boolean = false;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    private patch: PatchService,
    private ras: ResourceApiService,
    private cas: CommonsApiService,
  ) {}

  ngOnInit() {
    this.shownResources = this.projectResources;
  }

  get user(): User {
    return this.userService.getUser();
  }

  async openResourceDialog() {
    const dialogRef = this.dialog.open(AddRelatedResourceDialogComponent, {data: this.project});
    const updatedProject: CommonsSearchProject = await lastValueFrom(dialogRef.afterClosed());
    if (updatedProject) {
      let projectPatch: ProjectPatch = {
        portal_resources_urls: updatedProject.portal_resources_urls as unknown as string[],
      };
      await lastValueFrom(this.cas.patchProject(this.project, projectPatch));
      await firstValueFrom(this.ras.syncProject(this.project.id, this.user, this.project.publisher.name));
      this.reloadEmit.emit();
    }
  }

  showEditButton() {
    return !this.publicCommons && this.projectWithPermissions?._can_update_meta;
  }

  async handleShowPastEvents() {
    this.showPastEvents = !this.showPastEvents;
    this.shownResources = this.showPastEvents ? this.projectResources : this.filterPastResources(this.projectResources);
  }

  filterPastResources(resources: Resource[]) {
    return resources.filter(
      r => !r.ends || (r.ends && !this.showPastEvents && new Date(r.ends).getTime() > Date.now()),
    );
  }
}
