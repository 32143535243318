import {Component, Inject} from '@angular/core';
import {MatSnackBarRef, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {ParsedError} from '@form-controls/error-message/error-message.component';

interface ErrorSnackbarData {
  errorString?: string;
  parsedError?: ParsedError;
  action: string;
}

@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss'],
})
export class ErrorSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<ErrorSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ErrorSnackbarData,
  ) {}

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
