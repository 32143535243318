<ng-container *ngIf="size === 'sm'">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" style="margin-top: 4em; margin-bottom: 4em">
    <div>
      <mat-spinner diameter="32"></mat-spinner>
    </div>
    <div>{{ message || 'Loading...' }}</div>
  </div>
</ng-container>
<ng-container *ngIf="size !== 'sm'">
  <div id="spinner-container" class="mat-typography" fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
    <h1>{{ message }}</h1>
  </div>
</ng-container>
