<div class="breadcrumbs" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" style="padding-left: 0px; padding-top: 20px">
    <a (click)="navigateHome()">HOME</a>
    <ng-container *ngFor="let item of navItems; let i = index">
      <mat-icon *ngIf="i < navItems.length">chevron_right</mat-icon>
      <a (click)="handleClick(item)">{{ item.title }}</a>
    </ng-container>
  </div>
  <ng-content select="section.left"></ng-content>
  <ng-content select="section.right"></ng-content>
</div>
<ng-content></ng-content>
