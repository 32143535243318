<div *ngIf="user">
  <button *ngIf="resourceButton" mat-raised-button color="primary" (click)="addResource('resource')">
    <mat-icon>add</mat-icon>
    Add Resource
  </button>
  <div class="divider"></div>
  <button *ngIf="eventButton" mat-raised-button color="primary" (click)="addResource('event')">
    <mat-icon>add</mat-icon>
    Add Event
  </button>
</div>
