<app-commons-menu
  title=""
  [navItems]="[
    {
      title: this.publicCommons ? 'Public Data Commons' : 'Private Data Commons',
      routerLink: '/home',
      queryParams: {tabIndex: this.publicCommons ? 'public_commons' : 'private_commons'}
    }
  ]"
></app-commons-menu>
<app-commons-search
  (searchEmit)="getSearchResults($event)"
  [userOnVpn]="userIsOnVPN"
  [showProjectCreateButton]="showProjectCreateButton"
>
</app-commons-search>

<ng-container *ngIf="userHasAcceptedEula; else eulaNotAcceptedMessage">
  <ng-container *ngIf="publicCommons">
    <ng-container *ngIf="isLoaded; else loadingMessage">
      <app-commons-project-list
        *ngIf="searchMode === 'projects'"
        listType="public"
        [projects]="projects"
        [publicCommons]="this.publicCommons"
      ></app-commons-project-list>
      <app-commons-dataset-list
        *ngIf="searchMode === 'datasets'"
        [datasets]="datasets"
        [publicCommons]="publicCommons"
      ></app-commons-dataset-list>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!publicCommons">
    <ng-container *ngIf="!shouldShowCommons">
      <h2>You have no commons projects to view.</h2>
    </ng-container>
    <ng-container *ngIf="shouldShowCommons">
      <app-loading *ngIf="userIsOnVPN === undefined" message="Checking VPN status..." size="sm"></app-loading>
      <ng-container *ngIf="userIsOnVPN === false">
        <app-vpn-warning></app-vpn-warning>
      </ng-container>
      <ng-container *ngIf="userIsOnVPN === true">
        <ng-container *ngIf="isLoaded; else loadingMessage">
          <ng-container *ngIf="searchMode === 'projects' && projects.length > 0">
            <app-commons-project-list
              *ngIf="searchMode === 'projects'"
              listType="private"
              [projects]="projects"
              [publicCommons]="this.publicCommons"
            ></app-commons-project-list>
          </ng-container>
          <ng-container *ngIf="showDatasetList()">
            <app-commons-dataset-list
              *ngIf="searchMode === 'datasets'"
              [datasets]="datasets"
              [publicCommons]="this.publicCommons"
              [showCsvDownload]="true"
            ></app-commons-dataset-list>
          </ng-container>
          <ng-container *ngIf="searchMode === 'projects' && projects.length === 0 && searchString === undefined">
            <div class="alert info">
              <h4>The iTHRIV Research Data Commons is...</h4>
              <ul>
                <li>
                  ...a scalable research data infrastructure that enables the safe, secure collection, storage, sharing
                  and analysis of health research data
                </li>
                <li>...searchable project and dataset metadata and a managed lifecycle for data</li>
                <li>...designed for “self-supported” use by skilled researchers</li>
                <li>...a foundation for multi-institution data integration</li>
              </ul>

              <p>
                You do not yet have a project in the Private iTHRIV Commons. Please contact a colleague to ask that you
                be added to their existing project OR request a new Commons project by emailing: (<a
                  href="mailto:iTHRIVAdmin@uvahealth.org"
                  >iTHRIVAdmin&#64;uvahealth.org</a
                >) Your request will be directed to the appropriate Commons Administrator.
              </p>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <div class="no-results" *ngIf="hasNoResults()">
    No {{ searchMode }} found matching <span class="search-string">"{{ searchString }}"</span>.
  </div>
</ng-container>

<ng-template #eulaNotAcceptedMessage>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="eula-not-accepted alert warning" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
      <p>You may only view the Research Data Commons if you accept the terms &amp; conditions.</p>
      <button (click)="displayCommonsEulaDialog()" color="accent" mat-flat-button>View Terms &amp; Conditions</button>
    </div>
  </div>
</ng-template>

<ng-template #loadingMessage>
  <app-loading message="Loading Research Data Commons projects..." size="sm"></app-loading>
</ng-template>
