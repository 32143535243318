import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RichTextEditorModule} from '@syncfusion/ej2-angular-richtexteditor';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {FormFieldLabelComponent} from '@form-controls/form-field-label/form-field-label.component';
import {RichTextFieldComponent} from '@form-controls/rich-text-field/rich-text-field.component';
import {MaterialModule} from '@material/material.module';
import {MarkdownModule} from 'ngx-markdown';
import {ErrorMessageComponent} from '@form-controls/error-message/error-message.component';

@NgModule({
  declarations: [RichTextFieldComponent],
  imports: [
    CommonModule,
    RichTextEditorModule,
    MaterialModule,
    MarkdownModule,
    ErrorMessageComponent,
    FormFieldLabelComponent,
  ],
  exports: [RichTextFieldComponent, FormFieldLabelComponent, ErrorMessageComponent],
  providers: [ResourceApiService],
})
export class RichTextFieldModule {}
