import {EventEmitter, Injectable, Output} from '@angular/core';
import {parse, stringify} from 'telejson';
import {NavItem} from '@shared/types/nav-item';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsHistoryService {
  navItems: NavItem[];
  @Output() updated = new EventEmitter<NavItem[]>();

  constructor() {
    this.navItems = this.get();
  }

  get() {
    const cachedJson = localStorage.getItem('breadcrumbsHistory');
    return cachedJson && cachedJson !== 'undefined' ? parse(cachedJson) : [];
  }

  push(navItem: NavItem) {
    const breadcrumbsHistory = this.get();
    const lastItemStr = stringify(breadcrumbsHistory[breadcrumbsHistory.length - 1]);
    const newItemStr = stringify(navItem);

    // Prevent duplicate items from being added.
    if (lastItemStr !== newItemStr) {
      breadcrumbsHistory.push(navItem);
    }

    this.save(breadcrumbsHistory);
  }

  pop(): NavItem {
    const breadcrumbsHistory = this.get();
    const lastItem = breadcrumbsHistory.pop();
    this.save(breadcrumbsHistory);
    return lastItem;
  }

  save(newNavItems: NavItem[]) {
    localStorage.setItem('breadcrumbsHistory', stringify(newNavItems));
    this.navItems = newNavItems;
    this.updated.emit(this.navItems);
  }
}
