import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Category} from '@shared/types/category';
import {Resource} from '@shared/types/resource';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-edit-resource-button',
  templateUrl: './edit-resource-button.component.html',
  styleUrls: ['./edit-resource-button.component.scss'],
})
export class EditResourceButtonComponent {
  @Input() resource: Resource;
  @Input() category: Category;
  @Input() user: User;

  constructor(private router: Router) {}

  openEdit() {
    this.router.navigate(['/' + this.resource.segment.name.toLowerCase(), this.resource.id, 'edit']);
  }

  userMayEdit() {
    return this.resource.user_may_edit;
  }
}
