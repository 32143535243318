import {Injectable} from '@angular/core';
import {Observable, Subscription, interval} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntervalService {
  private subscription: Subscription;
  private observable: Observable<number>;

  constructor() {}

  setInterval(callback: () => void, time: number): Subscription {
    // Clear any existing interval.
    this.clearInterval();

    // Create a new interval and subscribe to it.
    this.observable = interval(time);
    return (this.subscription = this.observable.subscribe(() => callback()));
  }

  clearInterval() {
    if (this.subscription && this.observable) {
      this.subscription.unsubscribe();
      delete this.subscription;
      delete this.observable;
    }
  }
}
