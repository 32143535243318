<div [matTooltip]="getTooltipText(category)">
  <div
    class="category-tile category-tile-root"
    (mouseover)="category.hover = true"
    (mouseout)="category.hover = false"
    [ngStyle]="{
      'box-shadow': shouldHighlight ? 'none' : '0px 0px 20px 5px white',
      'border-color': category.color,
      'background-image': categoryImageURL()
    }"
  ></div>
  <div
    class="mat-typography category-tile-root-title"
    (mouseover)="category.hover = true"
    (mouseout)="category.hover = false"
    [ngStyle]="{
      'background-color': shouldHighlight ? category.color : categoryColorLight(),
      'border-color': shouldHighlight ? category.color : categoryColorLight()
    }"
  >
    <a
      [routerLink]="categoryRoute(category)"
      [appGradientBorder]="category.color"
      [ngStyle]="{color: shouldHighlight ? 'white' : 'black'}"
      >{{ category.name }}</a
    >
  </div>
</div>
