import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '@material/material.module';
import {ErrorMessageComponent} from '@form-controls/error-message/error-message.component';
import {ErrorSnackbarComponent} from '@form-controls/error-snackbar/error-snackbar.component';
import {PublicPrivateToggleComponent} from '@form-controls/public-private-toggle/public-private-toggle.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxFileDropModule} from 'ngx-file-drop';
import {MarkdownModule} from 'ngx-markdown';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {ChipListComponent} from './chip-list/chip-list.component';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {FormFieldLabelComponent} from './form-field-label/form-field-label.component';
import {FormFieldComponent} from './form-field/form-field.component';
import {ImageSelectComponent} from './image-select/image-select.component';
import {TreeSelectModule} from './tree-select/tree-select.module';
import {ColorPickerModule} from 'ngx-color-picker';

@NgModule({
  declarations: [
    PublicPrivateToggleComponent,
    ChipListComponent,
    ErrorSnackbarComponent,
    FileUploadComponent,
    FormFieldComponent,
    ImageSelectComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MarkdownModule,
    MaterialModule,
    NgxDaterangepickerMd.forRoot(),
    NgxFileDropModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    FormFieldLabelComponent,
    ErrorMessageComponent,
    ColorPickerModule,
    TreeSelectModule,
  ],
  exports: [
    PublicPrivateToggleComponent,
    ChipListComponent,
    ErrorMessageComponent,
    ErrorSnackbarComponent,
    FileUploadComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    ImageSelectComponent,
  ],
  providers: [ResourceApiService],
})
export class FormControlsModule {}
