<mat-card [ngClass]="'view'" [id]="'related_resources'">
  <mat-card-header>
    <mat-card-title> Related Resources</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container>
      <button mat-flat-button color="primary" *ngIf="showEditButton()" (click)="openResourceDialog()">
        <mat-icon>edit</mat-icon>
        EDIT RELATED RESOURCES
      </button>
    </ng-container>
    <app-resource-list
      [resources]="shownResources || this.filterPastResources(projectResources)"
      [showUserActions]="false"
      [sidenavExpanded]="true"
      [hideEventsFilter]="false"
      (toggleShowPastEvents)="handleShowPastEvents()"
      fxFlexFill
    ></app-resource-list>
  </mat-card-content>
</mat-card>
