<div *ngIf="user && resources">
  <app-resource-list
    [resources]="resources"
    [sidenavExpanded]="false"
    fxFlexFill
    [navItems]="[{title: 'Favorite Resources and Events', routerLink: '/home', queryParams: {tabIndex: 'favorites'}}]"
    [showPastEvents]="true"
    [hideEventsFilter]="true"
  ></app-resource-list>
</div>
<div *ngIf="!user && !institution" class="please-log-in">
  <h2>Please log in to view your favorites.</h2>
  <app-login-services></app-login-services>
</div>
<div *ngIf="!user && institution" class="please-log-in">
  <h2>{{ institution.name }} View</h2>
  <h2>You must <a href="#/login">log in</a> to view your favorites.</h2>
</div>
