/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DatasetRoleID {
    ADMIN = 'admin',
    COLLABORATOR = 'collaborator',
    CUSTOMER = 'customer',
}
