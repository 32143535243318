<mat-form-field class="chip-list" (click)="handleClick()" [formGroup]="field.formGroup">
  <mat-chip-list #chipList [attr.aria-label]="field.placeholder">
    <mat-chip
      (removed)="remove(value)"
      *ngFor="let value of values; index as i"
      [removable]="removable"
      [selectable]="selectable"
      [color]="isInvalid(i) ? 'warn' : undefined"
      [ngClass]="isInvalid(i) ? 'invalid' : ''"
    >
      {{ value }}
      <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      #chipInput
      (matChipInputTokenEnd)="add($event)"
      [matChipInputAddOnBlur]="addOnBlur"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (blur)="handleBlur()"
      (keyup)="validate()"
      (paste)="handlePaste($event)"
      placeholder="Click here to type a new item and press Enter."
    />
  </mat-chip-list>
  <ng-content></ng-content>
</mat-form-field>
