import {Validators} from '@angular/forms';
import {DatasetType} from '@services/landing-service';
import {FormField} from '@shared/types/form-field';
import {ValidateDataAggregation} from '@shared/validators/aggregation.validator';
import {ValidateLandingServiceInstitutionEmails} from '@shared/validators/landing_service_email.validator';
import {ValidateDatasetType} from '@shared/validators/dataset_type.validator';
import {ValidateDateTimeRange} from '@shared/validators/date_time_range.validator';
import {ValidateHsd} from '@shared/validators/hsd.validator';
import {ValidateIdentifierHipaa} from '@shared/validators/identifiers_hipaa.validator';
import {ValidateOtherSensitiveData} from '@shared/validators/other_sensitive_data.validator';
import {ValidateUrl} from '@shared/validators/url.validator';
import {ValidateRequiredTreeSelect} from '@shared/validators/tree-select.validator';
import {ValidateRequiredIfREDCapExtractData} from '@shared/validators/redcap.validator';

export enum FormType {
  RESOURCE = 'resource',
  PROJECT = 'project',
  DATASET = 'dataset',
}
export const getFieldValidators = (field: FormField, formType: FormType, datasetType?: DatasetType) => {
  const isResource = formType === FormType.RESOURCE;
  const validators = [];

  // Resource/Dataset/Project common form field validators
  if (field.required) {
    validators.push(Validators.required);
  }

  if (field.minLength) {
    validators.push(Validators.minLength(field.minLength));
  }

  if (field.maxLength) {
    validators.push(Validators.maxLength(field.maxLength));
  }

  if (field.type === 'daterange') {
    validators.push(ValidateDateTimeRange);
  }

  if (field.type === 'email' || field.name === 'users') {
    // If Project or Dataset
    if (!isResource) validators.push(ValidateLandingServiceInstitutionEmails);

    // If Resource
    if (isResource) validators.push(Validators.email);
  }

  if (field.type === 'url' || field.name === 'web_page_url') {
    validators.push(ValidateUrl);
  }

  if (field.required && field.type === 'tree') {
    validators.push(ValidateRequiredTreeSelect);
  }

  // Dataset-only field validators
  if (formType === FormType.DATASET) {
    if (field.name === 'dataset_type') {
      validators.push(ValidateDatasetType);
    }

    if (field.name === 'other_sensitive_data') {
      validators.push(ValidateOtherSensitiveData);
    }
    if (field.name === 'identifiers_hipaa') {
      validators.push(ValidateIdentifierHipaa);
    }

    if (['study_irb_number', 'dsp_id'].includes(field.name)) {
      validators.push(ValidateHsd);
    }

    if (field.name === 'data_aggregation_id') {
      validators.push(ValidateDataAggregation);
    }

    if (datasetType === DatasetType.REDCAP) {
      if (field.name === 'redcap_project_url') {
        validators.push(Validators.required);
        validators.push(ValidateUrl);
      }
      if (field.name === 'redcap_report_id') {
        validators.push(ValidateRequiredIfREDCapExtractData);
      }
    }
    if (datasetType === DatasetType.DICOM) {
      if (field.name === 'quality') {
        validators.push(Validators.required);
      }
    }
  }

  return validators;
};
