import {AbstractControl, ValidationErrors} from '@angular/forms';
import {checkForNoneAndCUI} from '@shared/validators/hsd.validator';

/**
 * Returns a validation error if:
 * - The CUI option is selected in the given control.
 * - The NONE or N/A option is selected along with another value.
 * - Otherwise, returns null.
 * @param control
 */
export const ValidateIdentifierHipaa = (control: AbstractControl): null | ValidationErrors => {
  return checkForNoneAndCUI(control);
};
