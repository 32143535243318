<div fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    <ng-container *ngIf="showProjectCreateButton">
      <button mat-raised-button color="primary" style="height: 3em" [routerLink]="['/private_commons', 'new-project']">
        <mat-icon>add</mat-icon>
        CREATE PROJECT
      </button>
    </ng-container>
  </div>
  <div fxLayoutGap="2em">
    <span class="secondary-nav">
      Find:
      <mat-button-toggle-group
        #toggleGroup="matButtonToggleGroup"
        [value]="searchMode"
        (change)="toggleSearchMode($event)"
        aria-label="Select a toggle"
      >
        <mat-button-toggle value="projects" aria-label="Find Projects"
          ><mat-icon>science</mat-icon> Projects</mat-button-toggle
        >
        <mat-button-toggle value="datasets" aria-label="Find Datasets"
          ><mat-icon>insights</mat-icon> Datasets</mat-button-toggle
        >
      </mat-button-toggle-group>
    </span>

    <mat-form-field>
      <input
        type="search"
        matInput
        placeholder="Search {{ searchMode === 'projects' ? 'Projects' : 'Datasets' }}..."
        (keyup)="updateKeywordSearch($event)"
        [(ngModel)]="searchString"
      />
      <button mat-icon-button matSuffix (click)="clearSearchString()">
        <mat-icon>{{ searchString ? 'close' : '' }}</mat-icon>
      </button>
      <button mat-icon-button matSuffix>
        <mat-icon (click)="submitKeywordSearch()">search</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
