import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {VPNDisconnectDialogComponent} from '@commons/vpndisconnect-dialog/vpndisconnect-dialog.component';
import {SearchEmit} from '@shared/types/search-emit';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {UserService} from '@services/user-service/user.service';
import {VPNCheckService} from '@services/vpnCheckService/vpn-check.service';
import {User} from '@shared/types/user';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-commons-search',
  templateUrl: './commons-search.component.html',
  styleUrls: ['./commons-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonsSearchComponent implements OnDestroy {
  @Output() searchEmit = new EventEmitter<SearchEmit>();
  @Input() userOnVpn: boolean;
  @Input() showProjectCreateButton: boolean;
  subs: Subscription[] = [];
  searchString: string = '';
  searchMode: 'projects' | 'datasets' = 'projects';
  vpnDialogRef: MatDialogRef<VPNDisconnectDialogComponent>;

  constructor(
    public cas: CommonsApiService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private vpnCheckService: VPNCheckService,
    private dialog: MatDialog,
  ) {
    const vpnCheckSub = this.vpnCheckService.updated.subscribe(async isConnected => {
      if (!isConnected && this.isPrivateCommons) {
        if (!this.vpnDialogRef) {
          this.vpnDialogRef = this.dialog.open(VPNDisconnectDialogComponent, {
            hasBackdrop: true,
            width: '400px',
            height: '300px',
          });
        }
      } else {
        this.vpnDialogRef && this.vpnDialogRef.close();
      }
    });

    this.subs.push(vpnCheckSub);
  }

  get userIsOnVPN(): boolean {
    return this.vpnCheckService.userIsOnVPN;
  }

  get isCommonsHome(): boolean {
    const url = this.route.snapshot.url;
    return url && url.length === 1 && url[0].path === 'home';
  }

  get isPrivateCommons(): boolean {
    const queryParamMap = this.route.snapshot.queryParamMap;
    return queryParamMap.get('tabIndex') === 'private_commons';
  }

  get user(): User {
    return this.userService.getUser();
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];
    this.vpnCheckService.clearInterval();
  }

  submitKeywordSearch() {
    this.searchEmit.emit({searchString: this.searchString, searchMode: this.searchMode});
  }

  updateKeywordSearch($event: KeyboardEvent) {
    if ($event.key === 'Enter' && this.isCommonsHome) {
      this.submitKeywordSearch();
    }
  }

  toggleSearchMode($event: MatButtonToggleChange) {
    this.searchMode = $event.value;
    this.submitKeywordSearch();
  }

  clearSearchString() {
    this.searchString = '';
    this.submitKeywordSearch();
  }
}
