/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ServiceStatusResponse } from '../models/ServiceStatusResponse';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class StatusService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Check Connection To Landing Service
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public checkConnectionToLandingService(): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/status',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Check File Size Limit
     * Returns the value stored in MAX_CONTENT_LENGTH. Returns None of there is no limit set.
     * @returns any Successful Response
     * @throws ApiError
     */
    public checkFileSizeLimit(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/status/file-size-limit',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Service Status
     * Gets status of 2 services - MinIO and Postgres
     * @returns ServiceStatusResponse Successful Response
     * @throws ApiError
     */
    public getServiceStatus(): Observable<ServiceStatusResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/status/services',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Irb Status
     * Returns true or false depending on if there is a connection to an online IRB service
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public getIrbStatus(): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/status/irb',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

}
