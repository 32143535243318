/**
 *  Intercepts all calls to the backend and adds the auth token to the request header
 */

import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  skipURL = /^https?:\/\/s3\.amazonaws\.com.*|^https?:\/\/www\.google-analytics\.com.*/;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = localStorage.getItem('token');

    // Remove authorization headers when the EPPN is missing.
    if (req.headers.has('eppn') && req.headers.get('eppn') == 'no_eppn') {
      req = req.clone({
        setHeaders: {},
      });
      return next.handle(req);
    }

    // Don't add authorization headers when making AWS S3 or Google Analytics requests.
    if (!this.skipURL.test(req.url) && token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    // send cloned request with header to the next handler.
    return next.handle(req);
  }
}
