<ng-container *ngIf="!user">
  <h1 class="mat-h1 page-title">Public View</h1>
</ng-container>
<mat-tab-group
  mat-stretch-tabs
  (selectedTabChange)="updateTab($event)"
  [selectedIndex]="tabIndex"
  backgroundColor="primary"
  dynamicHeight
>
  <mat-tab label="Private Data Commons" *ngIf="showTab('Private Data Commons')">
    <ng-template matTabContent>
      <app-commons-home [publicCommons]="false" #commonsHome color="primary"></app-commons-home>
    </ng-template>
  </mat-tab>
  <mat-tab label="Public Data Commons">
    <ng-template matTabContent>
      <app-commons-home [publicCommons]="true" color="primary"></app-commons-home>
    </ng-template>
  </mat-tab>
  <mat-tab label="Events">
    <ng-template matTabContent>
      <app-resource-list
        [resources]="resources"
        [sidenavExpanded]="false"
        [showPastEvents]="showPastEvents"
        [hideEventsFilter]="true"
      ></app-resource-list>
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <button class="view-all" type="button" mat-raised-button *ngIf="true" color="primary" (click)="seeAllEvents()">
          View all events
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="My Favorites" *ngIf="showTab('My Favorites')">
    <ng-template matTabContent>
      <app-favorite-resource-list [institution]="institution"></app-favorite-resource-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="My Resources and Events" *ngIf="showTab('My Resources and Events')">
    <ng-template matTabContent>
      <app-user-resource-list [institution]="institution"></app-user-resource-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="Recently Updated" *ngIf="showTab('Recently Updated')">
    <ng-template matTabContent>
      <app-resource-list
        [navItems]="[
          {
            title: 'Recently Updated Resources and Events',
            routerLink: '/home',
            queryParams: {tabIndex: 'Recently Updated'}
          }
        ]"
        [resources]="resources"
        [sidenavExpanded]="false"
        [showPastEvents]="true"
        [hideEventsFilter]="true"
      ></app-resource-list>
    </ng-template>
  </mat-tab>
</mat-tab-group>
