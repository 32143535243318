import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncateWords',
})
export class TruncateWordsPipe implements PipeTransform {
  transform(value: string, numWords = 20, numCharsPerLine = 36, numLines = 6): string {
    return this.truncate(value, value, numWords, numCharsPerLine, numLines);
  }

  truncate(origStr: string, partialStr: string, numWords = 20, numCharsPerLine = 36, numLines = 6) {
    if (partialStr) {
      const maxChars = numCharsPerLine * numLines;
      const allWords = this.removeTags(partialStr).trim().split(/[\s]+/);
      const someWords = allWords.slice(0, numWords);
      if (allWords.length > someWords.length) {
        const someStr = someWords.join(' ');

        // If there are too many characters, chop off a word and try again,
        // recursively, until it fits.
        if (someStr.length + 3 > maxChars) {
          return this.transform(someStr, numWords - 1, numCharsPerLine, numLines);
        } else {
          return someStr + '...';
        }
      }
    }

    const strippedOrigStr = this.removeTags(origStr);
    const strippedPartialStr = this.removeTags(partialStr);
    return strippedOrigStr !== strippedPartialStr ? strippedPartialStr + '...' : strippedOrigStr;
  }

  removeTags(str: string): string {
    if (['', null, undefined].includes(str)) {
      return '';
    } else {
      return str
        .replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '') // Remove style tags
        .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '') // Remove script tags
        .replace(/(<([^>]+)>)/gi, '') // Strip all other tags
        .replace(/([\s]+)/gi, ' ') // Replace multiple whitespace characters with single ones
        .trim();
    }
  }
}
