import {AbstractControl, ValidationErrors} from '@angular/forms';

export const ValidateDateTimeRange = (control: AbstractControl): ValidationErrors | null => {
  let hasError = false;

  if (control.value) {
    hasError = !(
      // Should be an object with start and end keys whose values are not null.
      (
        typeof control.value === 'object' &&
        control.value.hasOwnProperty('start') &&
        control.value.hasOwnProperty('end') && // start and end should both be Date or Moment objects...
        ((control.value.start !== null &&
          control.value.end !== null &&
          control.value.start.toISOString &&
          control.value.end.toISOString) ||
          // OR start and end should both be null.
          (control.value.start === null && control.value.end === null))
      )
    );
  }

  return hasError ? {dateTimeRange: true} : null;
};
