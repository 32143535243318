import {EventEmitter, Injectable, Output} from '@angular/core';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {CommonsSearchProject} from '@shared/types/commons-types';
import {lastValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatchService {
  @Output() reloadEmit = new EventEmitter<any>();
  constructor(private cas: CommonsApiService) {}

  async patchProject(project: CommonsSearchProject, fieldName?: string, fieldValue?: any) {
    const field = {};
    if (fieldName) {
      field[fieldName] = fieldValue;
    }
    await lastValueFrom(this.cas.patchProject(project, field));
  }
}
