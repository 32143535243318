<ul class="right-col-fields">
  <li *ngFor="let field of rightColFields">
    <h4>{{ field.label }}:</h4>
    <markdown>{{ field.value }}</markdown>
  </li>
  <li *ngFor="let field of rightColProjectDatasetFileLinkFields">
    <h4>{{ field.label }}:</h4>
    <a href="javascript:void(0);" (click)="openProjectDatasetFileLink(field.value)" class="link">{{
      field.value.name
    }}</a>
  </li>
  <li>
    <h4>KEYWORDS:</h4>
    <mat-chip-list>
      <mat-chip *ngFor="let keyword of keywords()">
        {{ keyword }}
      </mat-chip>
    </mat-chip-list>
  </li>
</ul>
