import {AbstractControl, FormArray, FormGroup, ValidationErrors} from '@angular/forms';
import {IdentifierHipaaID, OtherSensitiveDataID} from '@services/landing-service';
import {IDENTIFIERS_HIPAA} from '@shared/constants/constants';
import {OtherSensitiveData} from '@shared/labels/other-sensitive-data';
import {SensitivityLevel} from '@shared/labels/sensitivity-level';
import {FormSelectOption} from '@shared/types/form-select-option';

export const isHipaaHsd = (formGroup: FormGroup | FormArray): boolean => {
  const hipaaFormControl = formGroup?.get('identifiers_hipaa');

  if (!hipaaFormControl) return false;

  let isHipaaHsd = true;

  // If the form has been populated, determine if the values indicate that the dataset has HSD.
  if (hipaaFormControl?.value) {
    isHipaaHsd =
      hipaaFormControl.value.filter((v: FormSelectOption | string) => {
        const hipaaId = typeof v === 'string' ? v : v.id;
        const hipaaIdEnum = IDENTIFIERS_HIPAA[hipaaId];
        return hipaaIdEnum?.sensitivity_level === SensitivityLevel.HSD;
      }).length > 0;
  }
  return isHipaaHsd;
};

/**
 * Returns a validation error if the dataset has HSD and the given control is empty.
 * @param control
 */
export const ValidateHsd = (control: AbstractControl): null | ValidationErrors => {
  if (control && isHipaaHsd(control.parent)) {
    return !control.value ? {required: true} : null;
  }

  return null;
};

/**
 * Returns a validation error if:
 * - The CUI option is selected in the given control.
 * - The NONE option is selected along with another value.
 * - Otherwise, returns null.
 * @param control
 */
export const checkForNoneAndCUI = (control: AbstractControl): null | ValidationErrors => {
  // Prevent user from saving if CUI option is selected or if NONE or N/A are selected along with another value.
  const nones = new Set([OtherSensitiveDataID.NONE, IdentifierHipaaID.NONE, IdentifierHipaaID.NA]);

  const returnObj: ValidationErrors = {};

  if (control.value !== null && Array.isArray(control.value)) {
    for (const v of control.value) {
      if (v === OtherSensitiveData.CUI) {
        returnObj.cui = true;
      }

      // Check if NONE is selected along with another value.
      if (nones.has(v) && control.value.length > 1) {
        returnObj.none = true;
      }
    }
  }

  return Object.keys(returnObj).length > 0 ? returnObj : null;
};
