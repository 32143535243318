import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, Input, OnInit} from '@angular/core';
import {UnparseConfig} from 'papaparse';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {UserService} from '@services/user-service/user.service';
import {User} from '@shared/types/user';
import {convertResourceListToCsvData, getCsvFilename, getCsvOptions} from '@utilities/csv-export';
import {AppBaseComponent} from '@shared/components/app-base/app-base.component';
import {CommonsSearchProject} from '@shared/types/commons-types';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import FileSaver, {saveAs} from 'file-saver';

@Component({
  selector: 'app-commons-project-list',
  templateUrl: './commons-project-list.component.html',
  styleUrls: ['./commons-project-list.component.scss'],
})
export class CommonsProjectListComponent extends AppBaseComponent implements OnInit {
  @Input() listType: 'public' | 'private';
  @Input() projects: CommonsSearchProject[];
  @Input() publicCommons: boolean;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private cas: CommonsApiService,
    private userService: UserService,
  ) {
    super(breakpointObserver);
  }

  get user(): User {
    return this.userService.getUser();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async getSystemUsageReport(recordType: string) {
    const url = this.cas.getSystemUsageReportUrl(recordType);
    const fileName = `${recordType}.csv`;
    await this.cas.downloadFile(url, fileName, this.userService.user);
  }
}
