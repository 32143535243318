import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface PublishDatasetDialogData {
  confirm: boolean;
  publishCase: number;
  dataGranularityIsOther: boolean;
  isScanComplete: boolean;
}

@Component({
  selector: 'app-publish-dataset-dialog',
  templateUrl: './publish-dataset-dialog.component.html',
  styleUrls: ['./publish-dataset-dialog.component.scss'],
})
export class PublishDatasetDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PublishDatasetDialogData,
    public dialogRef: MatDialogRef<PublishDatasetDialogComponent>,
  ) {}

  canConfirm() {
    return [1, 2].includes(this.data.publishCase);
  }

  onNoClick() {
    this.data.confirm = false;
    this.dialogRef.close(this.data);
  }

  onSubmit() {
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }
}
