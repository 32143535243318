/** Stores the URL the user requested in case they need to log in again. */
export const setPrevUrl = () => {
  const currentUrl = window?.location?.hash?.replace(/^(#\/)*/, ''); // remove leading "#/"
  // Skip if the current URL is a login/logout page
  if (currentUrl && !['session', 'logout', 'login', 'timedout'].includes(currentUrl.split('/')[0])) {
    localStorage.setItem('prev_url', currentUrl);
  }
};

export const getPrevUrl = (defaultURL = '/home') => {
  return localStorage.getItem('prev_url') || defaultURL;
};
