<h2 mat-dialog-title>
  Make this Dataset's data public?
  <span fxFlex></span>
  <button mat-dialog-close="" mat-icon-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<div *ngIf="!data.isScanComplete">
  <p>The data scan has not completed. Please check back later when your file has processed.</p>
</div>

<div *ngIf="data.isScanComplete" mat-dialog-content class="mat-typography" style="height: calc(100% - 96px)">
  <p class="mat-headline">By clicking "Confirm" below...</p>
  <div *ngIf="data.publishCase === 1">
    <p>• I confirm that this dataset contains no patient data or other sensitive data.</p>
    <p>
      I understand that publishing this file will generate a Digital Object Identifier (DOI) in UVA LibraData. (Note
      that you will receive an email with details regarding how to manage this DOI record.)
    </p>
  </div>
  <div *ngIf="data.publishCase === 2">
    <p>
      • I confirm that this dataset contains no HIPAA identifiers except possibly dates and or geographic information
      (zip code or less specific) thereby meeting the criteria for de-identified or limited datasets.
    </p>
    <p>• I confirm that no row-level data is being published.</p>
    <p>• I confirm the dataset does not contain other sensitive data.</p>
    <p>
      • I understand that publishing this file will generate a Digital Object Identifier (DOI) in UVA LibraData. (Note
      that you will receive an email with details regarding how to manage this DOI record.)
    </p>
  </div>
  <div *ngIf="data.dataGranularityIsOther">
    <p>
      • By electing to publish this file with Data Granularity type "OTHER" you are confirming that you have
      institutional approval to make this file fully public and that there is no risk of patient re-identification."
    </p>
  </div>
  <div class="note-text">
    There may be a several-minute delay before your file is publicly accessible once you have made it public.
  </div>
  <div *ngIf="!canConfirm()">
    <p>This dataset is not able to made public.</p>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="canConfirm()">
  <button (click)="onSubmit()" color="primary" mat-flat-button fxFlex="calc(50% - 10px)">
    <mat-icon>check</mat-icon>
    Confirm
  </button>
  <button (click)="onNoClick()" color="warn" mat-flat-button fxFlex="calc(50% - 10px)">
    <mat-icon>close</mat-icon>
    Cancel
  </button>
</div>
