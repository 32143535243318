import {Component, Input, OnInit} from '@angular/core';
import {NgForOf, NgIf} from '@angular/common';
import {FlexModule} from '@angular/flex-layout';

export interface ParsedError {
  title: string;
  errors: ErrorMessage[];
}

export interface ErrorMessage {
  title: string;
  messages: string[];
}

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  standalone: true,
  imports: [NgForOf, FlexModule, NgIf],
})
export class ErrorMessageComponent implements OnInit {
  @Input() errorString: string;
  @Input() parsedError: ParsedError;
  title: string;
  message: string;
  errors: ErrorMessage[] = [];

  constructor() {}

  ngOnInit() {
    if (this.parsedError) {
      this.title = this.parsedError.title;
      this.errors = this.parsedError.errors;
    } else if (this.errorString) {
      this.parseError();
    }
  }

  parseError() {
    if (typeof this.errorString === 'string') {
      const patternJsonWithCode = /^([^{]+): {"code":([^,]+),"errors":(.*),"status":"(.*)"}\W+$/;
      const patternJson = /^{"title":"([^"]+)","errors":\[(.*)]}]}\W+$/;
      const patternMulti = /^([^{]+): {"message":"(.*) Error:(.*)"}\W+$/;
      const patternShort = /^([^{]+): {"message":"(.*)"}\W+$/;

      if (patternJsonWithCode.test(this.errorString)) {
        patternJsonWithCode.lastIndex = 0; // Reset the regex
        const results = patternJsonWithCode.exec(this.errorString);

        if (results && results.length === 5) {
          this.title = results[1];
          this.message = `${results[2]}: ${results[4]}`;
          const errorsObjString = results[3];
          const errorsObj = JSON.parse(errorsObjString);
          this.errors = Object.entries(errorsObj).map(([key, value]) => {
            const returnObj = {title: key, messages: []};
            if (value instanceof Array) {
              returnObj.messages = value;
            } else if (typeof value === 'string') {
              returnObj.messages = [value];
            } else if (typeof value === 'object') {
              returnObj.messages = Object.entries(value).map(([mKey, mValue]) => `${mKey}: ${mValue}`);
            }

            return returnObj;
          });
        }

        const parsedError: ParsedError = JSON.parse(this.errorString);
        this.title = parsedError.title;
        this.errors = parsedError.errors;
      } else if (patternJson.test(this.errorString)) {
        const parsedError: ParsedError = JSON.parse(this.errorString);
        this.title = parsedError.title;
        this.errors = parsedError.errors;
      } else if (patternMulti.test(this.errorString)) {
        patternMulti.lastIndex = 0; // Reset the regex
        const results = patternMulti.exec(this.errorString);
        if (results && results.length === 4) {
          this.title = results[1];
          this.message = results[2];

          const arrayPattern = /{'([^':]+)': \[\\"([^\\"]+)\\"]}/g;
          const errors = results[3];
          if (arrayPattern.test(errors)) {
            arrayPattern.lastIndex = 0; // Reset the regex
            const arrayPatternResults = arrayPattern.exec(errors);

            if (arrayPatternResults && arrayPatternResults.length >= 3) {
              const matches = arrayPatternResults.slice(1); // Get all but the first item.

              // Even indexes will be keys. Odd indexes will be values:
              for (let i = 0; i < matches.length; i += 2) {
                const messages = matches[i + 1].split(/"],\W+\["/);

                this.errors.push({
                  title: matches[i],
                  messages,
                });
              }
            } else {
              this.message = this.errorString;
            }
          }
        }
      } else if (patternShort.test(this.errorString)) {
        patternShort.lastIndex = 0; // Reset the regex
        const results = patternShort.exec(this.errorString);

        if (results && results.length === 3) {
          this.title = results[1];
          this.message = results[2];
        }
      } else {
        this.message = this.errorString;
      }
    }
  }
}
