<mat-card
  fxFlex.lg="calc(25% - 1em)"
  fxFlex.md="calc(25% - 1em)"
  fxFlex.sm="calc(50% - 1em)"
  fxFlex.xl="calc(25% - 1em)"
  fxFlex.xs="calc(100% - 1em)"
>
  <mat-card-content>
    <h2 class="mat-card-title">PROJECT DETAILS</h2>
    <app-public-private-toggle
      *ngIf="showToggle()"
      [selected_option]="project.is_metadata_public ? 'PUBLIC' : 'PRIVATE'"
      (toggle)="togglePrivate($event)"
      tooltipPrivate="Click here to allow only project owners and collaborators to view the project details."
      tooltipPublic="Click here to allow anyone to view the project details."
    ></app-public-private-toggle>
    <mat-list dense>
      <app-comma-delimited-list-item
        [field]="project.alternate_name"
        title="Alternate Project Name"
      ></app-comma-delimited-list-item>
      <app-comma-delimited-list-item
        *ngIf="!publicCommons"
        [field]="toStringArray(getPIs(project.project_users))"
        title="Principal Investigator"
      ></app-comma-delimited-list-item>
      <mat-list-item *ngIf="user">
        <h4 mat-line>Source Organization:</h4>
        <p mat-line>{{ project.source_organization.name }}</p>
      </mat-list-item>
      <app-comma-delimited-list-item
        [field]="toStringArray(project.partners)"
        title="Partner Institution"
      ></app-comma-delimited-list-item>
      <app-comma-delimited-list-item
        [field]="toStringArray(project.funders)"
        title="Funding Source"
      ></app-comma-delimited-list-item>
      <app-comma-delimited-list-item
        [field]="toStringArray(project.web_page_urls)"
        title="Website"
        displayType="link"
      ></app-comma-delimited-list-item>
      <app-comma-delimited-list-item
        [field]="toStringArray(project.keywords)"
        title="Keywords"
        displayType="chip"
      ></app-comma-delimited-list-item>
    </mat-list>

    <div *ngIf="showProjectTeam()" class="project-team">
      <h2 class="mat-card-title">PROJECT TEAM</h2>
      <mat-list dense>
        <table [dataSource]="project.project_users" class="projectperms" aria-describedby="Project team" mat-table>
          <ng-container matColumnDef="email">
            <th *matHeaderCellDef mat-header-cell>EMAIL</th>
            <td *matCellDef="let up" mat-cell>
              {{ toTypeUp(up).user.email }}
            </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th *matHeaderCellDef mat-header-cell>ROLE</th>
            <td
              *matCellDef="let up"
              mat-cell
              [matTooltip]="getRoleTooltip(lookupRole(toTypeUp(up).project_role_id))"
              matTooltipPosition="left"
            >
              {{ lookupRole(toTypeUp(up).project_role_id) }}
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedUserpermColumns" mat-header-row></tr>
          <tr
            *matRowDef="let row; let odd = odd; columns: displayedUserpermColumns"
            [ngClass]="{gray: odd}"
            mat-row
          ></tr>
        </table>
      </mat-list>
      <button
        (click)="showProjectPermissions()"
        *ngIf="showEditPermissions()"
        color="primary"
        mat-raised-button
        matTooltip="Click here to edit project details/permissions"
      >
        <mat-icon>security</mat-icon>
        EDIT PERMISSIONS
      </button>
    </div>
  </mat-card-content>
</mat-card>
