import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TreeSelectComponent} from '@form-controls/tree-select/tree-select.component';
import {MaterialModule} from '@material/material.module';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {RichTextEditorModule} from '@syncfusion/ej2-angular-richtexteditor';

@NgModule({
  declarations: [TreeSelectComponent],
  exports: [TreeSelectComponent],
  imports: [CommonModule, RichTextEditorModule, MaterialModule],
  providers: [ResourceApiService],
})
export class TreeSelectModule {}
