import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {stringify} from 'telejson';
import {BreadcrumbsHistoryService} from '@services/breadcrumbs-history/breadcrumbs-history.service';
import {NavItem} from '@shared/types/nav-item';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnChanges {
  @Input() navItems: NavItem[];

  constructor(
    private breadcrumbsHistory: BreadcrumbsHistoryService,
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.navItems) {
      if (this.hasChanged(changes.navItems.previousValue, changes.navItems.currentValue)) {
        this.breadcrumbsHistory.save(this.navItems);
      }
    }
  }

  handleClick(item: NavItem) {
    if (item.hasOwnProperty('onClick') && typeof item.onClick === 'function') {
      return item.onClick();
    } else if (item.hasOwnProperty('routerLink')) {
      const commands = typeof item.routerLink === 'string' ? [item.routerLink] : item.routerLink;
      return this.router.navigate(commands, {queryParams: item.queryParams || {}});
    }
  }

  private hasChanged(oldItems: NavItem[], newItems: NavItem[]): boolean {
    const prev = stringify(oldItems);
    const curr = stringify(newItems);
    return prev !== curr;
  }

  navigateHome() {
    this.handleClick({title: 'Events', routerLink: '/home', queryParams: {}});
  }
}
