import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {getNavItems} from '@commons/commons-nav/commons-nav-util';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {UserService} from '@services/user-service/user.service';
import {NavItem} from '@shared/types/nav-item';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-commons-menu',
  templateUrl: './commons-menu.component.html',
  styleUrls: ['./commons-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonsMenuComponent implements OnChanges {
  @Input() title: string;
  @Input() navItems?: NavItem[];

  constructor(
    private cas: CommonsApiService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private titleService: Title,
  ) {}

  get user(): User {
    return this.userService.getUser();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title) {
      this.setPageTitle();
    }
  }

  async reloadNavItems() {
    const listType = (await this.userService.getCommons()) === 'public_commons' ? 'commons-public' : 'commons-private';
    this.navItems = getNavItems([listType]);
  }

  async setPageTitle() {
    await this.reloadNavItems();
    let pageTitle = 'iTHRIV > Commons';
    if (this.navItems && this.navItems.length > 0) {
      const action = this.navItems[this.navItems.length - 1];
      pageTitle = pageTitle + ' > ' + action.title;
    }

    if (this.navItems && this.title) {
      pageTitle = pageTitle + ' > ' + this.title;
      history.pushState(null, pageTitle, null);
    }

    this.titleService.setTitle(pageTitle);
  }
}
