<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <button
    mat-stroked-button
    *ngFor="let option of options"
    [matTooltip]="option.name"
    [ngStyle]="{borderColor: option.color}"
    (click)="handleValueChange($event, option.id)"
    [ngClass]="{'service-logo': true, selected: isSelected(option.id)}"
    [formGroup]="field.formGroup"
  >
    <img *ngIf="option.image" [alt]="option.name" [src]="option.image" />
    <span *ngIf="!option.image">{{ option.name }}</span>
  </button>
</div>
<ng-content></ng-content>
