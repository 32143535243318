<mat-card
  *ngIf="event && event.id"
  [routerLink]="['/resource', event.id]"
  [ngClass]="{event: true, private: event.private}"
  [ngStyle]="{backgroundColor: categoryColor}"
  [hidden]="!event.user_may_view"
>
  <div class="gradient-bg-overlay"></div>
  <div class="icon-with-content" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
    <div fxFlex="70px">
      <div class="type-icon" *ngIf="typeIconId(event)">
        <mat-icon svgIcon="ithriv_{{ typeIconId(event) }}" matTooltip="{{ event.type.name }}"></mat-icon>
      </div>
      <div class="institution" matTooltip="{{ event.institution.name }}">
        <img [alt]="event.institution.name" [src]="getInstitutionLogoUrl(event.institution)" />
      </div>
    </div>

    <div fxFlex="calc(100% - 90px)">
      <mat-card-header [routerLink]="['/resource', event.id]">
        <mat-card-title>{{ event.segment.name }}: {{ event.name }}</mat-card-title>
        <app-approved-badge [resource]="event" [user]="user"></app-approved-badge>
        <app-favorite-resource-button [resource]="event" [user]="user"></app-favorite-resource-button>
      </mat-card-header>
      <mat-card-content [routerLink]="['/resource', event.id]" [ngStyle]="{color: 'white'}">
        <div fxLayout="row wrap" fxLayoutGap="8px">
          {{ renderStartDate(event.starts) }} -
          {{ renderEndDate(event.ends) }}
        </div>
        <div>{{ event.location }}</div>
        <div class="add-to-calendar">
          <button
            mat-stroked-button
            title="Add to Calendar"
            class="addeventatc"
            (click)="handleClick($event)"
            matTooltip="Click to add to calender"
          >
            Add to Calendar
            <span class="arrow">&nbsp;</span>
            <span class="start">{{ event.starts | date: 'MM/d/y h:mm a' }}</span>
            <span class="end">{{ event.ends | date: 'MM/d/y h:mm a' }}</span>
            <span class="timezone"></span>
            <span class="title">{{ event.name }}</span>
            <span class="description">{{ getResourceOrEventURL(event) }}</span>
            <span class="location">{{ event.location }}</span>
            <span class="alarm_reminder">1440</span>
          </button>
        </div>
      </mat-card-content>
    </div>
  </div>
</mat-card>
