import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AuthenticationModule} from '@authentication/authentication.module';
import {ButtonsModule} from '@buttons/buttons.module';
import {FormControlsModule} from '@form-controls/form-controls.module';
import {MaterialModule} from '@material/material.module';
import {NavigationModule} from '@navigation/navigation.module';
import {CategoryTileComponent} from '@portal/category-tile/category-tile.component';
import {CategoryComponent} from '@portal/category/category.component';
import {ConsultRequestFormComponent} from '@portal/consult-request-form/consult-request-form.component';
import {EventTileComponent} from '@portal/event-tile/event-tile.component';
import {FavoriteResourceListComponent} from '@portal/favorite-resource-list/favorite-resource-list.component';
import {ResourceListComponent} from '@portal/resource-list/resource-list.component';
import {ResourceSearchComponent} from '@portal/resource-search/resource-search.component';
import {ResourceTileComponent} from '@portal/resource-tile/resource-tile.component';
import {ResourceComponent} from '@portal/resource/resource.component';
import {SearchBarComponent} from '@portal/search-bar/search-bar.component';
import {SearchComponent} from '@portal/search/search.component';
import {UserResourceListComponent} from '@portal/user-resource-list/user-resource-list.component';
import {DirectivesModule} from '@shared/directives/directives.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {MarkdownModule} from 'ngx-markdown';

@NgModule({
  declarations: [
    CategoryComponent,
    CategoryTileComponent,
    ConsultRequestFormComponent,
    EventTileComponent,
    FavoriteResourceListComponent,
    ResourceComponent,
    ResourceListComponent,
    ResourceSearchComponent,
    ResourceTileComponent,
    SearchBarComponent,
    SearchComponent,
    UserResourceListComponent,
  ],
  imports: [
    AuthenticationModule,
    ButtonsModule,
    CommonModule,
    DirectivesModule,
    FlexLayoutModule,
    FormControlsModule,
    FormsModule,
    MarkdownModule.forRoot({loader: HttpClient}),
    MaterialModule,
    NavigationModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    CategoryComponent,
    CategoryTileComponent,
    ConsultRequestFormComponent,
    EventTileComponent,
    FavoriteResourceListComponent,
    ResourceComponent,
    ResourceListComponent,
    ResourceSearchComponent,
    ResourceTileComponent,
    SearchBarComponent,
    SearchComponent,
    UserResourceListComponent,
  ],
})
export class PortalModule {}
