<div
  *ngIf="!(user && user.role === 'Admin') && resource.user_may_edit && resource.approved !== 'Approved'"
  class="ribbon ribbon-top-right"
>
  <span>Unapproved</span>
</div>
<div *ngIf="user && user.role === 'Admin'" class="ribbon ribbon-top-right clickable">
  <span
    (click)="handleClick($event)"
    *ngIf="resource.approved === 'Approved'"
    class="approved"
    matTooltip="{{ instructions() }}"
    >Approved</span
  >
  <span (click)="handleClick($event)" *ngIf="resource.approved !== 'Approved'" matTooltip="{{ instructions() }}"
    >Unapproved</span
  >
</div>
