import {Component, Input, OnInit} from '@angular/core';
import {FormField} from '@shared/types/form-field';
import {FormSelectOption} from '@shared/types/form-select-option';

@Component({
  selector: 'app-image-select',
  templateUrl: './image-select.component.html',
  styleUrls: ['./image-select.component.scss'],
})
export class ImageSelectComponent implements OnInit {
  @Input() field: FormField;
  @Input() options: FormSelectOption[];

  constructor() {}

  ngOnInit() {
    if (this.field.formControl.value) {
      this.field.formControl.setValue(this.field.formControl.value);
    } else if (this.field.defaultValue) {
      this.field.formControl.setValue(this.field.defaultValue);
    }
  }

  handleValueChange($event: MouseEvent, institutionId) {
    $event.preventDefault();
    this.field.formControl.setValue(institutionId);
    this.field.formControl.updateValueAndValidity();
    if (this.field.hasOwnProperty('onChange')) {
      this.field.onChange($event);
    }
  }

  isSelected(value: string | object): boolean {
    if (!this.field.formControl.value) {
      return false;
    }

    if (typeof this.field.formControl.value === 'object' && this.field.formControl.value.hasOwnProperty('name')) {
      if (typeof value === 'object' && value.hasOwnProperty('name')) {
        return this.field.formControl.value.name === value['name'];
      }
      return this.field.formControl.value.name === value;
    }

    return `${this.field.formControl.value}` === `${value}`;
  }
}
