import {Component, Inject} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface CommonsEulaDialogData {
  confirm: boolean;
}

@Component({
  selector: 'app-commons-eula-dialog',
  templateUrl: './commons-eula-dialog.component.html',
  styleUrls: ['./commons-eula-dialog.component.scss'],
})
export class CommonsEulaDialogComponent {
  form = new UntypedFormGroup({});

  constructor(
    public dialogRef: MatDialogRef<CommonsEulaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonsEulaDialogData,
  ) {}

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit(userAccepted: boolean) {
    const data: CommonsEulaDialogData = {
      confirm: userAccepted,
    };
    this.dialogRef.close(data);
  }
}
