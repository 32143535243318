import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Institution} from '@shared/types/institution';
import {UserService} from '@services/user-service/user.service';
import {Resource} from '@shared/types/resource';
import {User} from '@shared/types/user';
import {ALL_LOGIN_SERVICES} from '@authentication/login-service';
import dayjs from '@src/assets/dayjs';

// await import '@src/assets/js/atc.min.js';

@Component({
  selector: 'app-event-tile',
  templateUrl: './event-tile.component.html',
  styleUrls: ['./event-tile.component.scss'],
})
export class EventTileComponent implements OnInit, AfterViewInit {
  @Input() event: Resource;
  sameDayEvent?: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  get user(): User {
    return this.userService.getUser();
  }

  checkIfSameDayEvent() {
    this.sameDayEvent = dayjs(this.event.starts).format('MMM D, YYYY') === dayjs(this.event.ends).format('MMM D, YYYY');
  }

  renderStartDate(date: string, tz?: string) {
    if (!date.endsWith('Z')) {
      date += 'Z';
    }
    let format = 'MMM D, YYYY h:mm A';
    let render = dayjs(date).tz(tz).format(format);
    return render === 'Invalid Date' ? '' : render;
  }

  renderEndDate(date: string, tz?: string) {
    if (!date.endsWith('Z')) {
      date += 'Z';
    }
    let format = this.sameDayEvent ? 'h:mm A z' : 'MMM D, YYYY h:mm A z';
    let render = dayjs(date).tz(tz).format(format);
    return render === 'Invalid Date' ? '' : render;
  }

  get categoryColor(): string {
    if (this.event && this.event.resource_categories && this.event.resource_categories.length > 0) {
      const counts = {};

      // Get all category colors
      this.event.resource_categories.forEach(rc => {
        counts[rc.category.color] = counts[rc.category.color] ? counts[rc.category.color] + 1 : 1;
      });

      // Count the category colors. Return the color with the highest count.
      const countsArray = Object.entries(counts);
      let highest = countsArray[0];

      countsArray.forEach(c => {
        if (c[1] > highest[1]) {
          highest = c;
        }
      });

      return highest[0];
    }
  }

  ngOnInit() {
    let import_js = async () => {
      // using this atc.min.js need to be changed in the future to an ES6 compliant module
      // @ts-ignore
      return await import('@src/assets/js/atc.min.js');
    };
    import_js().then(() => {});
  }

  ngAfterViewInit() {
    this.checkIfSameDayEvent();
    this.changeDetectorRef.detectChanges();
  }

  typeIconId(resource: Resource) {
    return resource && resource.type && resource.type.icon && resource.type.icon.id;
  }

  getResourceOrEventURL(resource: Resource) {
    return [window.location.origin, '#', 'resource', resource.id].join('/');
  }

  getInstitutionLogoUrl(institution: Institution) {
    return ALL_LOGIN_SERVICES[institution.name].image.replace('.svg', '-inverse.svg');
  }

  handleClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
