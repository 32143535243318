import {Component, Input} from '@angular/core';
import FileSaver from 'file-saver';
import {unparse, UnparseConfig} from 'papaparse';

@Component({
  selector: 'app-csv-export-button',
  templateUrl: './csv-export-button.component.html',
  styleUrls: ['./csv-export-button.component.scss'],
})
export class CsvExportButtonComponent {
  @Input() data: any[];
  @Input() filename: string;
  @Input() options: UnparseConfig;

  constructor() {}

  onDownload() {
    const csv = unparse(this.data, this.options);
    const blob = new Blob([csv], {type: 'text/plain;charset=utf-8'});
    FileSaver.saveAs(blob, this.filename);
  }
}
