// Angular Imports
import {Component, Inject, OnInit} from '@angular/core';
import {NgIf} from '@angular/common';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

// Material Imports
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MaterialModule} from '@material/material.module';

// App Imports
import {FormControlsModule} from '@form-controls/form-controls.module';
import {RichTextFieldModule} from '@form-controls/rich-text-field/rich-text-field.module';
import {ResourceApiService} from '@services/resource-api/resource-api.service';

// Types
import {Category} from '@shared/types/category';
import {FormField} from '@shared/types/form-field';
import {ErrorStateMatcher} from '@angular/material/core';

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss'],
  standalone: true,
  imports: [MaterialModule, NgIf, FormControlsModule, RichTextFieldModule, ReactiveFormsModule],
})
export class CategoryFormComponent implements OnInit {
  category: Category;
  categoryForm: UntypedFormGroup = new UntypedFormGroup({});
  createNew = false;
  error: string;
  isDataLoaded = false;
  showConfirmDelete = false;

  // Form Fields
  fields = {
    name: new FormField({
      formGroup: this.categoryForm,
      formControl: new UntypedFormControl(),
      required: true,
      maxLength: 100,
      minLength: 1,
      placeholder: 'Name',
      type: 'text',
    }),
    description: new FormField({
      formGroup: this.categoryForm,
      formControl: new UntypedFormControl(),
      required: true,
      placeholder: 'Description',
      type: 'richtexteditor',
      options: {
        status: ['words'],
      },
    }),
    brief_description: new FormField({
      name: 'brief_description',
      formGroup: this.categoryForm,
      formControl: new UntypedFormControl(),
      required: true,
      maxLength: 140,
      minLength: 20,
      placeholder: 'Brief Description',
      type: 'text',
    }),
    image: new FormField({
      formGroup: this.categoryForm,
      formControl: new UntypedFormControl(),
      placeholder: 'Image',
      type: 'text',
    }),
    icon: new FormField({
      formGroup: this.categoryForm,
      formControl: new UntypedFormControl(),
      placeholder: 'Select Icon',
      type: 'select',
      apiSource: 'getIcons',
      showIcons: true,
    }),
    color: new FormField({
      formGroup: this.categoryForm,
      formControl: new UntypedFormControl(),
      placeholder: 'Color',
      type: 'color',
    }),
  };
  errorMatcher: ErrorStateMatcher;

  constructor(
    private api: ResourceApiService,
    public dialogRef: MatDialogRef<CategoryFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    const colWidth = 100 - 1 / 6;
    const parent = this.data.parent_category;
    const level = parent ? parent.level + 1 : 0;
    this.dialogRef.updateSize(`${colWidth}vw`);

    if (this.data.edit) {
      this.createNew = false;
      this.category = this.data.edit;
    } else {
      this.createNew = true;
      this.category = {
        id: null,
        name: '',
        description: '',
        brief_description: '',
        level: level,
      };
      if (this.data.parent_category) {
        this.category.parent_id = this.data.parent_category.id;
      }
    }
    this.loadForm();
  }

  ngOnInit() {
    if (!this.createNew) {
      this.validate();
    }
  }

  loadForm() {
    Object.entries(this.fields).forEach(([fieldName, field]) => {
      if (this.fields.hasOwnProperty(fieldName)) {
        const validators = [];

        if (field.required) {
          validators.push(Validators.required);
        }

        if (field.minLength) {
          validators.push(Validators.minLength(field.minLength));
        }

        if (field.maxLength) {
          validators.push(Validators.maxLength(field.maxLength));
        }

        field.formControl.patchValue(this.category[fieldName]);
        // this[fieldName].valueChanges.subscribe(t => this.category[fieldName] = t);
        field.formControl.setValidators(validators);
        this.categoryForm.addControl(fieldName, field.formControl);
      }
    });

    this.isDataLoaded = true;
  }

  getFields(): FormField[] {
    const fields = [];

    for (const fieldName in this.fields) {
      if (this.fields.hasOwnProperty(fieldName)) {
        fields.push(this.fields[fieldName]);
      }
    }

    return fields;
  }

  onSubmit() {
    this.validate();
    this.isDataLoaded = false;

    if (this.categoryForm.valid) {
      for (const fieldName in this.fields) {
        if (this.fields.hasOwnProperty(fieldName)) {
          this.category[fieldName] = this.fields[fieldName].formControl.value;
        }
      }

      if (this.createNew) {
        if (this.category.icon !== undefined) {
          this.category.icon_id = String(this.category.icon);
        }
        this.api.addCategory(this.category).subscribe(c => {
          this.category = c;
          if (this.data.parent_category) {
            this.data.parent_category.children.push(this.category);
          }
          this.dialogRef.close(this.category);
          this.isDataLoaded = true;
        });
      } else {
        if (this.category.icon !== undefined) {
          this.category.icon_id = String(this.category.icon);
        }
        this.api.updateCategory(this.category).subscribe(c => {
          this.category = c;
          if (this.data.parent_category) {
            this.data.parent_category.children.push(this.category);
          }
          this.dialogRef.close(this.category);
          this.isDataLoaded = true;
        });
      }
    }
  }

  validate() {
    for (const key in this.categoryForm.controls) {
      if (this.categoryForm.controls.hasOwnProperty(key)) {
        const control = this.categoryForm.get(key);
        control.markAsTouched();
      }
    }
  }

  onCancel() {
    this.dialogRef.close(this.category);
  }

  showDelete() {
    this.showConfirmDelete = true;
  }

  onDelete() {
    this.api.deleteCategory(this.category).subscribe({
      next: () => {
        if (this.data.parent_category) {
          const index = this.data.parent_category.children.indexOf(this.category, 0);
          if (index > -1) {
            this.data.parent_category.children.splice(index, 1);
          }
        }
        this.dialogRef.close(this.category);
      },
      error: error => {
        this.error = error;
      },
    });
  }
}
