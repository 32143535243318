<div *ngIf="user && resources" fxFlexFill>
  <app-resource-list
    [resources]="resources"
    [sidenavExpanded]="false"
    [navItems]="[{title: 'My Resources and Events', routerLink: '/home', queryParams: {tabIndex: 'user_resources'}}]"
    [showPastEvents]="true"
    [hideEventsFilter]="true"
    fxFlexFill
  ></app-resource-list>
</div>
<div *ngIf="!user && !institution" class="please-log-in">
  <h2>Please log in to view your resources.</h2>
  <app-login-services></app-login-services>
</div>
<div *ngIf="!user && institution" class="please-log-in">
  <h2>{{ institution.name }} View</h2>
  <h2>You must <a href="#/login">log in</a> to view resources you own.</h2>
</div>
