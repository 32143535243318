import {Component, Input} from '@angular/core';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {Favorite} from '@shared/types/favorite';
import {Resource} from '@shared/types/resource';
import {User} from '@shared/types/user';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-favorite-resource-button',
  templateUrl: './favorite-resource-button.component.html',
  styleUrls: ['./favorite-resource-button.component.scss'],
})
export class FavoriteResourceButtonComponent {
  @Input() resource: Resource;
  favorite: Favorite;
  @Input() user: User;

  constructor(private api: ResourceApiService) {}

  isUserFavorite(): boolean {
    return !!this.resource.favorites.find(f => f.user_id === this.user.id);
  }

  addFavorite() {
    this.api.addFavorite(this.user, this.resource).subscribe(f => {
      this.favorite = f;
      this.resource.favorites.push(this.favorite);
    });
  }

  async deleteFavorite() {
    for (const f of this.resource.favorites) {
      if (f.user_id === this.user.id) {
        this.favorite = f;
      }
    }

    await lastValueFrom(this.api.deleteFavorite(this.favorite));
    const index = this.resource.favorites.indexOf(this.favorite, 0);
    if (index > -1) {
      this.resource.favorites.splice(index, 1);
    }
  }

  async handleClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.isUserFavorite()) {
      await this.deleteFavorite();
    } else {
      this.addFavorite();
    }
  }

  instructions(): string {
    if (this.isUserFavorite()) {
      // language=TEXT
      return 'Delete Resource/Event from Favorites';
    } else {
      return 'Add Resource/Event to Favorites';
    }
  }
}
