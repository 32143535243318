import {EventEmitter, Injectable, NgZone, Output} from '@angular/core';
import {getUserLoginService} from '@authentication/login-service';
import {User} from '@shared/types/user';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {IntervalService} from '@services/interval/interval.service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {lastValueFrom} from 'rxjs';

const MIN_INTERVAL = 1000;
const MAX_INTERVAL = 30000;

@Injectable({
  providedIn: 'root',
})
export class VPNCheckService extends IntervalService {
  intervalMs = MIN_INTERVAL;
  userIsOnVPN: boolean | undefined = undefined;
  userHasLandingService: boolean | undefined = undefined;
  userIsLandingServiceAdmin: boolean | undefined = undefined;
  oldStatus: boolean | undefined = undefined;
  @Output() updated = new EventEmitter<boolean | undefined>();

  constructor(
    public api: ResourceApiService,
    public cas: CommonsApiService,
    public userService: UserService,
  ) {
    super();
    this.intervalMs = MIN_INTERVAL;
    this.reset();
    this.loadStatus();
  }

  get user(): User {
    return this.userService.getUser();
  }

  updateHasLandingService(): boolean {
    if (this.user && this.user.institution && this.user.institution.name) {
      const loginService = getUserLoginService(this.user);
      this.userHasLandingService = !!(loginService && loginService.hasLandingService);
      return;
    }
    this.userHasLandingService = false;
  }

  reset() {
    this.userIsOnVPN = undefined;
    this.userHasLandingService = undefined;
    this.userIsLandingServiceAdmin = undefined;
    this.oldStatus = undefined;
    this.intervalMs = MIN_INTERVAL;
    this.clearInterval();
    this.updated.emit(this.userIsOnVPN);
  }

  loadStatus() {
    this.clearInterval();
    this.setInterval(async () => {
      await this.loadVPNStatus();
    }, this.intervalMs);
  }

  /**
   * Sets userIsOnVPN to true if the user can reach the landing service. Otherwise, sets it to false.
   * @private
   */
  async loadVPNStatus() {
    this.updateHasLandingService();
    if (this.userHasLandingService) {
      this.oldStatus = this.userIsOnVPN;

      try {
        const status = await lastValueFrom(this.cas.getStatus(this.user));
        this.userIsOnVPN = !!status;
      } catch (error) {
        // The user is not on VPN. Keep retrying, with increasing timeout intervals.
        this.userIsOnVPN = false;
      }

      if (this.userIsOnVPN) {
        const landingServiceUser = await lastValueFrom(this.cas.getLandingServiceUser(this.user));
        this.userIsLandingServiceAdmin = !!landingServiceUser?.is_admin;
      }
    } else {
      this.userIsOnVPN = false;
      this.userIsLandingServiceAdmin = false;
    }
    this.updated.emit(this.userIsOnVPN);
    this.updateIntervalAndStatus();
  }

  private calculateIntervalMs() {
    if (this.userIsOnVPN) {
      // If the user is on VPN, re-check every 30 seconds.
      return MAX_INTERVAL;
    } else {
      // If the user is not on VPN, keep retrying, with increasing timeout intervals, maxing out at 30 seconds.
      return Math.min(this.intervalMs * 2, MAX_INTERVAL);
    }
  }

  private updateIntervalAndStatus() {
    this.intervalMs = this.calculateIntervalMs();
    this.loadStatus();
  }
}
