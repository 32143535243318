export const formatSize = (bytes: number, decimalPlaces = 2): string => {
  const sizes = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];

  for (let i = 0; i < sizes.length; i++) {
    const divisor = Math.pow(1024, i);
    const nextDivisor = divisor * 1000;

    if (bytes < nextDivisor) {
      let result = Intl.NumberFormat(undefined, {
        style: 'unit',
        unitDisplay: 'short',
        unit: sizes[i],
        maximumFractionDigits: decimalPlaces,
      }).format(bytes / divisor);
      result = result.replace('byte', 'B').replace('Byte', 'B').replace('octet', 'o'); // Fix for tiniest units in most languages, German, and Canadian French
      return result;
    }
  }
};
