type MaybeObjectOrArray = {[key: string]: any} | ArrayLike<any> | any;

/**
 * For the given object or array, return a new object or array with only the items that have non-empty values.
 **/
export const removeEmptyItems = (item?: MaybeObjectOrArray): MaybeObjectOrArray => {
  const isEmpty = v => ['', null, undefined].includes(v);

  // If item itself is empty, return undefined.
  if (isEmpty(item)) return;

  if (Array.isArray(item)) {
    if (item.length === 0) return [];

    return Object.values(item).filter(x => !isEmpty(x));
  }

  // If item is an object, return a new object with only the items that have non-empty values
  if (typeof item === 'object') {
    if (Object.keys(item).length === 0) return {};
    return Object.entries(item).reduce((acc, [k, v]) => {
      if (!isEmpty(v)) {
        acc[k] = v;
      }
      return acc;
    }, {});
  }

  // If all else fails, just return the given item, unmodified.
  return item;
};
