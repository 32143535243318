import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DeleteDocumentData {
  confirm: boolean;
  document_name: string;
}

@Component({
  selector: 'app-delete-document-dialog',
  templateUrl: './delete-document-dialog.component.html',
  styleUrls: ['./delete-document-dialog.component.scss'],
})
export class DeleteDocumentDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeleteDocumentData,
    public dialogRef: MatDialogRef<DeleteDocumentDialogComponent>,
  ) {}

  onNoClick() {
    this.data.confirm = false;
    this.dialogRef.close(this.data);
  }

  onSubmit() {
    this.data.confirm = true;
    this.dialogRef.close(this.data);
  }
}
