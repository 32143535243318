import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {UserService} from '@services/user-service/user.service';
import {LandingService} from '@shared/types/environment';
import fileSaver from 'file-saver';
import {
  CommonsObject,
  CommonsObjectWithJoins,
  CommonsProjectDocument,
  CommonsSearchDataset,
  CommonsSearchProject,
  DatasetUnion,
  ProjectUnion,
} from '@shared/types/commons-types';
import {User} from '@shared/types/user';
import {environment} from '@src/environments/environment';
import {showProgress} from '@utilities/show-progress';
import {lastValueFrom, Observable, of, throwError, TimeoutError} from 'rxjs';
import {catchError, finalize, last, map, tap, timeout} from 'rxjs/operators';
import {
  DatasetCreate,
  DatasetPatch,
  DatasetPut,
  DatasetResponse,
  DatasetsService,
  FileVersionResponse,
  IRBInvestigatorsResponse,
  IrbProtocolsService,
  IRBUserProtocolsResponse,
  OpenAPI,
  ProjectCreate,
  ProjectFileVersionResponse,
  ProjectPatch,
  ProjectPut,
  ProjectResponse,
  ProjectsService,
  StatusService,
  SystemUsageReportService,
  UserResponse,
  UsersService,
} from '../landing-service';

@Injectable({
  providedIn: 'root',
})
export class CommonsApiService {
  constructor(
    private readonly http: HttpClient,
    public datasetsService: DatasetsService,
    // public debugService: DebugService,
    public irbProtocolsService: IrbProtocolsService,
    // public keywordsService: KeywordsService,
    // public labelsService: LabelsService,
    // public organizationsService: OrganizationsService,
    public projectsService: ProjectsService,
    public statusService: StatusService,
    public usersService: UsersService,
    // public variablesMeasuredService: VariablesMeasuredService,
    public userService: UserService,
    public systemUsageService: SystemUsageReportService,
  ) {
    this.setApiUrl(this.userService?.user);
    OpenAPI.WITH_CREDENTIALS = true;
    OpenAPI.CREDENTIALS = 'include';
  }

  static getErrorText(verb: string, canRetry = true) {
    return `Failed to ${verb}, please${canRetry ? ' try again later or' : ''} contact system admin`;
  }

  setApiUrl(user: User, commonsObject?: CommonsObject, publisherName?: string) {
    OpenAPI.BASE = this.getLandingServiceUrl(user, commonsObject, publisherName);
  }

  getLandingService(user: User, commonsObject?: CommonsObject, publisherName?: string): LandingService {
    const obj = commonsObject as CommonsObjectWithJoins;
    let lsName: string = publisherName || obj?.publisher?.name || user?.institution?.name;
    if (lsName) return environment.landing_service.find(ls => ls.name === lsName);
  }

  getLandingServiceUrl(user: User, commonsObject?: CommonsObject, publisherName?: string): string {
    return this.getLandingService(user, commonsObject, publisherName)?.url;
  }

  getSystemUsageReportUrl(recordType: string) {
    this.setApiUrl(this.userService.user);
    const landingServiceUrl = this.getLandingServiceUrl(
      this.userService.user,
      undefined,
      this.userService.user?.institution?.name,
    );
    return `${landingServiceUrl}/system-usage-report/${recordType}`;
  }

  getProject(projectId: string, user: User, publisherName: string): Observable<ProjectResponse> {
    this.setApiUrl(user, null, publisherName);
    return this.projectsService.getProject(projectId).pipe(catchError(this.handleError));
  }

  /** Response will include a Location header with the URL of the new Dataset. */
  createProject(project: ProjectCreate, publisherName: string): Observable<string> {
    this.setApiUrl(this.userService.user, null, publisherName);
    return this.projectsService.createProject(project).pipe(catchError(this.handleError));
  }

  updateProject(
    projectId: string,
    newProject: ProjectPut,
    oldProject: CommonsSearchProject | ProjectResponse,
  ): Observable<any> {
    this.setApiUrl(this.userService.user, oldProject);
    return this.projectsService.updateProject(projectId, newProject).pipe(catchError(this.handleError));
  }

  patchProject(oldProject: CommonsSearchProject | ProjectResponse, fields: ProjectPatch): Observable<any> {
    this.setApiUrl(this.userService.user, oldProject);
    return this.projectsService.editProject(oldProject.id, fields).pipe(catchError(this.handleError));
  }

  deleteProject(project: CommonsSearchProject): Observable<any> {
    this.setApiUrl(this.userService.user, project);
    return this.projectsService.deleteProject(project.id, false).pipe(catchError(this.handleError));
  }

  getDataset(datasetId: string, user: User, publisherName: string): Observable<DatasetResponse> {
    this.setApiUrl(user, null, publisherName);
    return this.datasetsService.getDataset(datasetId).pipe(catchError(this.handleError));
  }

  /** Response will include a Location header with the URL of the new Dataset. */
  createDataset(dataset: DatasetCreate, publisherName: string): Observable<string> {
    this.setApiUrl(this.userService.user, null, publisherName);
    return this.datasetsService.createDataset(dataset).pipe(catchError(this.handleError));
  }

  updateDataset(id: string, oldDataset: DatasetUnion, newDataset: DatasetPut): Observable<any> {
    this.setApiUrl(this.userService.user, oldDataset);
    return this.datasetsService.updateDataset(id, newDataset).pipe(catchError(this.handleError));
  }

  patchDataset(dataset: DatasetUnion, fields: DatasetPatch): Observable<any> {
    this.setApiUrl(this.userService.user, dataset);
    const fieldsWithDatasetType = {...fields, dataset_type: dataset.dataset_type};
    return this.datasetsService
      .editDataset(this.getDatasetId(dataset), fieldsWithDatasetType)
      .pipe(catchError(this.handleError));
  }

  deleteDataset(dataset: DatasetUnion): Observable<any> {
    this.setApiUrl(this.userService.user, dataset);
    return this.datasetsService.deleteDataset(this.getDatasetId(dataset), false).pipe(catchError(this.handleError));
  }

  restoreDocument(project: ProjectUnion, document: CommonsProjectDocument, user: User): Observable<any> {
    this.setApiUrl(user, project);
    return this.projectsService
      .restoreProjectFile(this.getProjectId(project), document.fileVersion)
      .pipe(catchError(this.handleError));
  }

  deleteDocument(project: ProjectUnion, document: ProjectFileVersionResponse, user: User): Observable<any> {
    this.setApiUrl(user, project);
    return this.projectsService
      .deleteProjectFile(this.getProjectId(project), document.file_version.id, false)
      .pipe(catchError(this.handleError));
  }

  async downloadFile(url: string, filename: string, user?: User, commonsObject?: CommonsObject): Promise<void> {
    this.setApiUrl(user, commonsObject);
    let blob: Blob;
    if (user) {
      blob = await lastValueFrom(
        this.http.get(url, {
          headers: this.getRequestHeaders(user),
          responseType: 'blob',
        }),
      );
    } else {
      blob = await lastValueFrom(this.http.get(url, {responseType: 'blob'}));
    }
    fileSaver.saveAs(blob, filename);
  }

  downloadFileLocal(url: string, user?: User, download_stored_format: boolean = false): Observable<Blob> {
    const params = download_stored_format ? '?download_stored_format=True' : '';
    const fullUrl = `${url}${params}`;

    return this.http.get(fullUrl, {headers: this.getRequestHeaders(user), responseType: 'blob'});
  }

  checkDatasetFileScanComplete(user: User, dataset: DatasetResponse): Observable<boolean> {
    let v = 'latest';
    this.setApiUrl(user, dataset);
    return this.datasetsService.isScanComplete(this.getDatasetId(dataset), v).pipe(catchError(() => of(false)));
  }

  checkFileSizeLimit(user: User, commonsObject?: CommonsObject): Observable<number> {
    this.setApiUrl(user, commonsObject);
    return this.statusService.checkFileSizeLimit().pipe(catchError(this.handleError));
  }

  /**
   * checkFileScanComplete: Periodically checks for a dataset file's HIPAA scan status, until it is complete.
   * @param user
   * @param dataset
   */
  checkFileScanComplete(
    user: User,
    dataset: DatasetResponse,
  ): Observable<{scanComplete: boolean; dataset: DatasetResponse; error: any}> {
    let messageReceived = false;

    const url = `${this.getLandingServiceUrl(user, dataset)}/datasets/${dataset.id}/is-scan-complete/latest`;

    return new Observable(observer => {
      const eventSource = new EventSource(url);

      eventSource.onmessage = event => {
        messageReceived = true;
        const eventData = JSON.parse(event.data);
        if (!('is_scan_complete' in eventData)) {
          observer.next({scanComplete: false, dataset: dataset, error: true});
          eventSource.close();
        }
        if (eventData.is_scan_complete) {
          this.getDataset(dataset.id, user, dataset.publisher.name).subscribe({
            next: updatedDataset => {
              observer.next({scanComplete: true, dataset: updatedDataset, error: false});
              eventSource.close();
            },
          });
        }
      };
      eventSource.onerror = event => {
        if (messageReceived) return;
        observer.next({scanComplete: false, dataset: dataset, error: true});
        eventSource.close();
      };
      return () => eventSource.close();
    });
  }

  /**
   * Returns the list of IRB Investigators for the given project from the backend.
   * @param user
   * @param dataset
   * @param projectID
   */
  getDatasetIrbInvestigators(
    user: User,
    dataset: DatasetResponse,
    projectID?: string,
  ): Observable<IRBInvestigatorsResponse[]> {
    this.setApiUrl(user, dataset);
    return this.irbProtocolsService
      .listInvestigatorsByStudyIrbNumber(dataset.study_irb_number, projectID || null)
      .pipe(catchError(this.handleError));
  }

  isUserIrbInvestigator(dataset: DatasetUnion, user: User): Observable<boolean> {
    this.setApiUrl(user, dataset);
    return this.irbProtocolsService
      .listActiveStatusByStudyIrbNumber(dataset.study_irb_number)
      .pipe(catchError(this.handleError));
  }

  getLandingServiceUser(user: User, commonsObject?: CommonsObject): Observable<UserResponse> {
    this.setApiUrl(user, commonsObject);
    return this.usersService.getUser('me').pipe(catchError(this.handleError));
  }

  /**
   * Returns the list of IRB Study Numbers for the given user from the backend.
   * @param user
   * @param commonsObject
   */
  getUserIrbNumbers(user: User, commonsObject?: CommonsObject): Observable<IRBUserProtocolsResponse[]> {
    this.setApiUrl(user, commonsObject);
    return this.usersService.listUserIrbProtocols('me').pipe(catchError(this.handleError));
  }

  /**
   * Returns the URL of a particular file for the given project.
   * @param user
   * @param project
   * @param documentId
   */
  getProjectFileUrl(user: User, project: ProjectResponse | CommonsSearchProject, documentId: string): string {
    this.setApiUrl(user, project);
    const landingServiceUrl = this.getLandingServiceUrl(this.userService.user, project);
    return `${landingServiceUrl}/projects/${project.id}/files/${documentId}`;
  }

  /**
   * Returns the URL of the latest file version for the given dataset.
   * @param user
   * @param dataset
   * @param fileId
   */
  getDatasetFileUrl(user: User, dataset: DatasetResponse | CommonsSearchDataset, fileId?: string): string {
    this.setApiUrl(user, dataset);

    const fileVersion = !!fileId
      ? this.getDatasetFileVersion(dataset, fileId)
      : this.getLatestDatasetFileVersion(dataset);

    if (fileVersion) {
      const landingServiceUrl = this.getLandingServiceUrl(this.userService.user, dataset);
      return `${landingServiceUrl}/datasets/${dataset.id}/files/${fileVersion.id}`;
    }
  }

  /**
   * Returns the URL of the latest file version for the given dataset.
   * @param user
   * @param dataset
   * @param fileId
   */
  async getDatasetFilePresignedUrl(
    user: User,
    dataset: DatasetResponse | CommonsSearchDataset,
    fileId?: string,
  ): Promise<string> {
    this.setApiUrl(user, dataset);

    const baseUrl = this.getDatasetFileUrl(user, dataset, fileId);
    const metaUrl = `${baseUrl}/get-presigned-url`;
    const metaResponse = await (<Promise<{download_url: string}>>(
      this.http.get(metaUrl, {headers: this.getRequestHeaders(user)}).toPromise()
    ));
    return metaResponse.download_url;
  }

  /**
   * Returns the pre-signed download URL of the given project file version.
   * @param user
   * @param project
   * @param fileId
   */
  async getProjectFilePresignedUrl(
    user: User,
    project: ProjectResponse | CommonsSearchProject,
    fileId?: string,
  ): Promise<string> {
    this.setApiUrl(user, project);

    const baseUrl = this.getProjectFileUrl(user, project, fileId);
    const metaUrl = `${baseUrl}/get-presigned-url`;
    const metaResponse = await lastValueFrom(
      this.http.get<{download_url: string}>(metaUrl, {headers: this.getRequestHeaders(user)}),
    );
    return metaResponse.download_url;
  }

  getDatasetFileVersion(dataset: DatasetResponse | CommonsSearchDataset, fileId: string): FileVersionResponse {
    const dfv = dataset.dataset_file_versions.find(dfv => dfv.file_version.id === fileId);
    return dfv?.file_version;
  }

  getLatestDatasetFileVersion(dataset: DatasetResponse | CommonsSearchDataset): FileVersionResponse {
    if (dataset.dataset_file_versions.length === 0) {
      return;
    }
    return dataset.dataset_file_versions.find(dfv => dfv.is_latest_and_not_deleted)?.file_version;
  }

  deleteDatasetFile(user: User, dataset: any, fileVersionId: string): Observable<void> {
    this.setApiUrl(user, dataset);
    return this.datasetsService.deleteDatasetFile(dataset.id, fileVersionId, false).pipe(catchError(this.handleError));
  }

  /**
   * Returns the file data in CSV format for the given user's dataset from the backend.
   * @param user
   * @param dataset
   * @param progress
   */
  getDatasetFileData(
    user: User,
    dataset: DatasetResponse | CommonsSearchDataset,
    progress: EventEmitter<number>,
  ): Observable<string> {
    const file_edit_url = this.getDatasetFileUrl(user, dataset);

    if (!file_edit_url) {
      throwError(() => 'Dataset has no file_edit_url.');
    }

    const headers = this.getRequestHeaders(user);
    return this.http
      .get(file_edit_url, {
        headers,
        observe: 'events',
        responseType: 'text',
        reportProgress: true,
      })
      .pipe(
        map(event => showProgress(event, progress)),
        tap(_ => {}),
        last(), // return last (completed) message to caller
        catchError(this.handleError),
      );
  }

  /**
   * Returns the file data in CSV format for the given user's dataset from the backend.
   * @param user
   * @param dataset
   * @param data
   * @param progress
   */
  updateDatasetFileData(
    user: User,
    dataset: DatasetResponse | CommonsSearchDataset,
    data: any,
    progress: EventEmitter<number>,
  ): Observable<FileVersionResponse | string> {
    // const fileEditUrl = this.getDatasetFileUrl(user, dataset);
    //
    // if (!fileEditUrl) {
    //   return throwError('Dataset has no fileEditUrl.');
    // }

    // FIXME: Change to use this method once the OpenAPI spec is updated
    //   to include the file stream in the request body:
    //   return this.datasetsService.uploadDatasetFile(dataset.id, true);

    const url = this.getLandingServiceUrl(user, dataset);
    +`/datasets/${dataset.id}/files`;
    const headers = this.getRequestHeaders(user);
    const fileVersion = dataset.dataset_file_versions[0].file_version;
    const file = new File([data], fileVersion.file_name, {type: fileVersion.encoding_format});
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('edit', 'true');

    return this.http
      .post(url, formData, {
        headers,
        observe: 'events',
        responseType: 'text',
        reportProgress: true,
      })
      .pipe(
        map(event => showProgress(event, progress, data)),
        tap(_ => {}),
        last(), // return last (completed) message to caller
        catchError(this.handleError),
      );
  }

  /**
   * Returns true if the user can reach the landing service. Otherwise, returns false.
   * @private
   */
  getStatus(user: User, commonsObject?: CommonsObject): Observable<boolean> {
    this.setApiUrl(user, commonsObject);
    return this.statusService.checkConnectionToLandingService().pipe(
      timeout(3000),
      map((response: any) => {
        return !!response;
      }),
      catchError(error => {
        // If the request timed out, we're probably not on the VPN.
        const isOnVPN = !(error instanceof TimeoutError);
        return throwError(() => (isOnVPN ? 'Some error occurred with the VPN check.' : 'User is not on VPN.'));
      }),
      finalize(() => {}),
    );
  }

  getRequestHeaders(user: User) {
    let eppn = user ? user.eppn : 'user@virginia.edu';
    if (environment.use_header_auth) {
      OpenAPI.TOKEN = localStorage.getItem('token');
      OpenAPI.HEADERS = {Eppn: eppn, Authorization: `Bearer ${OpenAPI.TOKEN}`};
      return new HttpHeaders(OpenAPI.HEADERS);
    } else {
      return new HttpHeaders({});
    }
  }

  public handleError(error: any) {
    let message = 'Something bad happened; please try again lather.';

    console.error(error);

    if (error?.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error?.error?.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned a status code ${error?.status}, ` +
          `Code was: ${JSON.stringify(error?.error?.code) || JSON.stringify(error?.statusText)}, ` +
          `Message was: ${JSON.stringify(error?.error?.message) || JSON.stringify(error?.body?.detail)}`,
      );
      message = error?.error?.message || JSON.stringify(error?.body?.detail);
    }
    // return an observable with a user-facing error message
    // FIXME: Log all error messages to Google Analytics
    return throwError(() => error?.status + ': ' + message);
  }

  private getDatasetId(dataset: DatasetUnion): string {
    const idProperties = ['identifier', 'id', 'dataset_id'];
    for (const property of idProperties) {
      if (dataset[property]) {
        return dataset[property];
      }
    }
  }

  private getProjectId(project: ProjectUnion): string {
    const idProperties = ['identifier', 'id', 'project_id'];
    for (const property of idProperties) {
      if (project[property]) {
        return project[property];
      }
    }
  }
}
