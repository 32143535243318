import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ALL_LOGIN_SERVICES} from '@authentication/login-service';

@Component({
  selector: 'app-commons-institution-logo',
  templateUrl: './commons-institution-logo.component.html',
  styleUrls: ['./commons-institution-logo.component.scss'],
})
export class CommonsInstitutionLogoComponent {
  @Input() name: string;

  constructor(private router: Router) {}

  get loginService() {
    return ALL_LOGIN_SERVICES[this.name] || ALL_LOGIN_SERVICES['Public'];
  }

  getInstitutionLogo() {
    return this.loginService.image.replace('.svg', '-inverse.svg');
  }
}
