<div *ngIf="isDataLoaded" [@zoomTransition]="transitionClass" class="mat-typography">
  <div class="content">
    <app-breadcrumbs [navItems]="navItems"></app-breadcrumbs>
    <div fxLayout="column">
      <h1 [ngStyle]="{color: category.color}" class="mat-display-2">
        {{ category.name }}
      </h1>
      <markdown class="mat-typography">{{ category.description }}</markdown>
    </div>
    <div class="resource-buttons">
      <app-add-resource-button [category]="this.category" [user]="user"></app-add-resource-button>
    </div>
    <div *ngIf="resourcesAreLoaded" class="resources">
      <mat-tab-group
        mat-align-tabs="center"
        [ngClass]="category.parent.parent.name"
        dynamicHeight
        [ngStyle]="{backgroundColor: category.color}"
      >
        <mat-tab label="Resources/Events curated for {{ institutionName }} users ({{ numResources }})">
          <ng-template matTabContent>
            <app-resource-list
              *ngIf="numResources > 0"
              [resources]="resourceGroups.userInstitutionResources"
              [sidenavExpanded]="true"
              [currentCategory]="category"
              [showPastEvents]="showPastEvents"
              (toggleShowPastEvents)="handleShowPastEvents()"
              fxFlexFill
            ></app-resource-list>
            <div *ngIf="numResources === 0 && user" class="please-log-in">
              <h2>There are no resources available to your home institution.</h2>
            </div>
            <div *ngIf="numResources === 0 && !user && institutionId !== publicId" class="please-log-in">
              <h2>There are no resources curated for {{ institutionName }} users in this category.</h2>
            </div>
            <div *ngIf="numResources === 0 && !user && institutionId === publicId" class="please-log-in">
              <app-login-services></app-login-services>
              <p>
                Select your institution to view resources in this category that you have direct access to. To view all
                resources listed across iTHRIV institutions, select the "View all in category" tab.
              </p>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Other Resource/Events ({{ numOtherResources }})">
          <ng-template matTabContent>
            <app-resource-list
              [resources]="resourceGroups.otherResources"
              [sidenavExpanded]="true"
              [showPastEvents]="showPastEvents"
              (toggleShowPastEvents)="handleShowPastEvents()"
              fxFlexFill
            ></app-resource-list>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
