import {Component, EventEmitter, Input} from '@angular/core';
import {Router} from '@angular/router';
import {CommonsSearchProject, CommonsState} from '@shared/types/commons-types';

@Component({
  selector: 'app-commons-project-tile',
  templateUrl: './commons-project-tile.component.html',
  styleUrls: ['./commons-project-tile.component.scss'],
})
export class CommonsProjectTileComponent {
  @Input() project: CommonsSearchProject;
  @Input() projectChange: EventEmitter<CommonsState>;
  @Input() publicCommons: boolean;

  constructor(private router: Router) {}

  handleClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  async goProject() {
    const commons = this.publicCommons ? '/public_commons' : '/private_commons';
    this.router.navigate([commons, 'project', this.project.id]);
  }

  getInstitutionName() {
    return this.project.source_organization?.name || '';
  }
}
