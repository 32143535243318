<mat-card
  *ngIf="project && project.id"
  (click)="goProject()"
  [ngClass]="{project: true, private: project.is_metadata_public}"
  [hidden]="false"
  matTooltip="Research Commons Project"
>
  <div class="icon-bg">
    <mat-icon>science</mat-icon>
  </div>
  <mat-card-header fxLayout="column" fxLayoutAlign="center center">
    <h2 mat-card-title>{{ project.name }}</h2>
  </mat-card-header>
  <mat-card-content fxLayout="column" fxLayoutAlign="center center">
    <markdown [data]="project.description | truncateWords"></markdown>
  </mat-card-content>
  <app-commons-institution-logo [name]="getInstitutionName()"></app-commons-institution-logo>
</mat-card>
