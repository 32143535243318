<h2 mat-dialog-title>
  Accept iThriv Research Data Commons terms &amp; conditions?
  <span fxFlex></span>
  <button id="dismissEula" mat-dialog-close="" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</h2>
<div mat-dialog-content style="margin-top: 4em; margin-bottom: 2em">
  <p>
    By clicking "Yes" below, I agree to abide by all institutional policies regarding data classification, storage,
    sharing, and publication. This includes, but is not limited to, appropriate use of
    <a href="https://www.hhs.gov/hipaa/for-professionals/index.html" target="_blank">HIPAA-governed data</a>.
  </p>
  <p>
    I will not use this system for storage of
    <a
      href="https://www.federalregister.gov/documents/2010/11/09/2010-28360/controlled-unclassified-information"
      target="_blank"
      >CUI data</a
    >.
  </p>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
  <button id="acceptEula" (click)="onSubmit(true)" color="primary" fxFlex="calc(50% - 10px)" mat-flat-button>
    <mat-icon>check</mat-icon>
    Yes
  </button>
  <button id="rejectEula" (click)="onSubmit(false)" color="warn" fxFlex="calc(50% - 10px)" mat-flat-button>
    <mat-icon>close</mat-icon>
    No
  </button>
</div>
