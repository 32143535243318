import {Component, Input} from '@angular/core';
import {ALL_LOGIN_SERVICES, LoginService} from '@authentication/login-service';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input() showPartner = false;
  @Input() user: User;

  constructor() {}

  get defaultLoginService(): LoginService {
    return ALL_LOGIN_SERVICES.iTHRIV;
  }

  get loginService(): LoginService {
    if (this.user) {
      return ALL_LOGIN_SERVICES[this.user.institution.name];
    } else {
      return this.defaultLoginService;
    }
  }

  get partnerName(): string {
    return this.loginService.name;
  }

  get partnerLogoURL(): string {
    return this.loginService.image;
  }

  get partnerURL(): string {
    if (this.user && this.user.institution && this.user.institution.name) {
      return ALL_LOGIN_SERVICES[this.user.institution.name].websiteURL;
    } else {
      return ALL_LOGIN_SERVICES['iTHRIV'].websiteURL;
    }
  }
}
