<div class="mat-typography">
  <div *ngIf="data && data.dataset" mat-dialog-title>
    <h2 *ngIf="isHSD(data.dataset)">
      Download highly-sensitive dataset <em>{{ data.dataset.name }}</em
      >?
    </h2>
    <h2 *ngIf="!isHSD(data.dataset)">
      Download dataset <em>{{ data.dataset.name }}</em
      >?
    </h2>
    <span fxFlex></span>
    <button mat-dialog-close="" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <p *ngIf="isHSD(data.dataset)">
      By clicking "Confirm" below, I confirm that I have reviewed
      <a class="download-link" (click)="downloadDSP()">the Data Security Plan</a> for
      <strong>{{ data.dataset.name }}</strong
      >. I am saving this file to a location listed on my DSP and approved for Highly Sensitive Data.
    </p>
    <p *ngIf="!isHSD(data.dataset)">
      By clicking "Confirm" below, I confirm that this file for <strong>{{ data.dataset.name }}</strong> does NOT
      contain any highly sensitive data. I will save this file to an appropriate location as defined by institutional
      policy.
    </p>
  </div>
  <div mat-dialog-actions>
    <div class="select-mode" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <button (click)="onSubmit()" color="primary" mat-flat-button>
        <mat-icon>check</mat-icon>
        Confirm
      </button>
      <button (click)="onNoClick()" color="warn" mat-flat-button>
        <mat-icon>close</mat-icon>
        Cancel
      </button>
    </div>
  </div>
</div>
