<div class="container" *ngIf="isDataLoaded && level0Categories">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    [attr.viewBox]="viewBoxDimensions()"
    class="mat-typography"
  >
    <defs>
      <mask [id]="'circleMask' + navRadius">
        <circle [attr.cx]="0" [attr.cy]="0" [attr.r]="navRadius - strokeWidth * 1.5" fill="white"></circle>
      </mask>
      <mask [id]="'circleMask' + baseRadius">
        <circle [attr.cx]="0" [attr.cy]="0" [attr.r]="baseRadius - strokeWidth * 1.5" fill="white"></circle>
      </mask>
    </defs>

    <!-- Top Level Category Menu -->
    <svg:g [ngClass]="'level-0-menu '">
      <svg:g
        *ngFor="let level0Category of level0Categories; let i = index"
        [ngClass]="'level-0-menu-node'"
        [@menuState]="{value: getMenuState(level0Category), params: getMenuPosition(level0Category)}"
        app-node
        [state]="getState(level0Category)"
        (click)="selectCategory(level0Category)"
        [category]="level0Category"
        [selectedCategory]="selectedCategory"
        [isSelected]="getMenuState(level0Category) === 'selected'"
        [options]="
          makeNodeOptions({
            relationship: 'root',
            x: 0,
            y: 0,
            radius: navRadius,
            angle: 0,
            titleHeight: parentTitleHeight
          })
        "
      ></svg:g>
    </svg:g>

    <!-- Draw the lines first, so they show up underneath. -->
    <svg:g
      *ngFor="let level0Category of level0Categories"
      [@rootState]="{value: getRootState(level0Category), params: getRootShift(level0Category)}"
      [ngClass]="'level-0'"
    >
      <svg:line
        *ngFor="let level1Category of level0Category.children; let k = index"
        [ngClass]="'level-1-line'"
        [@rootLineState]="getRootState(level0Category)"
        [attr.stroke]="categoryColor(level0Category.color)"
        [attr.transform]="'scale(0)'"
        [attr.x1]="0"
        [attr.y1]="0"
        [attr.x2]="getCatPos(level1Category, false).x"
        [attr.y2]="getCatPos(level1Category, false).y"
      />

      <!-- LEVEL 1 -->
      <svg:g
        *ngFor="let level1Category of level0Category.children; let k = index"
        [ngClass]="'level-1'"
        [@childState]="{value: getState(level1Category), params: getCatPos(level1Category)}"
      >
        <svg:line
          *ngFor="let level2Category of level1Category.children; let k = index"
          [ngClass]="'level-2-line'"
          [@lineState]="getState(level1Category)"
          [attr.stroke]="categoryColor(level0Category.color)"
          [attr.transform]="'scale(0)'"
          [attr.x1]="0"
          [attr.y1]="0"
          [attr.x2]="getCatPos(level2Category, false).x"
          [attr.y2]="getCatPos(level2Category, false).y"
        />

        <!-- LEVEL 2 -->
        <svg:g
          *ngFor="let level2Category of level1Category.children; let k = index"
          [ngClass]="'level-2-node'"
          [@grandchildState]="{value: getState(level2Category), params: getCatPos(level2Category)}"
          app-node
          [category]="level2Category"
          [state]="getState(level2Category)"
          (click)="selectCategory(level2Category)"
          [options]="
            makeNodeOptions({
              relationship: 'child',
              x: 0,
              y: 0,
              radius: baseRadius,
              angle: 0,
              titleHeight: 40
            })
          "
          [selectedCategory]="selectedCategory"
          [matTooltip]="getTooltipText(level2Category)"
        />

        <!-- LEVEL 1 -->
        <svg:g
          [ngClass]="'level-1-node'"
          app-node
          [category]="level1Category"
          [state]="getState(level1Category)"
          (click)="selectCategory(level1Category)"
          [options]="
            makeNodeOptions({
              relationship: 'child',
              x: 0,
              y: 0,
              radius: baseRadius,
              angle: 0,
              titleHeight: 40
            })
          "
          [selectedCategory]="selectedCategory"
          [matTooltip]="getTooltipText(level1Category)"
        />
      </svg:g>

      <!-- LEVEL 0 -->
      <svg:g
        [ngClass]="'level-0-node'"
        app-node
        [category]="level0Category"
        (click)="selectCategory(level0Category)"
        [state]="getState(level0Category)"
        [options]="
          makeNodeOptions({
            relationship: 'root',
            x: 0,
            y: 0,
            radius: baseRadius,
            angle: 0,
            titleHeight: 40
          })
        "
        [selectedCategory]="selectedCategory"
        [isSelected]="selectedCategory.id === level0Category.id"
        [matTooltip]="getTooltipText(level0Category)"
      ></svg:g>
    </svg:g>
    -->
  </svg>
</div>
