import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-comma-delimited-list-item',
  templateUrl: './comma-delimited-list-item.component.html',
  styleUrls: ['./comma-delimited-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommaDelimitedListItemComponent {
  @Input() field: string | string[];
  @Input() title: string;
  @Input() displayType: 'string' | 'link' | 'chip';

  constructor() {}

  get fieldList(): string[] {
    if (Array.isArray(this.field)) {
      return this.field;
    } else if (typeof this.field === 'string') {
      return this.field.split(',');
    } else {
      return [];
    }
  }

  fieldHasValue(field: string | string[]) {
    if (typeof field === 'string') {
      return field !== '';
    } else if (Array.isArray(field)) {
      return field.length > 0 && field.some(Boolean);
    } else return false;
  }

  pluralize(title: string) {
    const s = this.fieldList.length === 1 ? '' : 's';
    return title + s;
  }
}
