<mat-card [ngClass]="'view'">
  <mat-card-header>
    <mat-card-title> Documents </mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="user">
    <mat-list *ngIf="hasDocuments()">
      <table [dataSource]="this.documentsDataSource" class="documents" aria-describedby="Documents" mat-table>
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell>NAME</th>
          <td *matCellDef="let doc" mat-cell>
            {{ toTypedDocument(doc).file_version.file_name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th *matHeaderCellDef mat-header-cell>TYPE</th>
          <td *matCellDef="let doc" mat-cell>
            {{ projectFileCategoryLabels[toTypedDocument(doc).category] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="url">
          <th *matHeaderCellDef mat-header-cell>DOWNLOAD</th>
          <td *matCellDef="let doc" mat-cell>
            <a (click)="downloadProjectDocument(doc, user)" *ngIf="canDownloadDocument(doc)">
              <button color="primary" mat-mini-fab type="button">
                <mat-icon
                  matTooltip="Click here to download document ({{ toTypedDocument(doc).file_version.file_name }})"
                >
                  file_download
                </mat-icon>
              </button>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="update">
          <th *matHeaderCellDef mat-header-cell>UPDATE</th>
          <td *matCellDef="let doc" mat-cell>
            <button
              (click)="updateDocument(toTypedDocument(doc).category)"
              *ngIf="canUpdateDocument()"
              color="warn"
              mat-mini-fab
              type="button"
            >
              <mat-icon>update</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th *matHeaderCellDef mat-header-cell>DELETE</th>
          <td *matCellDef="let doc" mat-cell>
            <button (click)="deleteDocument(doc)" color="warn" mat-mini-fab type="button">
              <mat-icon [matTooltip]="getDeleteButtonTooltip(doc)">delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="restore">
          <th *matHeaderCellDef mat-header-cell>RESTORE</th>
          <td *matCellDef="let doc" mat-cell>
            <button
              (click)="restoreDocument(doc)"
              *ngIf="canRestoreDocument(doc)"
              color="warn"
              mat-mini-fab
              type="button"
            >
              <mat-icon matTooltip="Click here to restore document ({{ toTypedDocument(doc).file_version.file_name }})">
                undo</mat-icon
              >
            </button>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedDocumentColumns" mat-header-row></tr>
        <tr *matRowDef="let row; let odd = odd; columns: displayedDocumentColumns" [ngClass]="{gray: odd}" mat-row></tr>
      </table>
    </mat-list>
  </mat-card-content>
  <mat-card-actions>
    <button (click)="addDocument()" *ngIf="canAddDocument()" color="primary" mat-raised-button type="button">
      <mat-icon>add</mat-icon>
      ADD DOCUMENT
    </button>
  </mat-card-actions>
</mat-card>
