import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {getPrevUrl} from '@utilities/prev-url';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';

@Component({
  selector: 'app-session-redirect',
  templateUrl: 'session-redirect.component.html',
  styleUrls: ['./session-redirect.component.scss'],
})
export class SessionRedirectComponent {
  // Accepts a token from the server, then redirects the user
  // to the home page.  This allows single sign on through
  // Shibboleth.

  constructor(
    private api: ResourceApiService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {
    this.route.params.subscribe(async params => {
      if (params.hasOwnProperty('token')) {
        const token = params['token'];

        if (token) {
          localStorage.setItem('token', token);
          await this.userService.refresh();
          await this.goPrevUrl();
        }
      }
    });
  }

  async goPrevUrl() {
    const prevUrl = getPrevUrl();
    await this.router.navigateByUrl(prevUrl);
    localStorage.removeItem('prev_url');
  }
}
