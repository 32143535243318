import {AbstractControl, ValidationErrors} from '@angular/forms';
import {DatasetType} from '@services/landing-service';

export const GENERIC_FIELDS = [
  'approved_irb_id',
  'associated_projects',
  'attribution',
  'audited',
  'contract_id',
  'data_aggregation_id',
  'dataset_type',
  'description',
  'doi',
  'dsp_id',
  'identifiers_hipaa',
  'institutional_access',
  'is_data_public',
  'is_metadata_public',
  'keywords',
  'license',
  'link_to_external_dataset',
  'name',
  'original_datasets',
  'other_sensitive_data',
  'schedule_for_deletion',
  'source_organization',
  'spatial_coverage',
  'study_irb_number',
  'temporal_coverage',
  'users',
  'variables_measured',
];

export const REDCAP_FIELDS = [
  'redcap_project_url',
  'redcap_project_token',
  'redcap_report_id',
  'is_data_dictionary',
  'redcap_extract_data',
  'redcap_project_title',
  'redcap_project_pi',
  'extract_options',
];

export const DICOM_FIELDS = [
  'acquisition_date',
  'angio_flag',
  'bids_structure',
  'field_of_view',
  'field_strength',
  'institution_name',
  'largest_image_pixel_value',
  'modality',
  'mr_acquisition_type',
  'organ_name',
  'patient_sex',
  'protocol_name',
  'quality',
  'scanner_manufacturer_name',
  'scanner_model_name',
  'scanning_sequence',
  'sequence_name',
  'series_description',
  'slice_location',
  'slice_thickness',
  'software_versions',
  'study_date',
];

/**
 * Changes the visibility of fields based on the dataset type.
 * @param control
 */
export const ValidateDatasetType = (control: AbstractControl): null | ValidationErrors => {
  const formGroup = control.parent;
  const formControl = formGroup?.controls['dataset_type'];
  const datasetType = formControl?.value as DatasetType;
  return datasetType ? null : {required: true};
};
