<app-commons-menu *ngIf="isLoaded()" [navItems]="navItems" [title]="projectName()">
  <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</app-commons-menu>

<ng-template #pageContent>
  <div *ngIf="error" class="display mat-error" id="delete_error_message">
    <app-error-message [errorString]="error"></app-error-message>
  </div>
  <div *ngIf="userService.user && newProject" fxLayout="column" fxLayoutGap="10px">
    <mat-card *ngIf="userService.user" ngClass="create-edit">
      <mat-card-content *ngIf="userService.user">
        <mat-card-header>
          <h2 mat-card-title>Project Details</h2>
          <span fxFlex></span>
        </mat-card-header>
        <form *ngIf="formStatus === 'form' && formContainer" [formGroup]="fg">
          <app-form-field
            *ngFor="let field of getFields()"
            [errorMatcher]="errorMatcher"
            [errors]="field.formControl ? field.formControl.errors : field.formGroup.errors"
            [field]="field"
            [formGroup]="fg"
          ></app-form-field>
          <footer *ngIf="formStatus === 'form'">
            <div *ngIf="errorMessage" class="display mat-error" id="create_error_message">
              <app-error-message [errorString]="errorMessage"></app-error-message>
            </div>
            <span class="ithriv-spacer"></span>
            <button (click)="submitProject($event)" color="primary" mat-raised-button type="button">
              <mat-icon>save_alt</mat-icon>
              Save
            </button>
            <button (click)="cancelProject()" mat-button type="button">Cancel</button>
          </footer>
        </form>

        <ng-container *ngIf="formStatus !== 'form'">
          <div *ngIf="formStatus === 'submitting'" fxLayout="column" fxLayoutAlign="space-around center">
            <mat-spinner [diameter]="48"></mat-spinner>
            <button (click)="cancelProject()" mat-button type="button">Cancel</button>
          </div>
          <div *ngIf="formStatus === 'complete'">
            <p>
              <b>Commons project {{ newProject.name }} successfully created. </b>
            </p>
            <button (click)="showForm()" color="primary" mat-raised-button type="button">
              <mat-icon>help</mat-icon>
              Create another project
            </button>
            <button (click)="cancelProject()" color="primary" mat-button type="button">Continue</button>
          </div>
        </ng-container>
      </mat-card-content>
    </mat-card>
    <mat-divider [inset]="true"></mat-divider>
    <mat-card
      *ngIf="
        userService.user &&
        formStatus === 'form' &&
        projectResponse?.id !== '' &&
        projectSearchObject?._can_manage_permission === true
      "
      ngClass="create-edit"
    >
      <mat-card-content *ngIf="userService.user && userPermissions">
        <mat-card-header>
          <mat-card-title id="permissionsTableTitle"> Project Permissions </mat-card-title>
          <mat-card-subtitle>
            <markdown>{{ emailFormatsText }}</markdown>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-list dense>
          <table [dataSource]="userPermissions" class="projectperms" mat-table aria-describedby="permissionsTableTitle">
            <ng-container matColumnDef="email">
              <th *matHeaderCellDef mat-header-cell>EMAIL</th>
              <td *matCellDef="let up" mat-cell>
                <mat-label>
                  {{ toTypeUp(up).user.email }}
                </mat-label>
              </td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th *matHeaderCellDef mat-header-cell>ROLE</th>
              <td *matCellDef="let up" mat-cell [matTooltip]="getRoleTooltip(lookupRole(toTypeUp(up).project_role_id))">
                {{ lookupRole(toTypeUp(up).project_role_id) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="edit">
              <th *matHeaderCellDef mat-header-cell>EDIT</th>
              <td *matCellDef="let up" mat-cell>
                <button
                  (click)="editPermission(toTypeUp(up))"
                  color="accent"
                  mat-mini-fab
                  type="button"
                  *ngIf="toTypeUp(up).user.email !== userService.user.email"
                >
                  <mat-icon matTooltip="Click here to edit project user permission"> edit </mat-icon>
                </button>
                <button
                  (click)="displaySelfError('edit')"
                  color="disabled"
                  mat-mini-fab
                  type="button"
                  *ngIf="toTypeUp(up).user.email === userService.user.email"
                >
                  <mat-icon matTooltip="Can't edit your own permission"> edit </mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th *matHeaderCellDef mat-header-cell>DELETE</th>
              <td *matCellDef="let up" mat-cell>
                <button
                  (click)="deletePermission(toTypeUp(up))"
                  color="primary"
                  mat-mini-fab
                  type="button"
                  *ngIf="toTypeUp(up).user.email !== userService.user.email"
                >
                  <mat-icon matTooltip="Click here to delete user's project permission"> delete </mat-icon>
                </button>
                <button
                  (click)="displaySelfError('delete')"
                  color="disabled"
                  mat-mini-fab
                  type="button"
                  *ngIf="toTypeUp(up).user.email === userService.user.email"
                >
                  <mat-icon matTooltip="Can't disavow your own permission"> delete </mat-icon>
                </button>
              </td>
            </ng-container>
            <tr *matHeaderRowDef="displayedUserPermissionColumns" mat-header-row></tr>
            <tr
              *matRowDef="let row; let odd = odd; columns: displayedUserPermissionColumns"
              [ngClass]="{gray: odd}"
              mat-row
            ></tr>
          </table>
        </mat-list>
        <div *ngIf="errorMessagePerm" class="display mat-error" id="perm_error_message">
          <app-error-message [errorString]="errorMessagePerm"></app-error-message>
        </div>
      </mat-card-content>
      <mat-card-actions id="project_permissions">
        <button (click)="addPermission()" color="primary" mat-raised-button>
          <mat-icon>security</mat-icon>
          ADD PERMISSION
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>
