<h1 mat-dialog-title>Project document upload</h1>
<mat-list mat-dialog-content>
  <mat-form-field>
    <mat-label>Document Type</mat-label>
    <mat-select [formControl]="documentTypeControl" required>
      <mat-option *ngFor="let option of documentTypeMap" [value]="option.key">{{ option.value }}</mat-option>
    </mat-select>
    <mat-error *ngIf="documentTypeControl.hasError('required')">Document type not selected</mat-error>
    <mat-hint>Document type select</mat-hint>
  </mat-form-field>
</mat-list>
<mat-list mat-dialog-actions>
  <ng-container>
    <app-commons-file-upload
      matTooltip="Click here to upload project document"
      color="primary"
      text="UPLOAD"
      (uploadComplete)="onFileComplete()"
      [target]="uploadUrlDocument()"
      [user]="projectDocumentMap.user"
    ></app-commons-file-upload>
  </ng-container>
  <button mat-button (click)="onNoClick()"><mat-icon>cancel</mat-icon>Cancel</button>
</mat-list>
