const oui = (Math.floor(Math.random() * 2 ** 47) * 2 + 1).toString(16).padStart(12, '0'); // fake "network card" for UUID OID
const clock_sequence = Math.floor(Math.random() * 2 ** 12)
  .toString(16)
  .padStart(3, '0'); // random "clock domain"
let timestamp_sequence = 0;
const TIMESTAMP_OFFSET = -new Date(1582, 9, 15, 0, 0, 0).getTime(); // Oct 15 1582, adoption of Gregorian calendar and UUID epoch

export default function uuid(): string {
  try {
    return new Crypto().randomUUID();
  } catch {
    // Version 6 UUID uses a timestamp and "network card" to make a unique point in space and time
    const now = (Date.now() + TIMESTAMP_OFFSET) * 2.44140625; // Upconvert to 100ns and simultaneously lop off bottom 12 bits
    const now_str = Math.floor(now).toString(16).padStart(12, '0');
    const ts_seq_str = (timestamp_sequence++).toString(16).padStart(3, '0');
    if (timestamp_sequence === 4096) timestamp_sequence = 0;
    return `${now_str.slice(0, 8)}-${now_str.slice(8, 12)}-6${ts_seq_str}-8${clock_sequence}-${oui}`;
  }
}
