<div class="mat-typography">
  <div mat-dialog-content>
    <p *ngIf="data.project">
      I confirm the Project Details text fields I have completed do not contain Personally Identifiable Information
      (PII), such as patient dates of service.
    </p>
    <p *ngIf="data.dataset && !data.hsd">
      I confirm the Dataset Details text fields I have completed do not contain Personally Identifiable Information
      (PII), such as patient dates of service.
    </p>
    <p *ngIf="data.dataset && data.hsd">
      I confirm the Dataset Details text fields I have completed do not contain Personally Identifiable Information
      (PII), such as patient dates of service. I understand this type of data should be limited to the associated data
      files I upload.
    </p>
  </div>
  <div mat-dialog-actions>
    <div class="select-mode" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <button (click)="onSubmit()" color="primary" mat-flat-button>
        <mat-icon>check</mat-icon>
        Confirm
      </button>
      <button (click)="onNoClick()" color="warn" mat-flat-button>
        <mat-icon>close</mat-icon>
        Cancel
      </button>
    </div>
  </div>
</div>
