import {Component, Input} from '@angular/core';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {Resource} from '@shared/types/resource';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-approved-badge',
  templateUrl: './approved-badge.component.html',
  styleUrls: ['./approved-badge.component.scss'],
})
export class ApprovedBadgeComponent {
  @Input() resource: Resource;
  @Input() user: User;

  constructor(private api: ResourceApiService) {}

  handleClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.resource.approved === 'Approved') {
      this.resource.approved = 'Unapproved';
      this.api.updateResource(this.resource).subscribe(r => {
        this.resource = r;
      });
    } else {
      this.resource.approved = 'Approved';
      this.api.updateResource(this.resource).subscribe(r => {
        this.resource = r;
      });
    }
  }

  instructions(): string {
    const what = this.resource.approved === 'Approved' ? 'Unapproved' : 'Approved';
    return (
      `Mark this resource as ${what}. Unapproved resources are only visible to the resource owners ` +
      `and system administrators. Once a resource page is approved, it appears to users in the system. ` +
      `Edits to an approved resource immediately update the live content. A system administrator can return ` +
      `an approved resource to unapproved if needed while changes are underway.`
    );
  }
}
