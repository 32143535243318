// Autogenerated by util/sync-labels/sync-labels.ts
// Do not edit this file directly.
// To regenerate this file, start ithriv_landing_service locally on port 8000 and
// run the following command in the root directory of the ithriv_web repository:
// > npm run sync_labels

export enum IdentifierHipaa {
  NA = 'na',
  NONE = 'none',
  ZIP_SHORT = 'zip-short',
  REGION = 'region',
  DATES_YEAR = 'dates-year',
  DATES_SHIFTED = 'dates-shifted',
  DATES_FULL = 'dates-full',
  AGES = 'ages',
  CITY_ZIP = 'city-zip',
  MEDICAL_RECORD_NUM = 'medical-record-num',
  NAMES = 'names',
  ADDRESSES = 'addresses',
  EMAIL_PHONE_FAX = 'email-phone-fax',
  SOCIAL_SECURITY = 'social-security',
  BIOMETRIC_ID = 'biometric-id',
  CERTIFICATE_NUM = 'certificate-num',
  VEHICLE_ID = 'vehicle-id',
  DEVICE_ID = 'device-id',
  OTHER_UNIQUE = 'other-unique',
}

export const IdentifierHipaaLabels = {
  na: 'N/A: Not derived from Medical Records',
  none: 'NONE',
  'zip-short': 'First 3 digits of ZIP and if population <20K ZIP changed to 000',
  region: 'STATE or larger regions',
  'dates-year': 'DATES using only YEAR and NO AGES > 90',
  'dates-shifted': 'Random Date Shifts Applied at Row Level',
  'dates-full': 'DATES with MONTH/DAY/YEAR',
  ages: 'AGES > 90 are reflected in data',
  'city-zip': 'CITY, ZIP, or equivalent GEOCODES',
  'medical-record-num': 'MEDICAL RECORD # or ACCOUNT # or HEALTH PLAN BENEFICIARIES',
  names: 'NAMES, INITIALS, FIRST 3 LETTERS of LAST NAME',
  addresses: 'ADDRESSES and equivalent GEOCODES',
  'email-phone-fax': 'EMAIL or PHONE or FAX #',
  'social-security': 'SOCIAL SECURITY # or last 4 digits',
  'biometric-id': 'BIOMETRIC IDs or FINGER PRINTS or VOICE RECORDINGS or FULL FACE IMAGES',
  'certificate-num': 'CERTIFICATE # or LICENSE #',
  'vehicle-id': 'VEHICLE ID, SN, or Plate #',
  'device-id': 'DEVICE ID or SN or IP address; Web URLs',
  'other-unique': 'OTHER UNIQUE IDENTIFIERS',
} as const;

export type IdentifierHipaaId = keyof typeof IdentifierHipaaLabels;
export type IdentifierHipaaLabel = (typeof IdentifierHipaaLabels)[IdentifierHipaaId];
