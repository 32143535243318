import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {getLoginServices, LoginService} from '@authentication/login-service';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-login-services',
  templateUrl: './login-services.component.html',
  styleUrls: ['./login-services.component.scss'],
})
export class LoginServicesComponent {
  @Input() hidePublic: boolean;
  @Input() prevUrl?: string;
  loginServices: LoginService[] = [];
  selectedTabIndex = 0;

  dataLoaded = false;

  constructor(
    private api: ResourceApiService,
    public router: Router,
    private route: ActivatedRoute,
  ) {
    this.selectedTabIndex = this.route.routeConfig && this.route.routeConfig.path === 'register' ? 1 : 0;
    this.loadServices();
  }

  async goNetworkBrowse() {
    const viewPrefs = this.api.getViewPreferences();
    const isNetworkView = viewPrefs && viewPrefs.hasOwnProperty('isNetworkView') ? viewPrefs.isNetworkView : true;

    if (isNetworkView) {
      await this.router.navigate(['network']);
    } else {
      await this.router.navigate(['browse']);
    }
  }

  async loadServices() {
    const institutions = await lastValueFrom(this.api.getInstitutions());
    this.loginServices = getLoginServices(institutions, this.hidePublic);
    this.dataLoaded = this.loginServices.length > 0;
  }

  async goLogin(s: LoginService) {
    await s.goLogin(this.router);
  }

  // Changes the tooltip text from 'Public' to Other'
  getTooltipText(name: string) {
    return name === 'Public' ? 'Other' : name;
  }
}
