import {ProjectCreate, ProjectPut, ProjectResponse, ProjectRoleID} from '@services/landing-service';
import {User} from '@shared/types/user';

// Return an object that implements ProjectResponse type
export const getBlankProject = (user: User): ProjectCreate => {
  return {
    // Required fields
    name: '',
    description: '',
    is_metadata_public: false,
    keywords: [''],
    users: [
      {
        email: user.email,
        role: ProjectRoleID.OWNER,
      },
    ],
    source_organization: {name: user.institution.name},

    // Optional fields
    alternate_name: '',
    web_page_urls: [],
    portal_resources_urls: [],
    funders: [],
    partners: [],
  };
};

export const makeProjectPutFromProjectResponse = (user: User, p?: ProjectResponse): ProjectCreate | ProjectPut => {
  if (!p) {
    return getBlankProject(user) as ProjectCreate;
  }

  return {
    // Required fields
    name: p?.name || '',
    description: p?.description || '',
    is_metadata_public: p?.is_metadata_public || false,
    keywords: p?.keywords?.map(k => (typeof k === 'string' ? k : k.text)) || [],
    users:
      p?.project_users?.map(u => ({
        email: u.user.email,
        role: u.project_role_id as ProjectRoleID,
        is_project_pi: u.is_project_pi,
      })) || [],
    source_organization: p?.source_organization?.name
      ? {name: p?.source_organization?.name}
      : {name: user.institution.name},

    // Optional fields
    alternate_name: p?.alternate_name || undefined,
    web_page_urls: p?.web_page_urls?.map(w => (typeof w === 'string' ? w : w.url)) || undefined,
    portal_resources_urls: p?.portal_resources_urls?.map(p => (typeof p === 'string' ? p : p.url)) || undefined,
    funders: p?.funders?.map(f => ({name: f.name})) || undefined,
    partners: p?.partners?.map(p => ({name: p.name})) || undefined,
  };
};
