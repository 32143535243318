<app-breadcrumbs *ngIf="navItems" [navItems]="navItems"></app-breadcrumbs>
<div
  *ngIf="resources"
  fxLayout="row wrap"
  fxLayout.xs="column"
  fxLayoutAlign="start stretch"
  fxLayoutAlign.xs="start start"
  fxLayoutGap="20px grid"
  [ngClass]="{
    'resource-list': true,
    'resource-list-narrow': sidenavExpanded
  }"
>
  <div fxFlex="100%" fxFlexFill fxLayout="row">
    <div fxLayoutAlign="start end" fxFlex="start" *ngIf="!hideEventsFilter">
      <mat-checkbox id="pastEvents" (change)="includePastEvents($event)" [checked]="showPastEvents">
        <h4>Include Past Events</h4>
      </mat-checkbox>
    </div>
    <div *ngIf="user && showUserActions" fxLayoutAlign="end end" fxFlex="end">
      <div *ngIf="!csvDataReady && user.role === 'Admin'">
        <button mat-raised-button (click)="getCsvData()" *ngIf="!preparingCsvData">
          <mat-icon>save_alt</mat-icon>
          Download this list as a CSV file
        </button>
        <button *ngIf="preparingCsvData" mat-raised-button disabled class="preparingCsvData">
          <mat-spinner [diameter]="24"></mat-spinner>
          Generating CSV file...
        </button>
      </div>
      <div *ngIf="csvDataReady">
        <app-csv-export-button [data]="csvData" [filename]="csvFilename" [options]="csvOptions"></app-csv-export-button>
      </div>
    </div>
  </div>
  <app-resource-tile
    *ngFor="let resource of resources"
    class="resource-tile-container"
    [fxFlex.xl]="sidenavExpanded ? '1 1 25%' : '1 1 20%'"
    [fxFlex.lg]="sidenavExpanded ? '1 1 33%' : '1 1 25%'"
    [fxFlex.md]="sidenavExpanded ? '1 1 50%' : '1 1 33%'"
    [fxFlex.sm]="sidenavExpanded ? '1 1 100%' : '1 1 50%'"
    [fxFlex.xs]="sidenavExpanded ? '1 1 100%' : '1 1 100%'"
    fxFlexFill
    [resource]="resource"
    id="resource-tile-{{ resource.id }}"
  ></app-resource-tile>
</div>
