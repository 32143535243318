<div
  class="container mat-typography"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutGap="10px"
  fxLayoutAlign="left left"
>
  <div fxFlex="100%">
    <mat-card>
      <mat-card-content *ngIf="user">
        <p>
          Completing this web form will send a concierge services request which will be routed to the best specialist to
          address your need. We aim to respond within two business days.
        </p>
        <form [formGroup]="requestConsultForm" (ngSubmit)="onSubmit()" *ngIf="formStatus === 'form'">
          <app-form-field
            *ngFor="let field of getFields()"
            [errors]="field.formControl ? field.formControl.errors : field.formGroup.errors"
            [field]="field"
            [formGroup]="requestConsultForm"
            [errorMatcher]="errorMatcher"
          ></app-form-field>

          <div id="error_message" class="display mat-error" *ngIf="errorMessage">
            {{ errorMessage }}
          </div>

          <footer *ngIf="formStatus === 'form'">
            <button type="button" color="primary" mat-raised-button (click)="onSubmit()">Submit</button>
            <button type="button" (click)="onCancel()" mat-button>Cancel</button>
          </footer>
        </form>
        <div *ngIf="formStatus === 'submitting'" fxLayout="column" fxLayoutAlign="space-around center">
          <mat-spinner [diameter]="48"></mat-spinner>
          <button type="button" (click)="onCancel()" mat-button>Cancel</button>
        </div>

        <div *ngIf="formStatus === 'complete'">
          <p>
            <b>Thank you for submitting your Consult Request. We aim to respond within two business days. </b>
          </p>
          <button type="button" color="primary" mat-raised-button (click)="showConsultRequestForm()">
            <mat-icon>help</mat-icon>
            Request another consult
          </button>
          <button type="button" color="primary" mat-button (click)="onCancel()">Continue</button>
        </div>
      </mat-card-content>
      <mat-divider [inset]="true"></mat-divider>
      <mat-divider [inset]="true"></mat-divider>
      <br />
    </mat-card>
  </div>
</div>
