import {cloneDeep} from 'lodash-es';
import {CommonsStateForm} from '@shared/types/commons-types';
import {NavItem} from '@shared/types/nav-item';

export const navItemMap: {[key in CommonsStateForm]: NavItem} = {
  'commons-private': {
    title: 'Private Data Commons',
    routerLink: '/home',
    queryParams: {tabIndex: 'private_commons'},
  },
  'commons-public': {
    title: 'Public Data Commons',
    routerLink: '/home',
    queryParams: {tabIndex: 'public_commons'},
  },
  'commons-project-private': {
    title: 'Project Home',
    routerLink: '/private_commons/project/:project_id',
  },
  'commons-project-public': {
    title: 'Project Home',
    routerLink: '/public_commons/project/:project_id',
  },
  'commons-project-create-edit': {
    title: 'Edit Project',
    routerLink: '/private_commons/project/:project_id/edit',
  },
  'commons-dataset-private': {
    title: 'View Dataset',
    routerLink: '/private_commons/project/:project_id/dataset/:dataset_id',
  },
  'commons-dataset-public': {
    title: 'View Dataset',
    routerLink: '/public_commons/project/:project_id/dataset/:dataset_id',
  },
  'commons-dataset-create-edit': {
    title: 'Edit Dataset Details',
    routerLink: '/private_commons/project/:project_id/dataset/:dataset_id/edit',
  },
  'commons-dataset-spreadsheet': {
    title: 'Edit Dataset Data',
    routerLink: '/private_commons/project/:project_id/dataset/:dataset_id/spreadsheet',
  },
};

export const getNavItems = (keys: CommonsStateForm[], projectId?: string, datasetId?: string): NavItem[] => {
  return keys.map(k => {
    const navItem = cloneDeep(navItemMap[k]);
    if (projectId) {
      navItem.routerLink = (navItem.routerLink as string).replace(':project_id', projectId);
    }

    if (datasetId) {
      navItem.routerLink = (navItem.routerLink as string).replace(':dataset_id', datasetId);
    }
    return navItem;
  });
};
