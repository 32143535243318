import {Component} from '@angular/core';

@Component({
  selector: 'app-upgrade-browser',
  templateUrl: './upgrade-browser.component.html',
  styleUrls: ['./upgrade-browser.component.scss'],
})
export class UpgradeBrowserComponent {
  constructor() {}
}
