<ng-container *ngIf="showDatasets()">
  <div
    class="resource-list"
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    fxLayoutAlign.xs="start start"
    fxLayoutGap="20px grid"
  >
    <div
      *ngIf="user && user.role === 'Admin' && showCsvDownload"
      fxFlex="100%"
      fxFlexFill
      fxLayout="row"
      fxLayoutAlign="end end"
    >
      <button mat-raised-button (click)="getSystemUsageReport('datasets')">
        <mat-icon>save_alt</mat-icon>
        Download this list as a CSV file
      </button>
    </div>
    <app-commons-dataset-tile
      *ngFor="let dataset of datasets"
      [dataset]="dataset"
      [publicCommons]="this.publicCommons"
      class="resource-tile-container"
      fxFlex.lg="1 1 33%"
      fxFlex.md="1 1 50%"
      fxFlex.sm="1 1 100%"
      fxFlex.xl="1 1 20%"
      fxFlex.xs="1 1 100%"
      fxFlexFill
      id="dataset-tile-{{ dataset.id }}"
    ></app-commons-dataset-tile>
  </div>
</ng-container>
