import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {Category} from '@shared/types/category';

export const getCategoryTooltipText = (category: Category): string => {
  const count = category.resource_count || 0;
  const s = count === 1 ? '' : 's';
  const countString = !category.resource_count ? '' : `\n(${count} resource${s}/event${s})`;
  return category.name + ':\n' + (category.brief_description || category.description) + countString;
};

export const getcategoryRoute = (category: Category, api: ResourceApiService): any[] => {
  if (category.level === 2) {
    return ['/category', category.id];
  } else if (api.getViewPreferences().isNetworkView) {
    return ['/network', category.id];
  } else {
    const id = category.level === 1 ? category.parent_id : category.id;
    return ['/browse', id];
  }
};
