import {Component, EventEmitter, Input} from '@angular/core';
import {Router} from '@angular/router';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {UserService} from '@services/user-service/user.service';
import {isHSD as _isHSD, isLocked as _isLocked} from '@utilities/commons-dataset-util';
import {CommonsSearchDataset, CommonsState} from '@shared/types/commons-types';

@Component({
  selector: 'app-commons-dataset-tile',
  templateUrl: './commons-dataset-tile.component.html',
  styleUrls: ['./commons-dataset-tile.component.scss'],
})
export class CommonsDatasetTileComponent {
  @Input() dataset: CommonsSearchDataset;
  @Input() datasetChange: EventEmitter<CommonsState>;
  @Input() publicCommons: boolean = true;
  isLocked = _isLocked;
  isHSD = _isHSD;

  constructor(
    private router: Router,
    private userService: UserService,
    private cas: CommonsApiService,
  ) {}

  handleClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  editDatasetData() {
    this.router.navigate([
      '/private_commons',
      'project',
      this.dataset.associated_projects[0].project.id,
      'dataset',
      this.dataset.id,
      'spreadsheet',
    ]);
  }

  async goDataset() {
    const commons = await this.userService.getCommons();
    this.router.navigate([
      commons,
      'project',
      this.dataset.associated_projects[0].project.id,
      'dataset',
      this.dataset.id,
    ]);
  }

  getWarnings() {
    return this.cas.getLatestDatasetFileVersion(this.dataset)?.file_hipaa_warnings || [];
  }

  canEditDatasetData() {
    return false;
    /** This is temporarily disabled
    return (
      this.dataset._can_upload_data === true && this.dataset.dataset_file_versions.length > 0 && !this.publicCommons
    );
     */
  }

  canUpdateMeta() {
    return this.dataset._can_update_meta === true && !this.publicCommons;
  }

  getInstitutionName() {
    return this.dataset.source_organization?.name || '';
  }
}
