// Autogenerated by util/sync-labels/sync-labels.ts
// Do not edit this file directly.
// To regenerate this file, start ithriv_landing_service locally on port 8000 and
// run the following command in the root directory of the ithriv_web repository:
// > npm run sync_labels

export enum AllowedMimetype {
  TEXT_PLAIN = 'text-plain',
  TEXT_CSV = 'text-csv',
  APPLICATION_CSV = 'application-csv',
  JSON = 'json',
  PDF = 'pdf',
  IMAGE_PNG = 'image-png',
  IMAGE_JPEG = 'image-jpeg',
  IMAGE_GIF = 'image-gif',
  IMAGE_BMP = 'image-bmp',
  IMAGE_SVG = 'image-svg',
  IMAGE_TIFF = 'image-tiff',
  IMAGE_WEBP = 'image-webp',
  MS_WORD_OLD = 'ms-word-old',
  MS_WORD_CDFV2 = 'ms-word-cdfv2',
  MS_WORD_OPENXML = 'ms-word-openxml',
  MS_EXCEL_OPENXML = 'ms-excel-openxml',
  MS_EXCEL_OLD = 'ms-excel-old',
  MS_PPT_OLD = 'ms-ppt-old',
  MS_PPT_OPENXML = 'ms-ppt-openxml',
  MS_PPT_OLE_STORAGE = 'ms-ppt-ole-storage',
  DICOM = 'dicom',
  ZIP = 'zip',
  X_ZIP = 'x-zip',
  FCS = 'fcs',
}

export const AllowedMimetypeLabels = {
  'text-plain': 'text/plain',
  'text-csv': 'text/csv',
  'application-csv': 'application/csv',
  json: 'application/json',
  pdf: 'application/pdf',
  'image-png': 'image/png',
  'image-jpeg': 'image/jpeg',
  'image-gif': 'image/gif',
  'image-bmp': 'image/bmp',
  'image-svg': 'image/svg+xml',
  'image-tiff': 'image/tiff',
  'image-webp': 'image/webp',
  'ms-word-old': 'application/msword',
  'ms-word-cdfv2': 'application/CDFV2',
  'ms-word-openxml': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'ms-excel-openxml': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'ms-excel-old': 'application/vnd.ms-excel',
  'ms-ppt-old': 'application/vnd.ms-powerpoint',
  'ms-ppt-openxml': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'ms-ppt-ole-storage': 'application/x-ole-storage',
  dicom: 'application/dicom',
  zip: 'application/zip',
  'x-zip': 'application/x-zip-compressed',
  fcs: 'application/vnd.isac.fcs',
} as const;

export type AllowedMimetypeId = keyof typeof AllowedMimetypeLabels;
export type AllowedMimetypeLabel = (typeof AllowedMimetypeLabels)[AllowedMimetypeId];
