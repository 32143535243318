import {AbstractControl, ValidationErrors} from '@angular/forms';
import {IdentifierHipaa} from '@shared/labels/identifier-hipaa';

/**
 * Returns a validation error if identifiers_hipaa field does not have
 * N/A selected AND given control is empty. Otherwise, returns null.
 * @param control
 */
export const ValidateDataAggregation = (control: AbstractControl): null | ValidationErrors => {
  const hipaaField = control?.parent?.get('identifiers_hipaa');

  if (!hipaaField) return null;

  const hipaaFieldHasValue = hipaaField?.value?.length > 0;
  const isNaSelected = hipaaField?.value?.length === 1 && hipaaField.value[0] === IdentifierHipaa.NA;
  const isHipaaApplicable = hipaaFieldHasValue && !isNaSelected;

  return isHipaaApplicable && !control.value ? {required: true} : null;
};
