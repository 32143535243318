<span class="mat-label-override">
  <span
    [ngClass]="{
      'mat-form-field': true,
      'mat-form-field-invalid': hasErrors(),
      'mat-form-field-valid': field.formControl.valid
    }"
  >
    <mat-label class="mat-form-field-label" [formGroup]="field.formGroup">
      {{ field.label || field.placeholder }}
      <span *ngIf="field.required" class="mat-placeholder-required mat-form-field-required-marker ng-star-inserted"
        >*</span
      >
    </mat-label>
  </span>
</span>
