import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {getInstitutionName, getSessionInstitutionId} from '@authentication/login-service';
import {PUBLIC_INSTITUTION_ID} from '@shared/constants/constants';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {Category} from '@shared/types/category';
import {CategoryResource} from '@shared/types/category-resource';
import {NavItem} from '@shared/types/nav-item';
import {Resource} from '@shared/types/resource';
import {User} from '@shared/types/user';
import {zoomTransition} from '@utilities/animations';
import {getcategoryRoute} from '@utilities/category-util';
import {lastValueFrom} from 'rxjs';

export interface ResourceGroups {
  userInstitutionResources: Resource[];
  otherResources: Resource[];
}

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  animations: [zoomTransition()],
})
export class CategoryComponent {
  categoryId: number;
  category: Category;
  categoryResources: Array<CategoryResource> = [];
  transitionClass = '';
  isDataLoaded = false;
  publicId = PUBLIC_INSTITUTION_ID;
  navItems: NavItem[];
  resourceGroups: ResourceGroups;
  showPastEvents: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ResourceApiService,
    private titleService: Title,
    private userService: UserService,
  ) {
    this.route.params.subscribe(params => {
      this.categoryId = params['category'];
      this.loadCategory(this.categoryId);
    });
  }

  get user(): User {
    return this.userService.getUser();
  }

  /**
   * Returns current user's institution_id, the session institution_id, or Public institution_id
   * if a user is not logged in and there is no session institution set.
   */
  get institutionId(): number {
    if (this.user) {
      return this.user.institution_id;
    } else {
      const institutionId = getSessionInstitutionId();
      if (institutionId !== undefined) {
        return institutionId;
      } else {
        return PUBLIC_INSTITUTION_ID;
      }
    }
  }

  get resourcesAreLoaded(): boolean {
    return !!(
      this.categoryResources &&
      this.resourceGroups &&
      Array.isArray(this.resourceGroups.userInstitutionResources) &&
      Array.isArray(this.resourceGroups.otherResources)
    );
  }

  get numResources(): number {
    return this.resourcesAreLoaded ? this.resourceGroups.userInstitutionResources.length : 0;
  }

  get numOtherResources(): number {
    return this.resourcesAreLoaded ? this.resourceGroups.otherResources.length : 0;
  }

  get institutionName() {
    return getInstitutionName(this.user);
  }

  async loadCategory(categoryId: Number) {
    this.category = await lastValueFrom(this.api.getCategory(categoryId));
    await this.loadResources();

    // Set page title
    const currentTitle = this.titleService.getTitle();
    this.titleService.setTitle(`${currentTitle} - ${this.category.name}`);

    this.loadNavItems();
  }

  async loadResources() {
    this.categoryResources = [];
    const allCategoryResources = await lastValueFrom(this.api.getCategoryResources(this.category));
    // Filter out past events if showPastEvents is false
    allCategoryResources.forEach(cr => {
      if (cr.resource.segment.name !== 'Event') return;
      if (this.showPastEvents || new Date(cr.resource.ends).getTime() > Date.now()) this.categoryResources.push(cr);
    });

    // Sort events by start date
    this.categoryResources.sort(function (a, b) {
      return new Date(a.resource.starts).getTime() - new Date(b.resource.starts).getTime();
    });

    // Add all other resources
    allCategoryResources.forEach(cr => {
      if (cr.resource.segment.name === 'Resource') {
        this.categoryResources.push(cr);
      }
    });

    this.resourceGroups = this.buildResourceGroups(this.institutionId);
    this.transitionClass = 'zoom-in-enter';
    this.isDataLoaded = true;
  }

  buildResourceGroups(institutionId?: number): ResourceGroups {
    const resourceGroups = {
      userInstitutionResources: [],
      otherResources: [],
    };
    institutionId = [NaN, null, undefined].includes(institutionId) ? PUBLIC_INSTITUTION_ID : institutionId;
    this.categoryResources.forEach(cr => {
      const isAvailable = cr.resource.availabilities.some(av => av.institution_id === institutionId && av.available);
      if (this.isApproved(cr)) {
        if (isAvailable) {
          resourceGroups.userInstitutionResources.push(cr.resource);
        } else {
          resourceGroups.otherResources.push(cr.resource);
        }
      }
    });

    return resourceGroups;
  }

  /** Set nav items
   * Populates breadcrumbs with category parents.
   * @private
   */
  private loadNavItems() {
    this.navItems = [];

    let cat: Category = this.category;

    // Loop through the category and its ancestors.
    while (true) {
      // Add the current category to the front of the breadcrumbs array.
      this.navItems.unshift({
        title: cat.name,
        routerLink: getcategoryRoute(cat, this.api),
      });

      if (cat.parent) {
        // Up to the next ancestor.
        cat = cat.parent;
      } else {
        // No more ancestors.
        break;
      }
    }
  }

  private isApproved(cr: CategoryResource) {
    return this.user ? true : cr.resource.approved;
  }

  async handleShowPastEvents() {
    this.showPastEvents = !this.showPastEvents;
    await this.loadCategory(this.categoryId);
  }
}
