import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Institution} from '@shared/types/institution';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {User} from '@shared/types/user';
import {ResourceApiService} from '@services/resource-api/resource-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User;

  constructor(
    private api: ResourceApiService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.loadUser();
  }

  public getUser(): User {
    return JSON.parse(localStorage.getItem('user'));
  }

  /** getCommons(): Returns the current commons location, public or private. */
  async getCommons(): Promise<string> {
    if (this.activatedRoute.snapshot.firstChild.url[0].path === 'home') {
      const params = await firstValueFrom(this.activatedRoute.queryParamMap);
      return params.get('tabIndex');
    } else {
      return this.activatedRoute.snapshot.firstChild.url[0].path;
    }
  }

  get institution(): Institution {
    return this.user && this.user.institution;
  }

  async refresh() {
    await this.loadUser();
  }

  async logout() {
    await lastValueFrom(this.api.closeSession());
    localStorage.removeItem('user');
    this.user = null;
  }

  async loadUser() {
    try {
      const user = await lastValueFrom(this.api.getSession());
      this.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('session', 'active');
      return this.user;
    } catch (error) {
      this.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('session');
      console.error('Not logged in.');
    }
    return this.user;
  }
}
