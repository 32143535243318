<mat-card [ngClass]="'view'">
  <mat-card-header>
    <span fxFlex></span>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button
        [routerLink]="['/private_commons', 'project', project.id, 'edit']"
        *ngIf="canEditProject()"
        color="accent"
        id="project-edit"
        mat-flat-button
      >
        <mat-icon>edit</mat-icon>
        EDIT PROJECT DETAILS
      </button>
      <button
        (click)="showConfirmDelete = true"
        *ngIf="!showConfirmDelete && canDeleteProject()"
        color="warn"
        mat-raised-button
        ngClass="show-confirm-delete"
        type="button"
      >
        DELETE PROJECT
      </button>
      <button
        (click)="deleteProject()"
        *ngIf="showConfirmDelete && canDeleteProject()"
        color="warn"
        mat-raised-button
        ngClass="confirm-delete"
        type="button"
      >
        <mat-icon>delete</mat-icon>
        Permanently Delete This Project!!!
      </button>
      <button
        (click)="showConfirmDelete = false"
        *ngIf="showConfirmDelete && canDeleteProject()"
        mat-button
        ngClass="cancel-delete"
      >
        <mat-icon>cancel</mat-icon>
        Cancel
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <markdown [data]="project.description"></markdown>
  </mat-card-content>
</mat-card>
