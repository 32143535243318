// Autogenerated by util/sync-labels/sync-labels.ts
// Do not edit this file directly.
// To regenerate this file, start ithriv_landing_service locally on port 8000 and
// run the following command in the root directory of the ithriv_web repository:
// > npm run sync_labels

export enum DatasetAggregationLevel {
  NOT_SELECTED = 'not-selected',
  NONE = 'none',
  ROW_LEVEL = 'row-level',
  GROUPS = 'groups',
  OTHER = 'other',
}

export const DatasetAggregationLevelLabels = {
  'not-selected': '',
  none: 'NONE',
  'row-level': 'Facts in dataset may be unique to individual participants',
  groups: 'All facts in dataset are aggregated to represent 11 or more individuals',
  other: 'Other (i.e. clinical imaging, EEG traces, pathology slides, etc.)',
} as const;

export type DatasetAggregationLevelId = keyof typeof DatasetAggregationLevelLabels;
export type DatasetAggregationLevelLabel = (typeof DatasetAggregationLevelLabels)[DatasetAggregationLevelId];
