import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, Input, OnInit} from '@angular/core';
import {CommonsSearchDataset} from '@shared/types/commons-types';
import {AppBaseComponent} from '@shared/components/app-base/app-base.component';
import {User} from '@shared/types/user';
import {UserService} from '@services/user-service/user.service';
import {CommonsApiService} from '@services/commons-api/commons-api.service';

@Component({
  selector: 'app-commons-dataset-list',
  templateUrl: './commons-dataset-list.component.html',
  styleUrls: ['./commons-dataset-list.component.scss'],
})
export class CommonsDatasetListComponent extends AppBaseComponent implements OnInit {
  @Input() datasets: CommonsSearchDataset[];
  @Input() publicCommons: boolean = true;
  @Input() showCsvDownload: boolean = false;

  constructor(
    public breakpointObserver: BreakpointObserver,
    public userService: UserService,
    public cas: CommonsApiService,
  ) {
    super(breakpointObserver);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get user(): User {
    return this.userService.getUser();
  }

  async getSystemUsageReport(recordType: string) {
    const url = this.cas.getSystemUsageReportUrl(recordType);
    const fileName = `${recordType}.csv`;
    await this.cas.downloadFile(url, fileName, this.userService.user);
  }

  showDatasets() {
    return this.datasets && this.datasets.length > 0;
  }
}
