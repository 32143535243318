import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {Institution} from '@shared/types/institution';
import {NavTab, NavTabMap} from '@shared/types/nav-item';
import {SERVICE_HOST} from '@src/environments/environment';
import {CommonsHomeComponent} from '@commons/commons-home/commons-home.component';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {DEFAULT_HOME_TABINDEX} from '@shared/constants/constants';
import {Resource} from '@shared/types/resource';
import {ResourceQuery} from '@shared/types/resource-query';
import {User} from '@shared/types/user';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Input()
  resourceQuery: ResourceQuery;
  @ViewChild('commonsHome') commonsHomeComponent: CommonsHomeComponent;
  searchForm: UntypedFormGroup;
  searchBox: UntypedFormControl;
  loading = false;
  resources: Resource[];
  events: Array<Resource> = [];
  institution: Institution;
  url = `https://${SERVICE_HOST}:4200/#/commons`;
  urlSafe: SafeResourceUrl;
  tabIndex = DEFAULT_HOME_TABINDEX;
  showPastEvents: boolean = false;

  tabs: NavTab[];
  tabsByIndex: NavTabMap;
  tabsByName: NavTabMap;
  tabsByLabel: NavTabMap;

  constructor(
    private api: ResourceApiService,
    private router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private userService: UserService,
    private cd: ChangeDetectorRef,
  ) {
    this.route.queryParamMap.subscribe(async queryParamMap => {
      this.makeTabsMaps();

      let tabName = 'events';
      if (queryParamMap.has('tabIndex')) {
        tabName = queryParamMap.get('tabIndex');
      }

      if (queryParamMap.has('showPastEvents') && queryParamMap.get('showPastEvents') === 'true') {
        this.showPastEvents = true;
      }

      await this.resolveTabIndex(tabName);
      this.clearQuery();
      await this.loadResources();
    });
  }

  get user(): User {
    return this.userService.getUser();
  }

  ngOnInit() {
    this.searchBox = new UntypedFormControl();
    this.searchForm = new UntypedFormGroup({
      searchBox: this.searchBox,
    });
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  makeTabsMaps() {
    this.tabs = this.makeTabs();
    this.tabsByName = this.makeTabsByName();
    this.tabsByLabel = this.makeTabsByLabel();
    this.tabsByIndex = this.makeTabsByIndex();
  }

  makeTabs(): NavTab[] {
    let indexCount: number = 0;
    let tabs = [];
    if (this.showPrivateCommons()) {
      tabs.push({name: 'private_commons', label: 'Private Data Commons', index: indexCount});
      indexCount = indexCount + 1;
    }

    tabs.push(
      {name: 'public_commons', label: 'Public Data Commons', index: indexCount},
      {name: 'events', label: 'Events', index: indexCount + 1},
    );
    indexCount = indexCount + 2;

    if (this.user) {
      tabs.push(
        {name: 'favorites', label: 'My Favorites', index: indexCount},
        {name: 'user_resources', label: 'My Resources and Events', index: indexCount + 1},
        {name: 'recently_updated', label: 'Recently Updated', index: indexCount + 2},
      );
    }
    return tabs;
  }

  makeTabsByName(): NavTabMap {
    const returnObj = {};
    this.tabs.forEach(tab => {
      returnObj[tab.name] = tab;
    });

    return returnObj;
  }

  makeTabsByLabel(): NavTabMap {
    const returnObj = {};
    this.tabs.forEach(tab => {
      returnObj[tab.label] = tab;
    });

    return returnObj;
  }

  makeTabsByIndex(): NavTabMap {
    const returnObj = {};
    this.tabs.forEach(tab => {
      returnObj[tab.index] = tab;
    });

    return returnObj;
  }

  async updateTab($event) {
    await this.resolveTabIndex($event.tab.textLabel);
    await this.refreshTab({showPastEvents: undefined});
    this.cd.detectChanges();
  }

  async refreshTab(updatedQuery = {}) {
    this.clearQuery();

    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {...updatedQuery, tabIndex: this.tabsByIndex[this.tabIndex].name},
      queryParamsHandling: 'merge',
    });
  }

  clearQuery() {
    const isEvents = this.tabsByIndex[this.tabIndex].name === 'events';

    this.resourceQuery = new ResourceQuery({
      query: '',
      filters: isEvents ? [{field: 'Segment', value: 'Event'}] : [],
      facets: [],
      total: 0,
      start: 0,
      size: 20,
      resources: [],
      sort: isEvents ? 'starts' : '-last_updated',
      ends: this.showPastEvents ? undefined : new Date(),
    });
  }

  async resolveTabIndex(tabName: string) {
    const tab = this.tabsByName[tabName] || this.tabsByLabel[tabName];
    if (tab) {
      this.tabIndex = tab.index;
    } else {
      !!this.user ? (this.tabIndex = 2) : (this.tabIndex = 1);
    }

    return this.tabIndex;
  }

  async loadResources() {
    this.resourceQuery = await lastValueFrom(this.api.searchResources(this.resourceQuery));
    this.resources = this.resourceQuery.resources || [];
  }

  async handleShowPastEvents() {
    this.showPastEvents = !this.showPastEvents;
    await this.refreshTab({showPastEvents: this.showPastEvents});
  }

  showPrivateCommons() {
    return this.user ? this.user.institution.name !== 'CU' : false;
  }

  showTab(tabName: string) {
    return this.tabs.find(tab => tab.label === tabName);
  }

  seeAllEvents() {
    this.router.navigate(['search'], {queryParams: {showPastEvents: false, Segment: 'Event'}});
  }
}
