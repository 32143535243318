import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {ProjectDocumentMap, ProjectFileCategories} from '@shared/types/commons-types';

@Component({
  selector: 'app-commons-project-document',
  templateUrl: './commons-project-document.component.html',
  styleUrls: ['./commons-project-document.component.scss'],
})
export class CommonsProjectDocumentComponent implements OnInit {
  projectDocumentTypes = ProjectFileCategories;
  documentTypeControl: UntypedFormControl;
  documentTypeMap: any;

  constructor(
    public dialogRef: MatDialogRef<CommonsProjectDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public projectDocumentMap: ProjectDocumentMap,
    private cas: CommonsApiService,
  ) {
    this.documentTypeControl = new UntypedFormControl('', Validators.required);
    this.getDocumentTypeOptions();
  }

  ngOnInit() {
    this.documentTypeControl.setValue(this.projectDocumentMap.document.category);
    this.validate();
  }

  onFileComplete() {
    this.dialogRef.close(null);
  }

  uploadUrlDocument() {
    return (
      this.cas.getLandingServiceUrl(this.projectDocumentMap.user, this.projectDocumentMap.project) +
      '/projects/' +
      this.projectDocumentMap.project.id +
      '/files?document_type=' +
      this.documentTypeControl.value
    );
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  validate() {
    this.documentTypeControl.markAsTouched();
    this.documentTypeControl.updateValueAndValidity();

    return !this.documentTypeControl.errors;
  }

  submit(): void {
    if (this.validate()) {
      this.dialogRef.close(true);
    }
  }

  getDocumentTypeOptions() {
    this.documentTypeMap = Object.entries(this.projectDocumentTypes).map(([key, value]) => {
      return {key, value};
    });
  }
}
