import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {cloneDeep, isEqual} from 'lodash-es';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {stringify} from 'telejson';
import {CategoriesService} from '@services/categories/categories.service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {Category} from '@shared/types/category';
import {NavItem} from '@shared/types/nav-item';
import {Resource} from '@shared/types/resource';
import {Facet, Filter, ResourceQuery} from '@shared/types/resource-query';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  resourceQuery: ResourceQuery;
  user: User;
  searchForm: UntypedFormGroup;
  searchBox: UntypedFormControl;
  categorySearchBox: UntypedFormControl;
  loading = true;
  hideResults = true;
  resources: Resource[];
  categories: Category[];
  pageSize = 20;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChildren(MatAutocompleteTrigger) autocompleteTriggers: QueryList<MatAutocompleteTrigger>;

  filteredOptions: Observable<Category[]>;
  sortName: 'relevance' | 'date' = 'relevance';
  navItems: NavItem[] = [
    {
      title: 'Search',
      routerLink: '/search',
    },
  ];
  showPastEvents = false;

  constructor(
    private api: ResourceApiService,
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoriesService,
  ) {
    this.resources = [];
    this.categories = [];

    this.api.getSession().subscribe(user => (this.user = user));
    this.api.getCategories().subscribe(categories => (this.categories = categories));

    // Watch changes to the URL query parameters.
    this.route.queryParamMap.subscribe(qParams => {
      const queryParamsObj = {};
      qParams.keys.forEach(k => {
        queryParamsObj[k] = qParams.get(k);
      });

      this.navItems[0].queryParams = queryParamsObj;

      let query = '';
      const filters: Filter[] = [];
      let ends = new Date();
      ends.setHours(0, 0, 0, 0);

      for (const key of qParams.keys) {
        if (key === 'query') {
          query = qParams.get(key);
        } else if (key === 'publicpage') {
          // TODO: How to handle Public search?
        } else {
          for (const value of qParams.getAll(key)) {
            if (key === 'showPastEvents' && value === 'true') {
              this.showPastEvents = true;
              ends = undefined;
            } else {
              filters.push({field: key, value});
            }
          }
        }
      }

      const newQuery = new ResourceQuery({
        query: query,
        filters: filters,
        facets: [],
        total: 0,
        size: this.pageSize,
        start: 0,
        resources: [],
        sort: '_score',
        ends: ends,
      });

      this.doSearch(newQuery);
    });
  }

  get facetsToDisplay(): Facet[] {
    if (this.resourceQuery && this.resourceQuery.facets) {
      const sortedFacets = [];
      this.resourceQuery.facets.forEach(f => {
        if (f.field === 'Category') {
          sortedFacets.unshift(f);
        } else {
          sortedFacets.push(f);
        }
      });
      return sortedFacets;
    }
  }

  ngOnInit() {
    this.initSearchBox();
  }

  getFacetFieldTitle(field) {
    switch (field) {
      case 'Segment':
        return 'Resources/Events';
      case 'Approved':
        return 'Approval Status';
      case 'Institution':
        return 'Home institution';
      case 'Availability':
        return 'Curated for';
      default:
        return field;
    }
  }

  /**
   * Updates the URL with the current query, which will trigger the UI to re-render with the updated search results.
   */
  updateUrl() {
    const queryParams: Params = {};
    const query: ResourceQuery = cloneDeep(this.resourceQuery);

    if (query.hasOwnProperty('query') && query.query) {
      queryParams['query'] = query.query;
    }

    queryParams['showPastEvents'] = this.showPastEvents;

    query.filters.forEach(({field, value}) => {
      if (field === 'showPastEvents') return;

      // Initialize the field with an empty array in queryParams, if it doesn't already exist.
      if (!queryParams[field]) {
        queryParams[field] = [];
      }

      queryParams[field].push(value);
    });

    const hasFilters = Object.entries(queryParams).length > 0;
    const routerCommands = hasFilters ? ['/search', 'filter'] : ['/search'];

    if (this.queryParamsHaveChanged(queryParams)) {
      this.router.navigate(routerCommands, {queryParams}).finally(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  /**
   * Submits search query to the Resource API and updates the list of search results.
   */
  doSearch(newQuery?: ResourceQuery) {
    const query = cloneDeep(newQuery || this.resourceQuery);
    this.loading = true;
    this.hideResults = query.query === '' && query.filters.length === 0;

    // Add start date constraint if we're not showing past events.
    if (!this.showPastEvents) {
      const ends = new Date();
      ends.setHours(0, 0, 0, 0);
      query.ends = ends;
    }

    // Remove showPastEvents filter, since it's not a real filter.
    query.filters = query.filters.filter(f => f.field !== 'showPastEvents');

    this.api.searchResources(query).subscribe(updatedQuery => {
      this.resourceQuery = updatedQuery;
      this.resources = updatedQuery.resources;
      this.loading = false;
      this.hideResults = false;

      const sameFilters = isEqual(updatedQuery.filters, query.filters);
      if (sameFilters && query.start) {
        this.resourceQuery.start = query.start;
      } else {
        this.resourceQuery.start = 0;
        this.paginator.firstPage();
      }
      this.initSearchBox();
    });
  }

  sortByDate() {
    this.sortName = 'date';
    this.resourceQuery.sort = '-last_updated';
    this.doSearch();
  }

  sortByRelevance() {
    this.sortName = 'relevance';
    this.resourceQuery.sort = '_score';
    this.doSearch();
  }

  addFilter(field: string, value: string) {
    this.loading = true;

    // Avoid adding duplicate pipes
    if (!this.categoryIsSelected({field, value})) {
      this.resourceQuery.filters.push({field: field, value: value});
    }

    this.updateUrl();
  }

  removeFilter(filter: Filter) {
    this.loading = true;

    const index = this.getFilterIndex(filter);
    if (index > -1) {
      this.resourceQuery.filters.splice(index, 1);
    }

    this.updateUrl();
  }

  updatePage() {
    this.resourceQuery.size = this.paginator.pageSize;
    this.resourceQuery.start = this.paginator.pageIndex * this.paginator.pageSize;
    this.doSearch();
  }

  getSelectedCategories(field: string): string[] {
    return this.resourceQuery.filters.filter(f => f.field === field).map(f => f.value);
  }

  categoryIsSelected(filter: Filter) {
    return this.getFilterIndex(filter) !== -1;
  }

  toggleFilter(field: string, value: string) {
    if (this.categoryIsSelected({field, value})) {
      this.removeFilter({field, value});
    } else {
      this.addFilter(field, value);
    }
  }

  calcFilterButtonWidth() {
    const percentWidth = 100 / (this.resourceQuery.facets.length + 1);
    const gutterWidth = (this.resourceQuery.facets.length - 1) * 10;
    return `calc(${percentWidth}% - ${gutterWidth}px)`;
  }

  removeKeywords() {
    this.loading = true;
    this.resourceQuery.query = undefined;
    this.updateUrl();
  }

  getFilterIndex(filter: Filter): number {
    return this.resourceQuery.filters.findIndex(f => isEqual(filter, f));
  }

  initSearchBox() {
    if (this.resourceQuery && !(this.searchBox && this.searchForm)) {
      this.searchBox = new UntypedFormControl();
      this.categorySearchBox = new UntypedFormControl();
      this.searchForm = new UntypedFormGroup({
        searchBox: this.searchBox,
        categorySearchBox: this.categorySearchBox,
      });

      this.searchBox.setValue(this.resourceQuery.query);
      this.categorySearchBox.setValue('');
      this.filteredOptions = this.categorySearchBox.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
    }
  }

  submitKeywordSearch() {
    this.resourceQuery.query = this.searchBox && this.searchBox.value;
    this.updateUrl();
  }

  updateKeywordSearch($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.resourceQuery.query = this.searchBox && this.searchBox.value;
      this.updateUrl();
    }
  }

  selectCategory($event: MatAutocompleteSelectedEvent) {
    const selectedCategory = $event.option.value as Category;
    this.addFilter('Category', selectedCategory.name);
    this.categorySearchBox.setValue('');
  }

  optionText(option: Category) {
    return option && option.indented_string ? option.indented_string : '';
  }

  categoryColor(category: Category) {
    return this.categoryService.getRootCategory(category).color;
  }

  /**
   * Returns true if no pipes have been selected in the current query.
   */
  hasNoFilters(): boolean {
    if (!this.resourceQuery) {
      return true;
    }

    const noKeyword = ['', undefined, null].includes(this.resourceQuery.query);
    const noFacetFilters = !this.resourceQuery.filters || this.resourceQuery.filters.length === 0;
    return noKeyword && noFacetFilters;
  }

  onSearchInputFocus() {
    this.autocompleteTriggers.forEach(t => {
      t._onChange('');
      t.openPanel();
    });

    return true;
  }

  optionCount(option: Category) {
    const categoryFacet = this.facetsToDisplay.find(f => f.field === 'Category');

    if (categoryFacet && categoryFacet.facetCounts && categoryFacet.facetCounts.length > 0) {
      const catFacetCount = categoryFacet.facetCounts.find(c => c.category === option.name);

      if (catFacetCount) {
        return catFacetCount.hit_count;
      }
    }
  }

  private _filter(value: string): Category[] {
    if (value && value.length > 0) {
      const words = value.replace(/\W+/gi, ' ').toLowerCase().split(' ');
      const patternString = words.map(w => `(?=.*${w})`).join('');
      const filterPattern = new RegExp(patternString, 'gi');

      return this.categoryService.categoryList.filter(option => {
        return option.resource_count > 0 && filterPattern.test(option.indented_string);
      });
    } else {
      return this.categoryService.categoryList;
    }
  }

  private queryParamsHaveChanged(newQParams: Params): boolean {
    const currentQParams = this.route.snapshot.queryParams;
    return stringify(currentQParams) !== stringify(newQParams);
  }

  handleShowPastEvents() {
    this.showPastEvents = !this.showPastEvents;
    this.updateUrl();
  }
}
