/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DatasetAggregationLevelID {
    NOT_SELECTED = 'not-selected',
    NONE = 'none',
    ROW_LEVEL = 'row-level',
    GROUPS = 'groups',
    OTHER = 'other',
}
