<div class="logo" *ngIf="showPartner">
  <a target="_blank" [href]="partnerURL">
    <img [src]="partnerLogoURL" [alt]="partnerName" />
  </a>
</div>
<div class="logo" *ngIf="!showPartner">
  <a target="_blank" [href]="defaultLoginService.websiteURL">
    <img [src]="defaultLoginService.image" [alt]="defaultLoginService.name" />
  </a>
</div>
