import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonsMenuComponent} from '@commons/commons-menu/commons-menu.component';
import {NavigationModule} from '@navigation/navigation.module';

@NgModule({
  declarations: [CommonsMenuComponent],
  imports: [CommonModule, FlexLayoutModule, NavigationModule],
  exports: [CommonsMenuComponent],
})
export class CommonsMenuModule {}
