import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CategoryFormComponent} from '@portal/category-form/category-form.component';
import {Category} from '@shared/types/category';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-edit-category-button',
  templateUrl: './edit-category-button.component.html',
  styleUrls: ['./edit-category-button.component.scss'],
})
export class EditCategoryButtonComponent {
  @Input() category: Category;
  @Input() user: User;
  @Input() parent: Category;
  @Output() updatedCategory = new EventEmitter<Category>();

  constructor(private dialog: MatDialog) {}

  openEdit() {
    this.dialog.open(CategoryFormComponent, {
      data: {edit: this.category, parent_category: this.parent},
    });
  }
}
