import {AbstractControl, UntypedFormControl, ValidationErrors, Validators} from '@angular/forms';
import {ALL_LOGIN_SERVICES} from '@authentication/login-service';
import {cloneDeep} from 'lodash-es';
import {cleanUpCommaDelimitedList} from './delimited_list.validator';

const landingServiceDomains = Object.values(ALL_LOGIN_SERVICES)
  .filter(s => s.hasLandingService)
  .reduce((acc, s) => acc.concat(s.domains), []);
const allowedDomainsEscaped = landingServiceDomains.map(d => d.replace('.', '\\.')).join('|');
const pattern = new RegExp(`^(${allowedDomainsEscaped})$`, 'i');

const isNotLandingServiceInstitutionEmail = (email: string): boolean => {
  const isNotEmailAddress = Validators.email(new UntypedFormControl(email));
  const isNotAllowedDomain = !pattern.test(email.split('@')[1]);
  return !!(isNotEmailAddress || isNotAllowedDomain);
};

export function ValidateLandingServiceInstitutionEmails(control: AbstractControl): ValidationErrors {
  let value = cloneDeep(control.value);
  if (value === null) {
    value = '';
  }

  let emails = [];

  if (typeof value === 'string') {
    emails = value
      .split(',')
      .map(e => e.trim())
      .filter(v => !!v);
  } else if (Array.isArray(value)) {
    emails = value;
  }

  const forbiddenIndices = [];
  emails.forEach((e, i) => {
    if (isNotLandingServiceInstitutionEmail(e)) {
      forbiddenIndices.push(i);
    }
  });
  return forbiddenIndices.length > 0 ? {email: {value: control.value, indices: forbiddenIndices}} : null;
}

export function ValidateLandingServiceInstitutionEmail(control: AbstractControl): ValidationErrors {
  let value = cloneDeep(control.value);
  if (value === null) {
    value = '';
  }

  return isNotLandingServiceInstitutionEmail(value) ? {email: {value: control.value}} : null;
}

/**
 * Returns the given comma-delimited string or list of strings,
 * trimmed, lowercased, converted to list of strings, and with empty items removed.
 * @param value
 */
export function cleanUpEmailsList(value?: string | string[]): string[] {
  const cleanUp = v => {
    if ([null, undefined, ''].includes(v)) {
      return '';
    }

    return v.trim().toLowerCase();
  };

  let valList = [];

  // Convert comma-delimited list of strings to an array.
  if (typeof value === 'string') {
    valList = cleanUpCommaDelimitedList(value).split(',');
  } else if (Array.isArray(value)) {
    valList = cloneDeep(value);
  }

  // Remove empty items.
  return valList.map(cleanUp).filter(v => v !== '');
}
