/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OtherSensitiveDataID {
    NONE = 'none',
    HIV_STATUS = 'hiv-status',
    PSYCHIATRIC = 'psychiatric',
    FINANCIAL = 'financial',
    EMPLOYMENT = 'employment',
    STUDENT = 'student',
    CONSUMER = 'consumer',
    CRIMINAL = 'criminal',
    ADDICTION_STATUS = 'addiction-status',
    SEXUAL_HEALTH = 'sexual-health',
    GENDER = 'gender',
    PII = 'pii',
    SSN = 'ssn',
    CUI = 'cui',
    OTHER_SENSITIVE = 'other-sensitive',
}
