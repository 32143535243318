<div class="breadcrumbs-container"><app-breadcrumbs [navItems]="navItems"></app-breadcrumbs></div>
<app-loading *ngIf="!resourceQuery" size="sm" message="Loading search results..."></app-loading>
<div
  [ngClass.sm]="'search-container sm'"
  [ngClass.gt-sm]="'search-container gt-sm'"
  fxLayout.gt-sm="row wrap"
  fxLayout.sm="column"
  fxLayoutAlign="center start"
  *ngIf="resourceQuery"
>
  <form fxFlex="100%" [formGroup]="searchForm" [ngClass]="{'search-box': true, 'hidden-results': hideResults}">
    <mat-form-field appearance="outline">
      <input
        [formControl]="searchBox"
        matInput
        placeholder="Search"
        type="search"
        value="{{ resourceQuery.query }}"
        (keyup)="updateKeywordSearch($event)"
        (focus)="onSearchInputFocus()"
      />
      <mat-icon (click)="submitKeywordSearch()" matSuffix>search</mat-icon>
    </mat-form-field>
  </form>
  <h2 fxFlex="100%" style="margin-bottom: 0; padding-bottom: 0">Filter Results</h2>
  <div
    fxLayout="column"
    fxLayoutGap="0px"
    fxFlex.gt-sm="100%"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="stretch stretch"
    fxLayoutGap.gt-sm="10px"
  >
    <mat-form-field
      appearance="outline"
      *ngFor="let facet of facetsToDisplay"
      [fxFlex.gt-sm]="calcFilterButtonWidth()"
      [id]="'facet' + facet.field"
    >
      <mat-label>{{ getFacetFieldTitle(facet.field) }}</mat-label>
      <ng-container *ngIf="facet.field !== 'Category'">
        <div
          [matTooltip]="
            facet.field === 'Availability' ? 'Show all resources available to a specific institution' : null
          "
        >
          <mat-select multiple [value]="getSelectedCategories(facet.field)">
            <mat-select-trigger>
              <ng-container *ngIf="getSelectedCategories(facet.field).length > 0">{{
                getSelectedCategories(facet.field).join(', ')
              }}</ng-container>
            </mat-select-trigger>
            <mat-option
              *ngFor="let count of facet.facetCounts"
              (click)="toggleFilter(facet.field, count.category)"
              [value]="count.category"
            >
              {{ count.category }}
              <span fxFlex></span>
              {{ count.hit_count }}
            </mat-option>
          </mat-select>
        </div>
      </ng-container>
      <ng-container *ngIf="facet.field === 'Category'">
        <input
          #trigger="matAutocompleteTrigger"
          [formControl]="categorySearchBox"
          matInput
          placeholder="Search Categories"
          type="search"
          [matAutocomplete]="auto"
          (click)="onSearchInputFocus()"
          (focus)="onSearchInputFocus()"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          panelWidth="720px"
          (optionSelected)="selectCategory($event)"
          [displayWith]="optionText"
        >
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <div class="color-chip" [ngStyle]="{'background-color': option.color || categoryColor(option)}"></div>
            {{ optionText(option) }}
            <span fxFlex></span>
            {{ optionCount(option) }}
          </mat-option>
        </mat-autocomplete>
      </ng-container>
    </mat-form-field>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="start center" class="applied-filters-container">
  <h3 [ngClass]="{'no-filters': hasNoFilters()}">Applied Filters</h3>
  <app-loading *ngIf="loading" size="sm" message="Loading filters..."></app-loading>
  <mat-chip-list class="applied-filters" *ngIf="!loading">
    <mat-chip *ngIf="resourceQuery.query" color="accent" selected (click)="removeKeywords()" matTooltip="Remove filter">
      Contains:&nbsp;&nbsp;
      <em>"{{ resourceQuery.query }}"</em>
      <span fxFlex></span>
      <mat-icon>close</mat-icon>
    </mat-chip>

    <mat-chip
      *ngFor="let filter of resourceQuery.filters"
      color="accent"
      selected
      (click)="removeFilter(filter)"
      fxLayout="row"
      fxLayoutAlign="start center"
      matTooltip="Remove filter"
    >
      {{ getFacetFieldTitle(filter.field) }}:&nbsp;&nbsp;
      <em>{{ filter.value }}</em>
      <span fxFlex></span>
      <mat-icon>close</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <span fxFlex></span>
  <div class="sort-menu" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <h3>Sort Results By:</h3>
    <mat-button-toggle-group [value]="sortName">
      <mat-button-toggle (click)="sortByRelevance()" value="relevance"> Search Relevance </mat-button-toggle>
      <mat-button-toggle (click)="sortByDate()" value="date"> Recently Updated </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<div [hidden]="loading || hideResults" class="results-container">
  <app-resource-list
    [resourceQuery]="resourceQuery"
    [resources]="resources"
    [showPastEvents]="showPastEvents"
    (toggleShowPastEvents)="handleShowPastEvents()"
    fxFlexFill
  ></app-resource-list>
</div>

<div [hidden]="loading || hideResults">
  <mat-paginator
    (page)="updatePage()"
    [length]="resourceQuery && resourceQuery.total"
    [pageSizeOptions]="[pageSize, pageSize * 3, pageSize * 5]"
    [pageSize]="pageSize"
  >
  </mat-paginator>
</div>
