import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CategoryFormComponent} from '@portal/category-form/category-form.component';
import {Category} from '@shared/types/category';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-add-category-button',
  templateUrl: './add-category-button.component.html',
  styleUrls: ['./add-category-button.component.scss'],
})
export class AddCategoryButtonComponent {
  @Input() category: Category;
  @Input() user: User;

  constructor(public dialog: MatDialog) {}

  openAdd() {
    this.dialog.open(CategoryFormComponent, {
      height: '400px',
      width: '600px',
      data: {parent_category: this.category},
    });
  }
}
