<mat-tree
  *ngIf="dataLoaded"
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="tree-select"
  [formGroup]="field.formControl"
>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <mat-checkbox
        [formControl]="getFormControl(node)"
        [checked]="checklistSelection.isSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="toggleNode(node)"
        ngDefaultControl
        >{{ node.name }}</mat-checkbox
      >
    </li>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
    <li>
      <div class="mat-tree-node" matTreeNodeToggle>
        <button mat-icon-button [attr.aria-label]="'toggle ' + node.name" (click)="toggleExpand($event, node)">
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
          <span
            *ngIf="numSelectedDescendants(node)"
            matBadge="{{ numSelectedDescendants(node) }}"
            matBadgeOverlap="false"
            matBadgeColor="accent"
            >{{ node.name }}</span
          >
          <span *ngIf="!numSelectedDescendants(node)">{{ node.name }}</span>
        </button>
      </div>
      <ul [class.tree-select-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
