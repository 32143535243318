import {AllowedMimeTypes} from '@shared/constants/constants';
import {FileAttachment} from '@shared/types/file-attachment';

export function findSvg(file: FileAttachment): string {
  const fileType = file.mime_type || file.type || file.name || file.file_name;
  const pattern = /([\w-+]+)$/gi;

  const fileExtension = fileType.toLowerCase().match(pattern);

  const allowedMimeTypeKey = Object.keys(AllowedMimeTypes).find(key => {
    return !!AllowedMimeTypes[key].find(t => t.toLowerCase().match(pattern).toString() === fileExtension.toString());
  });

  if (allowedMimeTypeKey) {
    return `/assets/filetypes/${allowedMimeTypeKey.toLowerCase()}.svg`;
  } else {
    return `/assets/filetypes/unknown.svg`;
  }
}
